<template>
  <f7-fab
      position="left-bottom"
      href="/admin/qr-customize-sheet/"
      class="custom-fab no-printme"
    >
      <i class="fas fa-palette"></i>
    </f7-fab>
</template>

<script>
export default {
  
}
</script>