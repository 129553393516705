// FRONT PAGES
import homeLocal from "../pages/home.vue";

// ADMIN PAGES
import DashPage from "../pages/dash.vue";
import LinksPage from "../pages/links.vue";
import profilePage from "../pages/profile.vue";
import accountPage from "../pages/account.vue";
import mainMenu from "../components/main-menu.vue";
import ConnectMP from "../components/links/connect_mp.vue";
import authenticationPage from "../pages/authentication.vue";

// LINKS
import linkTypePopup from "../components/links/link-type-popup.vue";
import linkSheet from "../components/links/link-sheet.vue";

// MY QR
import qrHome from "../pages/qr.vue";
import qrCustomizeSheet from "../components/qr/qr-customize-sheet.vue";

// HELPS
import helpLinksSidebar from "../components/helps/help-links-sidebar.vue";

// UTILS
import customizePageSheet from "../components/customize-page-sheet.vue";
import NotFoundPage from "../pages/404.vue";
import MiqrositioNotFound from "../pages/no-encontrado.vue";
import fullscreenLoader from "../components/fullscreen-loader-container.vue";
import authentication from "../js/authentication";

import crudLocal from "../js/crud-local";

let checkLogin = async (app, resolve, reject, goToComponent) => {
  authentication
    .checkLogin(app)
    .then((r) => {
      resolve(goToComponent);
    })
    .catch((e) => {
      resolve({ component: authenticationPage });
    });
};

var routes = [
  // UTILS
  {
    path: "/no-encontrado",
    component: MiqrositioNotFound,
    alias: ["/no-encontrado/"],
  },
  {
    path: "/not-found/",
    component: NotFoundPage,
  },
  {
    path: "/admin/customize-page-sheet/",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        sheet: {
          component: customizePageSheet,
        },
      });
    },
  },
  {
    path: "/admin/",
    async({ app, resolve, reject }) {
      if (
        location.hostname === "miqro.app" ||
        location.hostname === "devapp.miqro.app"
      ) {
        checkLogin(app, resolve, reject, { component: homeLocal });
      } else {
        // window.open("https://devapp.miqro.app/admin", "_self");
        window.open("https://miqro.app/admin", "_self");
      }
    },
    options: {
      transition: "none",
    },
    alias: ["/admin"],
  },
  {
    path: "/",
    async({ app, resolve, reject }) {
      if (
        !/^((devapp\.)?(miqro\.app){1}(\/)?[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(
          window.location.hostname
        )
      ) {
        crudLocal
          .checkIfMiqrositioExist("", window.location.hostname)
          .then((r) => {
            resolve({ component: homeLocal });
          });
      }
    },
    options: {
      animate: false,
    },
  },
  // DASHBOARD
  {
    path: "/admin/dash/",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        component: DashPage,
      });
    },
    options: {
      transition: "f7-fade",
    },
    alias: ["/admin/dash"],
  },
  // LINKS
  {
    path: "/admin/links/",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        component: LinksPage,
      });
    },
    options: {
      transition: "f7-fade",
    },
    alias: ["/admin/links"],
  },
  {
    path: "/admin/new-link-type/",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        popup: {
          component: linkTypePopup,
        },
      });
    },
    alias: ["/admin/new-link-type"],
  },
  {
    name: "new-link",
    path: "/admin/new-link/:type/",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        sheet: {
          component: linkSheet,
        },
      });
    },
    options: {
      props: {
        operation: "create",
      },
    },
    alias: ["/admin/new-link/:type"],
  },
  {
    name: "link",
    path: "/admin/link/:id/",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        sheet: {
          component: linkSheet,
        },
      });
    },
    options: {
      props: {
        operation: "edit",
      },
    },
    alias: ["/admin/link/:id"],
  },
  // MY QR
  {
    path: "/admin/qr/",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        component: qrHome,
      });
    },
    options: {
      transition: "f7-fade",
    },
    alias: ["/admin/qr"],
  },
  {
    path: "/admin/qr-customize-sheet/",
    sheet: {
      component: qrCustomizeSheet,
    },
    // async({ app, resolve, reject }) {
    //   checkLogin(app, resolve, reject, {
    //     sheet: {
    //       component: qrCustomizeSheet,
    //     },
    //   });
    // },
  },
  // LOCAL PROFILE
  {
    path: "/admin/local/",
    // id: "tabSettingsLocal",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        component: profilePage,
      });
    },
    options: {
      transition: "f7-fade",
    },
    alias: ["/admin/local"],
  },
  {
    path: "/admin/account/",
    // id: "tabAccountLocal",
    async({ app, resolve, reject }) {
      checkLogin(app, resolve, reject, {
        component: accountPage,
      });
    },
    options: {
      transition: "f7-fade",
    },
    alias: ["/admin/account"],
  },
  {
    path: "/admin/main-menu/",
    panel: {
      component: mainMenu,
    },
    alias: ["/admin/main-menu"],
  },
  // FRONT PAGE
  // {
  //   path: "/admin/:local",
  //   async({ app, resolve, reject }) {
  //     checkLogin(app, resolve, reject, {
  //       component: homeLocal,
  //     });
  //   },
  //   alias: ["/admin/:local/"],
  // },
  {
    path: "/:local/",
    component: homeLocal,
    alias: ["/:local"],
  },
  // HELPS
  {
    path: "/admin/help-links-sidebar/",
    panel: {
      component: helpLinksSidebar,
    },
  },
  {
    path: "/admin/connect-mp/",
    sheet: {
      component: ConnectMP,
    },
  },
  {
    path: "(.*)",
    component: NotFoundPage,
  },

  // DEPRECATED
  // import ProductPage from "../pages/product.vue";
  // import DynamicRoutePage from "../pages/dynamic-route.vue";
  // import RequestAndLoad from "../pages/request-and-load.vue";
  // import AboutPage from "../pages/about.vue";
  // import FormPage from "../pages/form.vue";
  // import newLinkSheet from "../components/links/new-link-sheet.vue";
  // import LoaderPage from "../pages/loader.vue";
  // import qrView from "../pages/qr-view.vue";
  // import CodigosQRPage from "../pages/deprecated/pages/codigos-qr.vue";
  // import shareSheet from "../deprecated/components/share-sheet.vue";
  // import SettingsPage from "../pages/admin/settings.vue";
  // CUSTOM QRS CODES
  // import qrSheet from "../components/qrs/qr-sheet.vue";
  // import newQRSheet from "../components/qrs/new-qr-sheet.vue";
  // import qrPreview from "../components/qrs/qr-preview.vue";
  // import escanearQR from "../components/qrs/escanear-qr.vue";
  // import QRTypePopup from "../components/qrs/qr-type-popup.vue";

  // AUTHENTICATION
  {
    path: "/admin/auth/",
    // id: "tabDash",
    component: authenticationPage,
    options: {
      transition: "f7-fade",
    },
    alias: ["/admin/auth"],
  },
  // CUSTOM QR CODES
  // {
  //   path: "/admin/codigosqr/",
  //   id: "tabCodigosQR",
  //   component: CodigosQRPage,
  //   options: {
  //     transition: "f7-fade",
  //   },
  // },
  // {
  //   path: "/admin/new-qr-type/",
  //   popup: {
  //     component: QRTypePopup,
  //   },
  // },
  // {
  //   path: "/admin/new-qr/:type/",
  //   sheet: {
  //     component: newQRSheet,
  //   },
  // },
  // {
  //   path: "/admin/qr/:id/",
  //   sheet: {
  //     component: qrSheet,
  //   },
  // },
  // {
  //   path: "/admin/escanear-qr/",
  //   sheet: {
  //     component: escanearQR,
  //   },
  // },
  // {
  //   path: "/admin/preview/:local/:qrid/",
  //   sheet: {
  //     component: qrPreview,
  //   },
  // },
  // {
  //   path: "/:local/:qrid/",
  //   sheet: {
  //     component: qrPreview,
  //   },
  // },
  // {
  //   path: "/home/",
  //   id: "tabHome",
  //   component: LinksPage,
  // },
  // {
  //   path: "/request-and-load/user/:userId/",
  //   async: function ({ router, to, resolve }) {
  //     // App instance
  //     var app = router.app;

  //     // Show Preloader
  //     app.preloader.show();

  //     // User ID from request
  //     var userId = to.params.userId;

  //     // Simulate Ajax Request
  //     setTimeout(function () {
  //       // We got user data from request
  //       var user = {
  //         firstName: "Vladimir",
  //         lastName: "Kharlampidi",
  //         about: "Hello, i am creator of Framework7! Hope you like it!",
  //         links: [
  //           {
  //             title: "Framework7 Website",
  //             url: "http://framework7.io",
  //           },
  //           {
  //             title: "Framework7 Forum",
  //             url: "http://forum.framework7.io",
  //           },
  //         ],
  //       };
  //       // Hide Preloader
  //       app.preloader.hide();

  //       // Resolve route to load page
  //       resolve(
  //         {
  //           component: RequestAndLoad,
  //         },
  //         {
  //           props: {
  //             user: user,
  //           },
  //         }
  //       );
  //     }, 1000);
  //   },
  // },
  // {
  //   path: "/admin/share/",
  //   async({ app, resolve, reject }) {
  //     checkLogin(app, resolve, reject, {
  //       sheet: {
  //         component: shareSheet,
  //       },
  //     });
  //   },
  // },
];

export default routes;
