<template>
  <span>
    <!-- GALERIA DE ITEMS -->

    <li>
      <div class="item-content item-input item-input-outline">
        <div class="item-inner">
          <div class="add-item-gallery" @click="addItemGallery">
            <span><i class="fas fa-plus"></i>&nbsp;&nbsp; AGREGAR ITEM</span>
          </div>
        </div>
      </div>
    </li>
    <li
      v-for="bloque_hijo in props.dataBloque.bloques_hijos"
      :key="bloque_hijo.name"
    >
      <div class="item-content item-input item-input-outline">
        <div class="item-inner">
          <f7-block class="item-gallery" @click="goToItem(bloque_hijo)">
            <f7-row>
              <f7-col width="25">
                <div>
                  <img
                    :src="bloque_hijo.vista_previa_url"
                    alt=""
                    style="
                      width: 60px;
                      height: 60px;
                      object-fit: cover;
                      border-radius: 5px;
                    "
                  />
                </div>
              </f7-col>
              <f7-col width="75"
                ><div>
                  <span>{{ bloque_hijo.nombre_bloque }}</span>
                </div></f7-col
              >
            </f7-row>
          </f7-block>
        </div>
      </div>
    </li>
  </span>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
import utils from "../../../js/utils";
import crudBloques from "../../../js/crud-bloques";

export default {
  props: {
    props: Object,
  },
  methods: {
    goToItem(bloque_hijo) {
      const self = this;
      localStorage.setItem("parent_block", self.props.dataBloque.name);
      f7.views.main.router.navigate(`/admin/link/${bloque_hijo.name}/`, {
        ignoreCache: true,
      });
    },
    addItemGallery() {
      const self = this;
      // const frappeHost = useStore("frappeHost");
      // let linkTypeLS = JSON.parse(localStorage.getItem("block_type"));

      // Si es un bloque "Padre" guarda su DocName en LocalStorage
      if (
        self.props.linkTypeId === "galeria_de_imagenes" ||
        self.props.linkTypeId === "catalogo"
      ) {
        // Si la operacion es "create" crea el bloque antes de agregar un item "Hijo"
        // Si la operacion es "edit" solo agrega el item "Hijo"
        if (self.props.operation === "create") {
          crudBloques.createBloque(self, true, false);
          self.addItem();
        } else if (self.props.operation === "edit") {
          self.addItem();
        }
      }
    },
    addItem() {
      const self = this;

      let callback = function (tipoDeBloqueHijo) {
        localStorage.setItem("block_type", JSON.stringify(tipoDeBloqueHijo));
        f7.sheet.close();

        f7.views.main.router.navigate(
          `/admin/new-link/${tipoDeBloqueHijo.name}/`,
          {
            ignoreCache: true,
          }
        );
      };
      crudBloques.getTipoDeBloqueHijo(self, callback);
    },
    setValueForm(e) {
      const self = this;
      crudBloques.setValueForm(self, e);
    },
  },
};
</script>
