<template>
  <span>
    <f7-list-input
      v-if="props.linkTypeId !== 'titulo_de_seccion'"
      outline
      label="Desde"
      type="datetime-local"
      name="mostrar_desde"
      @input="setValueForm"
      :disabled="suscripcion.nombre_suscripcion_actual === 'Gratuito' ? true : false"
      :value="props.dataBloque.mostrar_desde"
      :clear-button="true"
    ></f7-list-input>
    <f7-list-input
      v-if="props.linkTypeId !== 'titulo_de_seccion'"
      outline
      label="Hasta"
      type="datetime-local"
      name="mostrar_hasta"
      @input="setValueForm"
      :disabled="suscripcion.nombre_suscripcion_actual === 'Gratuito' ? true : false"
      :value="props.dataBloque.mostrar_hasta"
      :clear-button="true"
    ></f7-list-input>
  </span>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";
import { useStore } from "framework7-vue";

export default {
  setup() {
    const suscripcion = useStore("suscripcion");

    return {
      suscripcion,
    };
  },
  props: {
    props: Object,
  },
  methods: {
    setValueForm(e) {
      const self = this;
      crudBloques.setValueForm(self, e);
    },
  },
};
</script>
