<template>
  <f7-fab
    v-if="emojiElementSelected != ''"
    position="right-bottom"
    class="custom-fab"
    @click="triggerEmojiPicker"
    style="bottom: 75px;"
  >
    <i class="fas fa-smile"></i>
  </f7-fab>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import utils from "../js/utils";

export default {
  setup() {
    const emojiElementSelected = useStore("emojiElementSelected");

    return {
      emojiElementSelected
    }
  },
  methods: {
    triggerEmojiPicker() {
      utils.triggerEmojiPicker();
    }
  }
};
</script>