<template>
  <span>
    <f7-block v-if="suscripcion.nombre_suscripcion_actual === 'Pro'">
      <f7-row>
        <f7-col class="display-flex justify-content-flex-start">
          <span style="color: #b1b1b1">Últimos 30 Días</span>
        </f7-col>
      </f7-row>
      <f7-row class="padding-vertical">
        <f7-col width="75">Clicks en el Bloque</f7-col>
        <f7-col width="25" class="display-flex justify-content-flex-end">
          <f7-badge class="counter-badge">{{
            props.dataBloque.eventos_totales_bloque || 0
          }}</f7-badge></f7-col
        >
      </f7-row>
      <!-- <f7-row>
        <f7-col width="75">Interacciones con Enlaces Internos</f7-col>
        <f7-col width="25">{{
          props.dataBloque.eventos_totales_internos
        }}</f7-col>
      </f7-row> -->
    </f7-block>
    <f7-block v-if="suscripcion.nombre_suscripcion_actual === 'Gratuito'">
      <f7-row>
        <f7-col class="text-align-center padding-horizontal-half">
          <span style="color: #b1b1b1; font-size: 14px"
            >Con el Plan Pro podrás ver la cantidad de veces que los usuarios
            interactuaron con este bloque, midiendo así su popularidad.</span
          >
        </f7-col>
      </f7-row>
    </f7-block>
  </span>
</template>

<script>
import { useStore } from "framework7-vue";
import crudBloques from "../../../js/crud-bloques";

export default {
  setup() {
    const suscripcion = useStore("suscripcion");

    return {
      suscripcion,
    };
  },
  props: {
    props: Object,
  },
  methods: {
    setValueForm(e) {
      const self = this;
      crudBloques.setValueForm(self, e);
    },
  },
};
</script>
