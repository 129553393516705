<template>
  <!-- WHATSAPP -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'whatsapp'"
    class="item-whatsapp link-item-list-preview"
    @click="
      registraEvento(bloque, 'click_bloque', true, buildWhatsAppLink(bloque))
    "
  >
    <a style="width: 100%">
      <f7-row
        class="display-flex text-align-center justify-content-center align-items-center"
      >
        <!-- <f7-col width="25" class="text-align-left">
                  <i class="fab fa-whatsapp icon-blue-home-preview"></i>
                </f7-col> -->
        <f7-col width="100">
          <div class="item-title">{{ bloque.nombre_bloque }}</div>
        </f7-col>
      </f7-row>
    </a>
  </div>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";

export default {
  props: {
    bloque: Object,
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
    buildWhatsAppLink(bloque) {
      const self = this;
      return `https://wa.me/${bloque.whatsapp_numero}/?text=${encodeURI(
        bloque.whatsapp_mensaje
      )}`;
    },
  },
};
</script>
