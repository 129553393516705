<template>
  <f7-navbar class="no-shadow" style="position: fixed;">
    <template #title>Editar QR</template>
    <template #right>
      <span class="padding-right-half">
        <f7-button
          v-if="!minimizedQrCustomizeSheet"
          class="btn-links"
          @click="updateCustomQRCode"
        >
          <i class="fas fa-save"></i>
        </f7-button>
      </span>
      <span v-if="minimizedQrCustomizeSheet" class="padding-right-half">
        <f7-button class="btn-links btn-links-disabled">
          <i class="fas fa-save"></i>
        </f7-button>
      </span>
      <span class="padding-right-half">
        <f7-button
          v-if="!minimizedQrCustomizeSheet"
          class="btn-links"
          @click="minimizeQrCustomizeSheet"
          ><i class="fas fa-window-minimize"></i
        ></f7-button>
        <f7-button
          v-if="minimizedQrCustomizeSheet"
          class="btn-links"
          @click="maximizeQrCustomizeSheet"
          ><i class="fas fa-window-maximize"></i
        ></f7-button>
      </span>
      <span v-if="!minimizedQrCustomizeSheet" class="padding-right-half">
        <f7-button class="btn-links" @click="closeSheet">
          <i class="fas fa-times"></i>
        </f7-button>
      </span>
      <span v-if="minimizedQrCustomizeSheet" class="padding-right-half">
        <f7-button class="btn-links btn-links-disabled">
          <i class="fas fa-times"></i>
        </f7-button>
      </span>
    </template>
  </f7-navbar>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import store from "../../js/store";
import utils from "../../js/utils";

export default {
  props: {
    props: Object,
    f7router: Object,
  },
  setup() {
    const customQR = useStore("customQR");
    const customQRStyle = useStore("customQRStyle");
    const customQRMainColor = useStore("customQRMainColor");
    const customQRText = useStore("customQRText");
    const customQRSubText = useStore("customQRSubText");
    const customQRImage = useStore("customQRImage");
    const customQRSize = useStore("customQRSize");
    const customQRDotColor = useStore("customQRDotColor");
    const customQRCornerColor = useStore("customQRCornerColor");
    const customQRDotCornerColor = useStore("customQRDotCornerColor");
    const emojiPicker = useStore("emojiPicker");

    return {
      customQR,
      customQRStyle,
      customQRMainColor,
      customQRText,
      customQRSubText,
      customQRImage,
      customQRSize,
      customQRDotColor,
      customQRCornerColor,
      customQRDotCornerColor,
      emojiPicker
    };
  },
  data() {
    return {
      minimizedQrCustomizeSheet: false,
    }
  },
  methods: {
    updateCustomQRCode() {
      const self = this;

      utils.axiosCall(
        "post",
        "linqr",
        "codigo_qr_local",
        "update_codigo_qr_personalizado",
        {
          tamanio: self.customQRSize,
          estilo: self.customQRStyle,
          texto_superior: self.customQRText,
          texto_inferior: self.customQRSubText,
          muestra_logo: self.customQRImage,
          color_puntos: self.customQRDotColor,
          color_esquineros: self.customQRCornerColor,
          color_interior_esquineros: self.customQRDotCornerColor,
        },
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          utils.toastConfig.text = r.data.message.message;
          utils.toastConfig.cssClass = "success-toast no-printme";
          f7.toast.create(utils.toastConfig).open();


          self.emojiPicker.hidePicker();
          self.emojiElementDeselect();

          self.loading = false;
          
          f7.sheet.close();
        },
        function (e) {}
      );
    },
    minimizeQrCustomizeSheet() {
      const self = this;
      self.minimizedQrCustomizeSheet = true;

      let el = document.querySelector(".qr-customize-sheet");
      el.style.transform = "translate3d(0,85%,0)";
    },
    maximizeQrCustomizeSheet() {
      const self = this;
      self.minimizedQrCustomizeSheet = false;

      let el = document.querySelector(".qr-customize-sheet");
      el.style.removeProperty("transform");
    },
    closeSheet() {
      const self = this;
      self.f7router.navigate("/admin/qr/", {
        reloadCurrent: true,
        ignoreCache: true,
      });
      // f7.sheet.close(".qr-customize-sheet");
      // f7.sheet.destroy();
      // self.emojiPicker.hidePicker();
      // self.emojiElementDeselect();
      // setTimeout(() => {
      //   let el = document.querySelector(".qr-customize-sheet").remove();
      // }, 500);
    },
    emojiElementDeselect() {
      const self = this;
      store.dispatch("setEmojiElementSelected", "");
      self.emojiPicker.hidePicker();
    },
  }
};
</script>