import { f7, f7ready, useStore } from "framework7-vue";
import store from "./store";
import utils from "./utils";
import billing from "./billing";
import crudLocal from "./crud-local";

let checkLogin = async (app) => {
  let tokenValidation = await new Promise(async (resolve, reject) => {
    if (localStorage.getItem("token")) {
      await verifyRedirectMPIntegration();
      await verifyRedirectInstagramIntegration();
      crudLocal.getLocal(this, false);
      resolve();
    } else {
      await verifRedirectRecoverPassword();
      // self.props.loading = false;
      store.dispatch("setUserLogged", null);
      reject();
    }
  });

  // let preloaderDialog = app.dialog.preloader("Aguarde", "#222222");

  // let token = await new Promise((resolve, reject) => {
  //   resolve(localStorage.getItem("token"));
  // });
  // let tokenExpiration = await new Promise((resolve, reject) => {
  //   resolve(sessionStorage.getItem("token_expiration"));
  // });

  // if (tokenExpiration) {
  //   let parseTokenExpiration = parse(
  //     tokenExpiration,
  //     "yyyy-MM-dd HH:mm:ss",
  //     new Date()
  //   );
  //   let isTokenExpired = compareAsc(new Date(), new Date(parseTokenExpiration));

  //   if (token && isTokenExpired === -1) {
  //     preloaderDialog.close();
  //     preloaderDialog.el.remove();
  //     getUsuario();

  //     return;
  //   } else {
  //     localStorage.removeItem("token");
  //     sessionStorage.removeItem("token_expiration");
  //     preloaderDialog.close();
  //     preloaderDialog.el.remove();
  //     throw new Error("Usuario No Logueado");
  //   }
  // } else {
  //   localStorage.removeItem("token");
  //   sessionStorage.removeItem("token_expiration");
  //   preloaderDialog.close();
  //   preloaderDialog.el.remove();
  //   throw new Error("Usuario No Logueado");
  // }
};

let verifRedirectRecoverPassword = async () => {
  let urlParams = new URLSearchParams(window.location.search);

  return new Promise((resolve, reject) => {
    localStorage.setItem("recover_token", urlParams.get("token"));
    if (
      urlParams.has("recover_password") &&
      urlParams.get("recover_password") === "true"
    ) {
      if (urlParams.has("token")) {
        store.dispatch(
          "setRecoverPasswordToken",
          urlParams.get("recover_password")
        );
        resolve();
      }
      if (urlParams.has("recover_email")) {
        store.dispatch(
          "setRecoverPasswordEmail",
          urlParams.get("recover_email")
        );
        resolve();
      }
    } else {
      resolve();
    }
  });
};

let verifyRedirectMPIntegration = async () => {
  let urlParams = new URLSearchParams(window.location.search);

  return new Promise((resolve, reject) => {
    if (
      urlParams.has("mp_connect_succes") &&
      urlParams.get("mp_connect_succes") === "true"
    ) {
      utils.toastConfig.text =
        "Excelente! Has conectado tu cuenta de Mercado Pago";
      utils.toastConfig.cssClass = "success-toast";
      f7.toast.create(utils.toastConfig).open();
      resolve();
    } else if (
      urlParams.has("mp_connect_succes") &&
      urlParams.get("mp_connect_succes") === "false"
    ) {
      utils.toastConfig.text =
        "No hemos podido conectar tu cuenta de Mercado Pago. Intentalo nuevamente.";
      utils.toastConfig.cssClass = "error-toast";
      f7.toast.create(utils.toastConfig).open();
      resolve();
    } else {
      resolve();
    }
  });
};

let verifyRedirectInstagramIntegration = async () => {
  let urlParams = new URLSearchParams(window.location.search);

  return new Promise((resolve, reject) => {
    if (
      urlParams.has("ig_connect_succes") &&
      urlParams.get("ig_connect_succes").startsWith("true")
    ) {
      utils.toastConfig.text =
        "Excelente! Has conectado tu cuenta de Instagram";
      utils.toastConfig.cssClass = "success-toast";
      f7.toast.create(utils.toastConfig).open();
      resolve();
    } else if (
      urlParams.has("ig_connect_succes") &&
      urlParams.get("ig_connect_succes").startsWith("false")
    ) {
      utils.toastConfig.text =
        "No hemos podido conectar tu cuenta de Instagram. Intentalo nuevamente.";
      utils.toastConfig.cssClass = "error-toast";
      f7.toast.create(utils.toastConfig).open();
      resolve();
    } else {
      resolve();
    }
  });
};

// let validateAccessToken = function () {
//   const self = this;
//   let urlParams = new URLSearchParams(window.location.search);
//   let userLogged = useStore("userLogged");

//   return new Promise(function (resolve, reject) {
//     // Usuario Logueado VS Usuario No Logueado
//     if (userLogged.value !== null) {
//       utils.axiosCall(
//         "post",
//         "linqr",
//         "usuario_app",
//         "validate_access_token",
//         {},
//         {
//           "Linqr-Token": localStorage.getItem("token"),
//         },
//         function (r) {
//           // history.replaceState({}, "", location.href.split("?")[0]);

//           store.dispatch("setUsuarioEmail", r.data.message.email_usuario);
//           // f7.views.main.router.navigate("/admin/dash/");

//           // f7.loginScreen.close();
//           // f7.loginScree.hide();

//           crudLocal.getLocal(this, false);
//           resolve();
//         },
//         function (e) {
//           store.dispatch("setUserLogged", null);
//           localStorage.removeItem("token");
//           sessionStorage.removeItem("token_expiration");
//           reject();
//         }
//       );
//     } else {
//       // Muestra el login, el registro o el recupero de contraseña
//       // if (urlParams.has("login") && urlParams.get("login") === "true") {
//       //   self.props.loginScreenOpen = true;
//       // }
//       // else if (
//       //   urlParams.has("register") &&
//       //   urlParams.get("register") === "true"
//       // ) {
//       //   // self.props.loginScreenOpen = false;
//       // } else {
//       //   // self.props.loginScreenOpen = true;
//       // }
//       // if (urlParams.has("user")) {
//       //   self.props.nombre_unico_local_middleware = urlParams.get("user");
//       // }
//       // Si esta logueado tiene que cerrar la sesión
//       // self.props.loading = false;
//       // resolve();
//     }
//   });
// };

let login = async (self, e) => {
  f7.dialog.preloader("Iniciando Sesión");

  let invalidFields = await new Promise((resolve, reject) => {
    if (!self.usuarioLogin || !self.contraseniaLogin) {
      resolve(true);
    } else {
      resolve(false);
    }
  });

  if (invalidFields) {
    utils.toastConfig.text = "Debes completar todos los campos";
    utils.toastConfig.cssClass = "error-toast";
    f7.toast.create(utils.toastConfig).open();
    f7.dialog.close();
    return;
  }

  utils.axiosCall(
    "post",
    "linqr",
    "usuario_app",
    "login",
    {
      usr: self.usuarioLogin,
      pwd: self.contraseniaLogin,
    },
    {},
    function (r) {
      if (r.data.message.success === true) {
        localStorage.setItem("token", r.data.message.token);
        // store.dispatch("setUsuarioEmail", r.data.message.email_usuario);
        store.dispatch("setUserLogged", true);

        crudLocal.getLocal(self).then((r) => {
          self.usuarioLogin = "";
          self.contraseniaLogin = "";

          f7.loginScreen.close("#my-login-screen");
          f7.loginScreen.close("#my-signin-screen");
          f7.loginScreen.close("#my-recover-screen");
          f7.dialog.close();

          f7.views.main.router.navigate(`/admin`, {
            reloadCurrent: true,
          });

          utils.toastConfig.text = `Bienvenid@ 👋`;
          utils.toastConfig.cssClass = "success-toast";
          f7.toast.create(utils.toastConfig).open();
        });
      } else {
        utils.toastConfig.text = "No se ha podido iniciar sesión";
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
        f7.dialog.close();
      }
    }
  );
};

let signup = async (self, e) => {
  f7.dialog.preloader("Creando Sitio");

  let invalidFields = await new Promise((resolve, reject) => {
    if (
      !self.usuarioSignup ||
      !self.contraseniaSignup ||
      !self.uniquePageName ||
      !self.urlPage
    ) {
      resolve(true);
    } else {
      resolve(false);
    }
  });

  if (invalidFields) {
    utils.toastConfig.text = "Debes completar todos los campos";
    utils.toastConfig.cssClass = "error-toast";
    f7.toast.create(utils.toastConfig).open();
    f7.dialog.close();
    return;
  }

  utils.axiosCall(
    "post",
    "linqr",
    "usuario_app",
    "signup",
    {
      usr: self.usuarioSignup,
      pwd: self.contraseniaSignup,
      nombre_unico_local: self.uniquePageName,
      url_local: self.urlPage,
    },
    {},
    function (r) {
      if (r.data.message.success === true) {
        localStorage.setItem("token", r.data.message.token);
        self.userLogged = true;
        sessionStorage.setItem("onboarding", true);
        store.dispatch("setUserLogged", true);

        crudLocal.getLocal(self);

        crudLocal.getLocal(self).then((r) => {
          self.usuarioSignup = "";
          self.contraseniaSignup = "";
          self.uniquePageName = "";
          self.urlPage = "";
          self.usuarioLogin = "";
          self.contraseniaLogin = "";

          f7.loginScreen.close("#my-login-screen");
          f7.loginScreen.close("#my-signin-screen");
          f7.loginScreen.close("#my-recover-screen");
          f7.dialog.close();

          f7.views.main.router.navigate(`/admin`, {
            reloadCurrent: true,
          });
        });
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
        f7.dialog.close();
      }
    }
  );
};

let changePassword = async (self, e) => {
  f7.dialog.preloader("Restaurando Contraseña");

  let invalidFields = await new Promise((resolve, reject) => {
    if (!self.contraseniaRecover) {
      resolve(true);
    } else {
      resolve(false);
    }
  });

  if (invalidFields) {
    utils.toastConfig.text = "Debes completar todos los campos";
    utils.toastConfig.cssClass = "error-toast";
    f7.toast.create(utils.toastConfig).open();
    f7.dialog.close();
    return;
  }

  utils.axiosCall(
    "post",
    "linqr",
    "usuario_app",
    "change_password",
    {
      usr: self.recoverPasswordEmail,
      pwd: self.contraseniaRecover,
    },
    {
      "Linqr-Token": localStorage.getItem("recover_token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        localStorage.setItem("token", r.data.message.token);
        localStorage.removeItem("recover_token");

        // store.dispatch("setUsuarioEmail", r.data.message.email_usuario);
        store.dispatch("setUserLogged", true);

        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();

        self.recoverPasswordEmail = "";
        // self.props.loginScreenOpen = false;
        // self.props.signupScreenOpen = false;
        // self.props.forgetPasswordOpen = false;
        store.dispatch("setRecoverPasswordToken", undefined);

        f7.loginScreen.close("#my-login-screen");
        f7.loginScreen.close("#my-recover-screen");
        f7.loginScreen.close("#my-signin-screen");
        f7.dialog.close();

        f7.views.main.router.navigate("/admin", {
          reloadCurrent: true,
        });
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();

        f7.dialog.close();
      }
    }
  );
};

let changePasswordInsideApp = async (self, e) => {
  f7.dialog.preloader("Cambiando Contraseña");

  let invalidFields = await new Promise((resolve, reject) => {
    if (!self.contraseniaActual || !self.contraseniaNew) {
      resolve(true);
    } else {
      resolve(false);
    }
  });

  if (invalidFields) {
    utils.toastConfig.text = "Debes completar todos los campos";
    utils.toastConfig.cssClass = "error-toast";
    f7.toast.create(utils.toastConfig).open();
    f7.dialog.close();
    return;
  }

  utils.axiosCall(
    "post",
    "linqr",
    "usuario_app",
    "change_password_inside_app",
    {
      current_password: self.contraseniaActual,
      new_password: self.contraseniaNew,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();
        f7.dialog.close();
        self.props.sheetChangePasswordOpen = false;
      } else {
        utils.toastConfig.text =
          "Su contraseña no ha podido ser cambiada. Intente nuevamente.";
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
        f7.dialog.close();
      }
    }
  );
};

let recoverPassword = async (self, e) => {
  let invalidFields = await new Promise((resolve, reject) => {
    if (!self.recoverPasswordEmail) {
      resolve(true);
    } else {
      resolve(false);
    }
  });

  if (invalidFields) {
    utils.toastConfig.text =
      "Debes completar la dirección de email con la que te registraste en miqro.app";
    utils.toastConfig.cssClass = "error-toast";
    f7.toast.create(utils.toastConfig).open();
    f7.dialog.close();
    return;
  }
  utils.axiosCall(
    "post",
    "linqr",
    "usuario_app",
    "forget_password",
    {
      usr: self.recoverPasswordEmail,
    },
    {},
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();

        self.recoverPasswordEmail = "";
        // f7.views.main.router.navigate("/admin/dash/");
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
      }
    }
  );
};

export default {
  checkLogin,
  // validateAccessToken,
  login,
  signup,
  changePassword,
  changePasswordInsideApp,
  recoverPassword,
};
