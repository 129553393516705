<template>
  <f7-panel right cover>
    <f7-view>
      <f7-page>
        <f7-block
          class="no-margin no-padding text-align-center"
          style="height: 100%"
        >
          <f7-row>
            <f7-col
              ><img
                class="padding-vertical"
                style="width: 60%"
                src="/static/images/miqroapp_logo_v3.png"
                alt=""
            /></f7-col>
          </f7-row>
          <!-- <f7-row>
            <f7-col class="display-flex justify-content-center padding-left">
              <a
                class="
                  display-flex
                  flex-direction-column
                  justify-content-center
                  text-align-center
                  align-items-center
                  btn-links-lg
                  padding-vertical-half
                "
                @click="navigateToPreview"
              >
                <i class="fas fa-eye padding-top-half"></i>
                <span class="padding-top-half">Vista Previa</span>
              </a>
            </f7-col>
            <f7-col class="display-flex justify-content-center padding-right">
              <a
                class="
                  display-flex
                  flex-direction-column
                  justify-content-center
                  text-align-center
                  align-items-center
                  btn-links-lg
                  padding-vertical-half
                "
                href="#"
                @click="nativeShare"
              >
                <i class="fas fa-share-alt padding-top-half"></i>
                <span class="padding-top-half">Compartir</span>
              </a>
            </f7-col>
          </f7-row> -->
          <!-- <f7-row class="align-items-center">
            <f7-col>
              <f7-button
                class="display-block"
                style="text-transform: capitalize; width: 100%"
                @click="navigateToPreview"
              >
                <i class="fas fa-eye"></i>
                <br />
                Ver Sitio</f7-button
              >
            </f7-col>
            <f7-col>
              <f7-button
                class="display-block"
                style="text-transform: capitalize; width: 100%"
                @click="navigateToPreview"
              >
                <span
                  ><i class="fas fa-share-alt"></i>
                  <br />
                  Compartir</span
                ></f7-button
              >
            </f7-col>
          </f7-row> -->
          <f7-row>
            <f7-col>
              <f7-list class="no-hairlines no-hairlines-between no-margin-top">
                <!-- Mi Sitio -->
                <f7-list-item
                  class="no-padding-left"
                  no-chevron
                  title="Mi Sitio"
                  view=".view-main"
                  link="#"
                  @click="navigateToPreview"
                  reload-current
                >
                  <template #media>
                    <i class="fas fa-rocket"></i>
                  </template>
                </f7-list-item>
                <!-- Estadisticas -->
                <f7-list-item
                  class="no-padding-left"
                  no-chevron
                  title="Estadísticas"
                  view=".view-main"
                  link="/admin/dash/"
                  reload-current
                  :disabled="isCurrentPage('dash') ? true : false"
                >
                  <template #media>
                    <i class="fas fa-chart-bar"></i>
                  </template>
                </f7-list-item>
                <!-- Bloques -->
                <!-- <f7-list-item
                  class="no-padding-left"
                  no-chevron
                  title="Mis Bloques"
                  view=".view-main"
                  link="/admin/links/"
                  reload-current
                  :disabled="isCurrentPage('links') ? true : false"
                >
                  <template #media>
                    <i class="fas fa-th-large"></i>
                  </template>
                </f7-list-item> -->
                <!-- Cóodigo QR -->
                <f7-list-item
                  class="no-padding-left"
                  no-chevron
                  title="Mi Código QR"
                  view=".view-main"
                  link="/admin/qr/"
                  reload-current
                  :disabled="isCurrentPage('qr') ? true : false"
                >
                  <template #media>
                    <i class="fas fa-qrcode"></i>
                  </template>
                </f7-list-item>
                <!-- Mi Perfil -->
                <f7-list-item
                  class="no-padding-left"
                  no-chevron
                  title="Mi Perfil"
                  view=".view-main"
                  link="/admin/local/"
                  reload-current
                  :disabled="isCurrentPage('local') ? true : false"
                >
                  <template #media>
                    <i class="fas fa-store"></i>
                  </template>
                </f7-list-item>
                <!-- Mi Cuenta -->
                <f7-list-item
                  class="no-padding-left"
                  no-chevron
                  title="Mi Cuenta"
                  view=".view-main"
                  link="/admin/account/"
                  reload-current
                  :disabled="isCurrentPage('account') ? true : false"
                >
                  <template #media>
                    <i class="fas fa-user"></i>
                  </template>
                </f7-list-item>
                <!-- Divisor -->
                <f7-list-item
                  divider
                  style="background-color: #ffffff"
                ></f7-list-item>
                <!-- Tutorial -->
                <f7-list-item
                  class="no-padding-left"
                  no-chevron
                  title="Tutorial"
                  @click="openMainVideoTutorial"
                >
                  <template #media>
                    <i class="fas fa-question-circle"></i>
                  </template>
                </f7-list-item>
                <!-- Ayuda -->
                <f7-list-item
                  class="no-padding-left"
                  external
                  no-chevron
                  title="Escribinos"
                  link="https://wa.me/541158901003"
                >
                  <template #media>
                    <i class="fab fa-whatsapp"></i>
                  </template>
                </f7-list-item>
                <!-- Plan Pro -->
                <f7-list-item
                  v-if="
                    suscripcion.nombre_suscripcion_actual === 'Gratuito' &&
                    pruebaGratuita == false
                  "
                  class="no-padding-left main-menu-pro-item"
                  external
                  no-chevron
                  link="#"
                  @click="openTryProSheet"
                >
                  <template #title>
                    <div class="width: 100%">
                      <i class="fas fa-crown"></i>&nbsp;&nbsp;PASATE A PRO
                    </div>
                  </template>
                </f7-list-item>
              </f7-list>
            </f7-col>
          </f7-row>
          <f7-row
            class="margin-vertical"
            style="position: absolute; bottom: 0; width: 100%"
          >
            <f7-col
              ><f7-button class="button-with-icon" color="red" @click="signOut"
                ><i class="fas fa-sign-out-alt"></i>&nbsp;&nbsp;Salir</f7-button
              ></f7-col
            >
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import store from "../js/store";
import account from "../js/account";

export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  setup() {
    const local = useStore("local");
    const suscripcion = useStore("suscripcion");
    const pruebaGratuita = suscripcion.value.prueba_gratuita;

    return {
      local,
      suscripcion,
      pruebaGratuita,
    };
  },
  data() {
    return {
      currentPage: "",
    };
  },
  mounted() {
    const self = this;
    self.currentPage = self.f7router.currentRoute.path;
  },
  methods: {
    signOut() {
      account.signOut();
    },
    openMainVideoTutorial() {
      const self = this;

      f7.panel.close();
      store.dispatch("setOpenMainVideoTutorial", true);
    },
    navigateToPreview() {
      const self = this;

      f7.views.main.router.navigate(
        // `/admin/${self.local.nombre_unico_local}/`,
        `/admin`,
        {
          ignoreCache: false,
          realoadAll: false,
        }
      );
    },
    isCurrentPage(linkPath) {
      const self = this;
      var regex = new RegExp("^/admin/" + linkPath + "/*/");

      if (self.currentPage && self.currentPage.match(regex)) {
        return true;
      } else {
        return false;
      }
    },
    nativeShare() {
      const self = this;
      const localCap = "";

      if (self.local.nombre !== undefined) {
        localCap =
          self.local.nombre.charAt(0).toUpperCase() +
          self.local.nombre.slice(1);
      }

      if (navigator.share) {
        navigator
          .share({
            title: `${localCap}`,
            text: `${self.local.nombre_local} 👉`,
            url: self.local.url_local,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log(error));
      } else {
        // self.f7router.navigate("/admin/share/");
      }
    },
    openTryProSheet() {
      const self = this;
      store.dispatch("setOpenTryProSheet", true);
    },
  },
};
</script>
