<template>
  <!-- ACORDEON - INFORMACION GENERAL -->
  <span>
    <!-- <f7-list-item
      class="justify-content-center"
      v-if="
        (props.linkTypeId === 'feed_instagram' &&
          connectingWithInstagram === false &&
          connectedWithInstagram === false) ||
        (props.linkTypeId === 'producto_instagram' &&
          connectingWithInstagram === false &&
          connectedWithInstagram === false)
      "
    >
      <f7-button class="btn-instagram" fill @click="conectarInstagram">
        &nbsp;&nbsp;Conectar con Instagram
      </f7-button>
    </f7-list-item>
    <f7-list-item
      v-if="
        props.linkTypeId === 'feed_instagram' ||
        props.linkTypeId === 'producto_instagram'
      "
    >
      <span>Conectado con Instagram</span>
      <f7-toggle
        color="green"
        name="integracion_instagram"
        disabled
        :checked="
          (props.linkTypeId === 'feed_instagram' &&
            connectedWithInstagram === true) ||
          (props.linkTypeId === 'producto_instagram' &&
            connectedWithInstagram === true)
            ? true
            : false
        "
        @change="(e) => setValueForm(e)"
      ></f7-toggle>
    </f7-list-item> -->
    <!-- SELECCION DE POST INSTAGRAM -->
    <li
      v-show="
        props.linkTypeId === 'producto_instagram' && connectedWithInstagram
      "
    >
      <div class="item-content item-input item-input-outline">
        <div class="item-inner">
          <f7-button
            fill
            large
            color="blue"
            @click="props.actionSelectInstagramPostProduct = true"
            style="width: 100%"
            >SELECCIONAR POST DE INSTAGRAM</f7-button
          >
        </div>
      </div>
    </li>
    <!-- TITULO -->
    <f7-list-input
      v-if="props.linkTypeId !== 'titulo_de_seccion'"
      outline
      id="input_nombre_bloque"
      label="Nombre del Bloque"
      type="text"
      name="nombre_bloque"
      @input="setValueForm"
      :value="props.dataBloque.nombre_bloque"
      placeholder="Título del Link"
      required
      validate
      @click="
        (e) => {
          emojiElementSelect(e, 'input');
        }
      "
    >
    </f7-list-input>
    <f7-list-input
      v-if="
        props.linkTypeId === 'producto' ||
        props.linkTypeId === 'producto_instagram'
      "
      outline
      label="Código Interno"
      type="text"
      name="codigo_interno"
      @input="setValueForm"
      :value="props.dataBloque.codigo_interno"
      placeholder="Ingrese su código identificatorio"
      validate
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
    ></f7-list-input>
    <!-- SUBTITULO -->
    <f7-list-input
      v-if="props.linkTypeId === 'imagen' || props.linkTypeId === 'texto'"
      outline
      label="Subtítulo"
      type="text"
      name="subtitulo"
      @input="setValueForm"
      :value="props.dataBloque.subtitulo"
      placeholder="Escribe el Subtítulo"
      @click="
        (e) => {
          emojiElementSelect(e, 'input');
        }
      "
    ></f7-list-input>
    <!-- ENLACE -->
    <f7-list-input
      v-if="props.linkTypeId === 'enlace_simple'"
      outline
      label="Dirección Web"
      type="url"
      name="enlace"
      @input="setValueForm"
      :value="props.dataBloque.enlace"
      placeholder="https://ejemplo.com"
      required
      validate
      pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
      error-message="Ingresa una dirección web válida"
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
    ></f7-list-input>
    <!-- TEXTO -->
    <f7-list-input
      v-if="
        props.linkTypeId === 'texto' ||
        props.linkTypeId === 'producto' ||
        props.linkTypeId === 'producto_instagram' ||
        props.linkTypeId === 'imagen'
      "
      outline
      id="textarea_texto"
      type="textarea"
      label="Texto"
      name="texto"
      @input="setValueForm"
      :value="props.dataBloque.texto"
      placeholder="Escribe el texto a mostrar"
      validate
      :clear-button="true"
      @click="
        (e) => {
          emojiElementSelect(e, 'textarea');
        }
      "
    >
    </f7-list-input>
    <!-- <li
      v-if="
        props.linkTypeId === 'feed_instagram' ||
        props.linkTypeId === 'producto_instagram'
      "
    >
      <f7-row class="justify-content-center">
        <f7-col
          width="33"
          v-for="post in props.instagramPosts.data"
          :key="post.id"
          :data-post-id="post.id"
          @click="(e) => selectImagenInstagram(e)"
        >
          <img
            class="margin-half"
            style="object-fit: cover; width: -webkit-fill-available"
            :src="post.media_url"
            alt=""
          />
        </f7-col>
      </f7-row>
    </li> -->
    <fileUploadsBlockComponent :props="props" />
    <!-- MICRO POST | DESCRIPCION ITEM -->
    <f7-list-input
      v-if="props.linkTypeId === 'micro_post'"
      class="no-hairlines no-hairlines-between"
      type="texteditor"
      name="editor_texto"
      el="texteditor-post"
      label="Contenido"
      :clear-formatting-on-paste="false"
      :resizable="true"
      :value="props.dataBloque.editor_texto"
      style="--f7-text-editor-height: 150px"
      @texteditor:input="
        (e) => {
          setValueForm(e);
        }
      "
      :text-editor-params="{
        buttons: [
          ['bold', 'italic', 'underline', 'strikeThrough'],
          ['orderedList', 'unorderedList'],
          ['paragraph', 'h1', 'h2', 'h3'],
          ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
          ['subscript', 'superscript'],
          ['indent', 'outdent'],
          ['hr', 'customlink'],
        ],
        customButtons: props.customButtons,
        on: {
          input: (e) => {
            setValueForm(e);
          },
        },
      }"
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
      required
      validate
    ></f7-list-input>
    <!-- STOCK -->
    <!-- <f7-list-input
      style="width: 50%; display: inline-block"
      v-if="props.linkTypeId === 'producto'"
      outline
      label="Stock"
      type="text"
      name="stock"
      @input="setValueForm"
      :value="props.dataBloque.stock"
      placeholder="5"
      validate
    ></f7-list-input> -->
    <!-- MONEDA -->
    <f7-list-input
      v-if="
        props.linkTypeId === 'producto' ||
        props.linkTypeId === 'producto_instagram'
      "
      outline
      label="Moneda (Opcional)"
      type="text"
      name="moneda_importe"
      @input="setValueForm"
      :value="props.dataBloque.moneda_importe"
      placeholder="USD, ARS"
      validate
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
    ></f7-list-input>
    <!-- IMPORTE -->
    <f7-list-input
      style="width: 50%; display: inline-block"
      v-if="
        props.linkTypeId === 'producto' ||
        props.linkTypeId === 'producto_instagram'
      "
      outline
      label="Importe"
      type="number"
      name="importe"
      @input="setValueForm"
      :value="props.dataBloque.importe"
      placeholder="15.500"
      validate
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
    ></f7-list-input>
    <!-- IMPORTE CON DESCUENTO -->
    <f7-list-input
      style="width: 50%; display: inline-block"
      v-if="
        props.linkTypeId === 'producto' ||
        props.linkTypeId === 'producto_instagram'
      "
      outline
      label="Importe con Descuento"
      type="number"
      name="importe_descuento"
      @input="setValueForm"
      :value="props.dataBloque.importe_descuento"
      placeholder="10.500"
      validate
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
    ></f7-list-input>
    <!-- PRODUCTO | ACCION DE BOTON -->
    <li
      v-show="
        props.linkTypeId === 'producto' ||
        props.linkTypeId === 'producto_instagram'
      "
    >
      <div class="item-content item-input item-input-outline">
        <div class="item-inner">
          <div class="item-title item-label">
            Acción al tocar el botón del Producto
          </div>
          <f7-list class="radio-list">
            <f7-list-item
              v-if="
                props.linkTypeId === 'producto' ||
                props.linkTypeId === 'producto_instagram'
              "
              radio
              radio-icon="end"
              title="Recibir consulta por WhatsApp"
              name="accion_boton_cta_producto"
              :checked="
                props.dataBloque.accion_boton_cta_producto ===
                'consulta-whatsapp'
                  ? true
                  : false
              "
              @click="toastWhatsappContact"
              @change="(e) => setValueForm(e, 'consulta-whatsapp')"
            ></f7-list-item>
            <f7-list-item
              radio
              radio-icon="end"
              title="Recibir consulta por e-mail"
              name="accion_boton_cta_producto"
              data-value="consulta-email"
              :checked="
                props.dataBloque.accion_boton_cta_producto === 'consulta-email'
                  ? true
                  : false
              "
              @click="toastEmailContact"
              @change="(e) => setValueForm(e, 'consulta-email')"
            ></f7-list-item>
            <f7-list-item
              radio
              radio-icon="end"
              title="Redirigir a un link externo"
              name="accion_boton_cta_producto"
              data-value="redireccionar-url"
              :checked="
                props.dataBloque.accion_boton_cta_producto ===
                'redireccionar-url'
                  ? true
                  : false
              "
              @change="(e) => setValueForm(e, 'redireccionar-url')"
            ></f7-list-item>
            <f7-list-item
              radio
              radio-icon="end"
              title="Cobrar por MercadoPago"
              name="accion_boton_cta_producto"
              data-value="mercadopago"
              :checked="
                props.dataBloque.accion_boton_cta_producto === 'mercadopago'
                  ? true
                  : false
              "
              @change="(e) => checkMercadoPagoIntegration(e)"
            ></f7-list-item>
          </f7-list>
        </div>
      </div>
    </li>

    <!-- TEXTO DEL BOTON CTA -->
    <f7-list-input
      v-if="
        props.linkTypeId === 'micro_post' ||
        props.linkTypeId === 'imagen' ||
        props.linkTypeId === 'producto' ||
        props.linkTypeId === 'producto_instagram'
      "
      outline
      id="input_texto_boton_cta"
      label="Texto del Botón de Acción"
      type="text"
      name="texto_boton_cta"
      @input="setValueForm"
      :value="props.dataBloque.texto_boton_cta"
      placeholder="Ej: Mas Info, Comprar, Contactar, etc."
      @click="
        (e) => {
          emojiElementSelect(e, 'input');
        }
      "
    >
    </f7-list-input>
    <!-- ENLACE DEL BOTON CTA -->
    <f7-list-input
      v-if="
        props.linkTypeId === 'micro_post' ||
        props.linkTypeId === 'imagen' ||
        (props.linkTypeId === 'producto' &&
          props.dataBloque.accion_boton_cta_producto === 'redireccionar-url') ||
        (props.linkTypeId === 'producto_instagram' &&
          props.dataBloque.accion_boton_cta_producto === 'redireccionar-url')
      "
      outline
      label="Enlace del Botón de Acción"
      type="text"
      name="enlace_boton_cta"
      @input="setValueForm"
      :value="props.dataBloque.enlace_boton_cta"
      placeholder="URL donde direcciona el botón."
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
      validate
      pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
      error-message="Ingresa una dirección web válida"
    ></f7-list-input>
    <!-- SOLICITAR EMAIL PARA DESCARGA -->
    <f7-list-item v-if="props.linkTypeId === 'archivo'">
      <span>Solicitar e-mail para descargar</span>
      <f7-toggle
        color="green"
        name="archivo_solicitar_email"
        :checked="
          props.dataBloque.archivo_solicitar_email &&
          props.dataBloque.archivo_solicitar_email === 1
            ? true
            : false
        "
        @change="(e) => setValueForm(e)"
      ></f7-toggle>
    </f7-list-item>
    <!-- VIDEO -->
    <f7-list-input
      v-if="props.linkTypeId === 'video'"
      outline
      label="URL Video"
      type="url"
      name="video_url"
      @input="setValueForm"
      :value="props.dataBloque.video_url"
      placeholder="Youtube, Vimeo, Etc."
      required
      validate
      pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
      error-message="Ingresa una dirección web válida"
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
    ></f7-list-input>
    <!-- FORMULARIO -->
    <f7-list-input
      v-if="props.linkTypeId === 'formulario'"
      outline
      label="Email de Recepción"
      type="email"
      name="form_email_consultas"
      @input="setValueForm"
      :value="props.dataBloque.form_email_consultas"
      placeholder="Donde desea recibir las consultas"
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
    ></f7-list-input>
    <f7-list-input
      v-if="props.linkTypeId === 'whatsapp'"
      outline
      label="Numero de WhatsApp"
      type="text"
      name="whatsapp_numero"
      @input="setValueForm"
      :value="props.dataBloque.whatsapp_numero"
      placeholder="541122223333"
      required
      validate
      pattern="[0-9]*"
      @click="
        (e) => {
          emojiElementDeselect();
        }
      "
    ></f7-list-input>
    <!-- WHATSAPP -->
    <f7-list-input
      v-if="props.linkTypeId === 'whatsapp'"
      outline
      id="input_whatsapp_mensaje"
      label="Mensaje Predefinido"
      type="text"
      name="whatsapp_mensaje"
      @input="setValueForm"
      :value="props.dataBloque.whatsapp_mensaje"
      placeholder="Hola! Quisiera consultar sobre..."
      required
      validate
      data-type="input"
      @click="
        (e) => {
          emojiElementSelect(e, 'input');
        }
      "
    >
    </f7-list-input>
    <!-- TITULO DE SECCION -->
    <f7-list-input
      v-if="props.linkTypeId === 'titulo_de_seccion'"
      outline
      label="Título de Sección"
      type="text"
      id="input_nombre_bloque"
      name="nombre_bloque"
      @input="setValueForm"
      :value="props.dataBloque.nombre_bloque"
      placeholder="Escribe un título para tu sección"
      required
      validate
      data-type="input"
      @click="
        (e) => {
          emojiElementSelect(e, 'input');
        }
      "
    >
    </f7-list-input>
    <!-- FEED INSTAGRAM -->
    <!-- <f7-list-input
      v-if="props.linkTypeId === 'feed_instagram'"
      outline
      label="Total de Posts Instagram"
      type="number"
      max="50"
      name="total_posts_instagram"
      @input="setValueForm"
      placeholder="La cantidad de Posts que deseas mostrar"
      :value="props.dataBloque.total_posts_instagram"
      required
      validate
    ></f7-list-input> -->
    <!-- MAPA -->
    <!-- <f7-list-input
      v-if="props.linkTypeId === 'mapa'"
      outline
      label="URL Google Maps"
      type="url"
      name="mapa_url"
      @input="setValueForm"
      :value="props.dataBloque.mapa_url"
      placeholder="https://maps.google.com...."
      required
      validate
      pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
    ></f7-list-input> -->
    <actionInstagramPosts :props="props" :instagramPosts="instagramPosts" />
  </span>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";
import crudSettings from "../../../js/crud-settings";
import utils from "../../../js/utils";
import store from "../../../js/store";
import { f7, useStore } from "framework7-vue";
import fileUploadsBlockComponent from "./block-components/file-uploads-block-component";
import actionInstagramPosts from "./block-components/action-instagram-posts";

export default {
  props: {
    f7route: Object,
    f7router: Object,
    props: Object,
  },
  data() {
    return {
      connectedWithInstagram: true,
      // actionsConectionMPOpened: false,
    };
  },
  components: {
    fileUploadsBlockComponent,
    actionInstagramPosts,
  },
  setup() {
    const instagramPosts = useStore("instagramPosts");
    const emojiPicker = useStore("emojiPicker");
    const local = useStore("local");

    return {
      emojiPicker,
      instagramPosts,
      local,
    };
  },
  mounted() {
    const self = this;
  },
  methods: {
    toastWhatsappContact() {
      const self = this;
      utils.toastConfig.text =
        "Recibiras la consulta al número de WhatsApp que hayas configurado en tu Perfil";
      utils.toastConfig.position = "bottom";
      utils.toastConfig.cssClass = "warning-toast";
      f7.toast.create(utils.toastConfig).open();
    },
    toastEmailContact() {
      const self = this;
      utils.toastConfig.text =
        "Recibiras la consulta al e-mail que hayas configurado en tu Perfil";
      utils.toastConfig.position = "bottom";
      utils.toastConfig.cssClass = "warning-toast";
      f7.toast.create(utils.toastConfig).open();
    },
    checkMercadoPagoIntegration(e) {
      const self = this;

      if (self.local.integracion_mercado_pago) {
        self.setValueForm(e, "mercadopago");
        utils.toastConfig.text =
          "Recuerde que por el momento solo realizamos cobros en Pesos Argentinos (ARS).";
        utils.toastConfig.position = "bottom";
        utils.toastConfig.cssClass = "warning-toast";
        f7.toast.create(utils.toastConfig).open();
      } else {
        utils.toastConfig.text =
          "Aún no has configurado tu cuenta de Mercado Pago.";
        utils.toastConfig.position = "bottom";
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();

        self.props.dataBloque.accion_boton_cta_producto = "mercadopago";
        setTimeout(() => {
          self.props.dataBloque.accion_boton_cta_producto = "consulta-whatsapp";
        }, 500);

        self.f7router.navigate("/admin/connect-mp/");

        self.actionsConectionMPOpened = true;
      }
    },
    setValueForm(e, radioValue) {
      const self = this;
      crudBloques.setValueForm(self, e, radioValue);
    },
    conectarMercadoPago() {
      const self = this;
      crudSettings.conectarMercadoPago(self);
    },
    selectImagenInstagram(e) {
      const self = this;
      e.target.style.border = "4px solid #222222";
      e.target.style.borderRadius = "5px";
    },
    emojiElementSelect(e, dataType) {
      const self = this;
      e.target.setAttribute("data-type", dataType);
      e.target.setAttribute("data-page-type", "bloques");
      store.dispatch("setEmojiElementSelected", e.target);
      store.dispatch("setEmojiPageData", self.props.dataBloque);

      // if (self.emojiPicker.isPickerVisible()) {
      //   self.emojiPicker.hidePicker();
      // }
    },
    emojiElementDeselect() {
      const self = this;
      store.dispatch("setEmojiElementSelected", "");
      self.emojiPicker.hidePicker();
    },
  },
};
</script>
