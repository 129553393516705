<template>
  <!-- Texto -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'texto'"
    class="item-texto link-item-list-preview"
    @click="registraEvento(bloque, 'click_bloque')"
  >
    <a style="width: 100%">
      <f7-row class="text-align-center padding">
        <f7-col width="100">
          <div v-if="bloque.subtitulo" class="item-title">{{ bloque.subtitulo }}</div>
          <div v-if="bloque.texto" style="white-space: pre-line" class="item-text">{{ bloque.texto }}</div>
          <!-- <div v-if="bloque.nombre_bloque" class="item-title"></div> -->
        </f7-col>
      </f7-row>
    </a>
  </div>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";

export default {
  props: {
    bloque: Object,
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
  },
};
</script>
