<template>
  <f7-page>
    <Login :props="props" />
    <Register :props="props" />
    <RecoverPasswsord :props="props" />
  </f7-page>
</template>

<script>
import Login from "../components/authentication/login.vue";
import Register from "../components/authentication/register";
import RecoverPasswsord from "../components/authentication/recover-password";

export default {
  components: {
    Login,
    Register,
    RecoverPasswsord,
  },
  data() {
    return {
      props: {
        loginScreenOpen: true,
        signupScreenOpen: false,
        forgetPasswordOpen: false,
        loading: false,
      },
    };
  },
};
</script>