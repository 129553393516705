<template>
  <f7-block class="block-bloques-list no-margin">
    <ul style="list-style: none; padding: 0">
      <li
        v-for="bloque in bloquesLocal"
        :key="bloque.name"
        :data-bloque-id="bloque.name"
        class="link-item-list-home-preview"
      >
        <div
          class="item-content item-input item-input-outline"
          v-if="checkHorarios(bloque)"
        >
          <f7-row
            class="no-margin"
            style="position: relative; padding-top: 20px"
            v-show="
              (!disableBloque(bloque) && bloque.habilitado) ||
              (props.loggedUser && !props.showPreview)
            "
          >
            <!-- BLOQUE PREMIUM -->
            <span
              v-if="disableBloque(bloque)"
              v-show="props.loggedUser && !props.showPreview"
              class="badge edit-block-badge"
              style="
                background-color: #ebebeb;
                color: #4e4e4e;
                border: 1px dashed #4e4e4e;
              "
              ><i class="fas fa-crown" style="color: #4e4e4e"></i
              >&nbsp;&nbsp;Bloque Premium</span
            >

            <!-- EDITAR BLOQUE -->
            <span
              v-if="!disableBloque(bloque) && bloque.habilitado"
              v-show="props.loggedUser && !props.showPreview"
              class="badge edit-block-badge"
              style="
                background-color: #caf0ff;
                color: #2e90cf;
                border: 1px dashed #2e90cf;
              "
              @click="
                f7router.navigate(`/admin/link/${bloque.name}/`, {
                  reloadPrevious: true,
                })
              "
              ><i class="fas fa-pen" style="color: #2e90cf"></i
              >&nbsp;&nbsp;Editar</span
            >

            <!-- BLOQUE DESHABILITADO -->
            <span
              v-else-if="!disableBloque(bloque) && !bloque.habilitado"
              v-show="props.loggedUser && !props.showPreview"
              class="badge edit-block-badge"
              style="
                background-color: #d6f5e0;
                color: #15c44f;
                border: 1px dashed #15c44f;
              "
              @click="
                f7router.navigate(`/admin/link/${bloque.name}/`, {
                  reloadPrevious: true,
                })
              "
              ><i class="fas fa-check" style="color: #15c44f"></i
              >&nbsp;&nbsp;Habilitar</span
            >

            <!-- BORRAR BLOQUE -->
            <span
              v-if="!disableBloque(bloque)"
              v-show="props.loggedUser && !props.showPreview"
              class="badge delete-block-badge"
              style="
                background-color: #f5d6d6;
                color: #c41515;
                border: 1px dashed #c41515;
              "
              @click="deleteBloque(bloque.name)"
              ><i class="fas fa-trash" style="color: #c41515"></i
              >&nbsp;&nbsp;Borrar</span
            >

            <!-- BLOQUES -->
            <blockEnlaceSimple :bloque="bloque" />
            <blockWhatsapp :bloque="bloque" />
            <blockTexto :bloque="bloque" />
            <blockImagen :bloque="bloque" />
            <blockGaleria :bloque="bloque" />
            <blockVideo :bloque="bloque" />
            <blockFormulario :bloque="bloque" :props="props" />
            <blockArchivo :bloque="bloque" />
            <blockProducto :bloque="bloque" :local="local" />
            <blockProductoInstagram :bloque="bloque" :local="local" />
            <blockTituloDeSeccion :bloque="bloque" />
            <blockFeedInstagram :bloque="bloque" :props="props" />
          </f7-row>
        </div>
      </li>
    </ul>
  </f7-block>
</template>

<script>
import crudBloques from "../../js/crud-bloques";
import { f7, useStore } from "framework7-vue";
import blockEnlaceSimple from "../links/blocks/block_enlace_simple.vue";
import blockWhatsapp from "../links/blocks/block_whatsapp.vue";
import blockTexto from "../links/blocks/block_texto.vue";
import blockImagen from "../links/blocks/block_imagen.vue";
import blockGaleria from "../links/blocks/block_galeria.vue";
import blockVideo from "../links/blocks/block_video.vue";
import blockFormulario from "../links/blocks/block_formulario.vue";
import blockArchivo from "../links/blocks/block_archivo.vue";
import blockProducto from "../links/blocks/block_producto.vue";
import blockProductoInstagram from "../links/blocks/block_producto_instagram.vue";
import blockTituloDeSeccion from "../links/blocks/block_titulo_de_seccion.vue";
import blockFeedInstagram from "../links/blocks/block_feed_instagram.vue";

export default {
  props: {
    f7router: Object,
    props: Object
  },
  components: {
    blockEnlaceSimple,
    blockWhatsapp,
    blockTexto,
    blockImagen,
    blockGaleria,
    blockVideo,
    blockFormulario,
    blockArchivo,
    blockProducto,
    blockProductoInstagram,
    blockTituloDeSeccion,
    blockFeedInstagram,
  },
  setup() {
    const suscripcion = useStore("suscripcion");
    const bloquesLocal = useStore("bloquesLocal");
    const local = useStore("local");
    // const loggedUser = localStorage.getItem("token");

    return {
      suscripcion,
      bloquesLocal,
      local,
      // loggedUser
    };
  },
  computed: {
    disableBloque: () => {
      return function (bloque) {
        if (
          this.suscripcion != undefined &&
          this.suscripcion.nombre_suscripcion_actual === "Gratuito" &&
          bloque.suscripcion_de_bloque === "Pro"
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    deleteBloque(bloqueId) {
      const self = this;

      f7.dialog.confirm("¿Esta seguro que desea eliminar este bloque?", () => {
        crudBloques.deleteBloque(self, bloqueId);
      });
    },
    checkHorarios(bloque) {
      const self = this;
      let now = Date.now();

      if (self.props.loggedUser && !self.props.showPreview) {
        return true;
      } else {
        if (!bloque.mostrar_desde && !bloque.mostrar_hasta) {
          return true;
        } else if (bloque.mostrar_desde && bloque.mostrar_hasta) {
          if (
            now > new Date(Date.parse(bloque.mostrar_desde)) &&
            now < new Date(Date.parse(bloque.mostrar_desde))
          ) {
            return true;
          }
        } else if (
          bloque.mostrar_desde &&
          now > new Date(Date.parse(bloque.mostrar_desde))
        ) {
          return true;
        } else if (
          bloque.mostrar_hasta &&
          now < new Date(Date.parse(bloque.mostrar_desde))
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>



<!-- Micro Post -->
<!-- <div
  v-if="bloque.id_tipo_de_bloque === 'micro_post'"
  class="item-micro-post link-item-list-preview"
>
  <f7-card
    class="no-margin"
    expandable
    :backdrop="false"
    @card:opened="registraEvento(bloque, 'click_bloque')"
  >
    <f7-card-content :padding="false">
      <div
        class=""
        :style="{
          height: '300px',
          background:
            'linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)),' +
            'url(' +
            bloque.imagen_url +
            ')',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
        }"
      >
        <f7-card-header text-color="black" class="display-block">
          {{ bloque.nombre_bloque }}
          <br />
          <small :style="{ opacity: 0.7 }">{{
            bloque.subtitulo
          }}</small>
        </f7-card-header>
        <f7-link
          card-close
          color="black"
          class="card-opened-fade-in"
          :style="{
            position: 'absolute',
            right: '15px',
            top: '15px',
          }"
          ><i class="fas fa-times"></i
        ></f7-link>
      </div>
      <div
        class="card-content-padding padding-horizontal"
        :id="bloque.name"
      >
        {{ convertStringIntoHtml(bloque) }}
      </div>
      <f7-button
        class="external margin-horizontal margin-vertical"
        fill
        @click="
          registraEvento(
            bloque,
            'click_interno',
            redirect,
            bloque.texto_boton_cta
          )
        "
        >{{ bloque.texto_boton_cta }}</f7-button
      >
    </f7-card-content>
  </f7-card>
</div> -->

<!-- Mapa -->
<!-- <div
  v-if="bloque.id_tipo_de_bloque === 'mapa' && !checkDeviceDesktop()"
  class="item-mapa link-item-list-preview"
  @click="
    registraEvento(
      bloque,
      'click_bloque',
      true,
      extractLatLong(bloque.mapa_url)
    )
  "
>
  <a style="width: 100%">
    <f7-row
      class="display-flex text-align-center align-items-center justify-content-center"
    >
      <f7-col width="100">
        <div class="item-title">{{ bloque.nombre_bloque }}</div>
      </f7-col>
    </f7-row>
  </a>
</div>
<div
  v-else-if="
    bloque.id_tipo_de_bloque === 'mapa' && checkDeviceDesktop()
  "
  class="item-mapa link-item-list-preview"
  @click="
    registraEvento(bloque, 'click_bloque', true, bloque.mapa_url)
  "
>
  <a style="width: 100%">
    <f7-row
      class="display-flex text-align-center align-items-center justify-content-center"
    >
      <f7-col width="100">
        <div class="item-title">{{ bloque.nombre_bloque }}</div>
      </f7-col>
    </f7-row>
  </a>
</div> -->
<!-- <f7-popup
  :id="bloque.name"
  v-if="bloque.id_tipo_de_bloque === 'imagen'"
  class="image-popup link-item-list-preview"
>
  <div
    style="height: -webkit-fill-available"
    :style="
      'background-image: url(' +
      '\'' +
      bloque.imagen_url +
      '\'' +
      '); background-size: cover;' +
      'background-position: center center;'
    "
  >
    <f7-block
      style="
        height: -webkit-fill-available;
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.25);
      "
      class="no-padding no-margin display-flex justify-content-center"
    >
      <span
        style="position: absolute; top: 10px; right: 10px"
        @click="closeImagePopup(bloque.name)"
      >
        <i class="fas fa-times" style="color: #ffffff"></i>
      </span>
      <img
        :src="bloque.imagen_url"
        alt=""
        style="width: 100%; width: 100%; object-fit: contain"
      />
      <f7-button
        style="position: absolute; bottom: 40px"
        fill
        class="external"
        @click="
          registraEvento(
            bloque,
            'click_interno',
            redirect,
            bloque.enlace_boton_cta
          )
        "
      >
        {{ bloque.texto_boton_cta }}</f7-button
      >
    </f7-block>
  </div>
</f7-popup> -->
<!-- Calificacion -->
<!-- <div v-if="link.id === 'calificacion'" class="item-calificacion">
  <a class="external" href="https://example.com" style="width: 100%">
    <f7-row class="text-align-center padding-vertical">
      <f7-col width="25">
        <i class="fas fa-star item-icon"></i>
      </f7-col>
      <f7-col width="75">
        <div class="item-title">Calificanos</div>
      </f7-col>
    </f7-row>

    <f7-row class="text-align-center padding-vertical">
      <f7-col> </f7-col>
    </f7-row>
  </a>
</div> -->
<!-- Instagram Feed -->
<!-- <div
  v-show="
    bloque.id_tipo_de_bloque === 'feed_instagram' && instagramLoader
  "
  style="width: 100%; text-align: center; padding: 15px"
  class="link-item-list-preview"
>
  <span>
    <i
      class="fas fa-circle-notch fa-spin"
      style="font-size: 30px"
    ></i>
    <br />
    <br />
    Cargando Instagram...</span
  >
</div>
<div
  v-show="
    bloque.id_tipo_de_bloque === 'feed_instagram' && !instagramLoader
  "
  class="item-feed-instagram"
  @click="registraEvento(bloque, 'click_bloque')"
>
  <f7-block
    class="margin-bottom-half no-padding-horizontal no-margin-top"
  >
    <f7-row id="instafeed"></f7-row>
  </f7-block>
</div> -->
