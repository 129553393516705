import utils from "./utils.js";
import store from "./store.js";
import { f7, f7ready, useStore } from "framework7-vue";

let updateColors = () => {
  const local = useStore("local").value;
  store.dispatch("setBackgroundColor", local.color_fondo);
  store.dispatch("setBackgroundColorHex", local.nombre_color_fondo);
  store.dispatch("setBackgroundColorCustom", local.color_fondo_personalizado);
  store.dispatch("setMainColor", local.color_principal);
  store.dispatch("setMainColorHex", local.nombre_color_principal);
  store.dispatch("setMainColorCustom", local.color_principal_personalizado);

  updateColorPrincipal(
    local.color_principal,
    local.nombre_color_principal,
    local.estilo_sitio
  );

  updateColorBackground(
    local.color_fondo,
    local.nombre_color_fondo,
    local.estilo_sitio
  );
};

let updateColorPrincipal = function (name, color, estilo) {
  // Buttons
  let rootCss = f7.$(":root")[0];

  if (estilo === "bloques") {
    rootCss.style.setProperty("--preview-btn-color", color);
  }

  rootCss.style.setProperty("--link-miqrositio-color", color);
  rootCss.style.setProperty("--item-titulo-de-seccion-color", color);
  rootCss.style.setProperty("--item-nombre-local-color", color);
  rootCss.style.setProperty("--uppy-dashboard-color", color);
  rootCss.style.setProperty("--item-sku-badge-color", color);
  rootCss.style.setProperty(
    "--miqrositio-input-outline-focused-border-color",
    color
  );
  rootCss.style.setProperty("--miqrositio-label-focused-text-color", color);

  if (estilo === "limpio") {
    // Botones, Badges & Uppy
    rootCss.style.setProperty("--preview-btn-background-color", color);
    rootCss.style.setProperty("--uppy-dashboard-background-color", color);
    rootCss.style.setProperty("--item-sku-badge-background-color", color);
    f7.$(".link-item-list-preview .item-title").css("color", color);
  }

  // URL Local
  setTimeout(() => {
    let urlLocal = f7.$("div.home-preview").find(".home-preview-url-local");
    for (let i = 0; i < urlLocal.length; i++) {
      f7.$(urlLocal[i]).css("color", color);
    }
  }, 0);
};

let updateColorBackground = async (name, color, estilo) => {
  if (name !== "") {
    let selectionCircleBackground = f7
      .$(`#color-selection-circle-fondo-${name}`)
      .find("path");

    selectionCircleBackground.css("fill", "#ffffff");
  }

  // Color de Fondo de Pagina
  f7.$("div.home-preview").css("background", color);
  f7.$("body").css("background", color);

  // :root
  let rootCss = f7.$(":root")[0];

  if (estilo === "limpio") {
    rootCss.style.setProperty("--preview-btn-color", color);
    f7.$(".link-item-list-preview ul").css("background", color);
  }

  if (estilo === "bloques") {
    // Botones, Badges & Uppy
    rootCss.style.setProperty("--preview-btn-background-color", color);
    rootCss.style.setProperty("--uppy-dashboard-background-color", color);
    rootCss.style.setProperty("--item-sku-badge-background-color", color);
    // Bloque
    rootCss.style.setProperty("--preview-item-bg-color", "#ffffff");
    rootCss.style.setProperty(
      "--preview-item-box-shadow",
      "0 0.5rem 1rem rgb(0 0 0 / 25%)"
    );
  } else if (estilo === "limpio") {
    // Bloque
    rootCss.style.setProperty("--preview-item-bg-color", color);
    rootCss.style.setProperty("--preview-item-box-shadow", "none");
    // Textos Invertidos
    let invertedColorTexts = await calculateTextColor(color);
    f7.$(".link-item-list-preview .item-text").css("color", invertedColorTexts);
    rootCss.style.setProperty("--uppy-dashboard-color", invertedColorTexts);
    f7.$(".link-item-list-preview .item-description").css(
      "color",
      invertedColorTexts
    );
  }
};

let calculateTextColor = async (hexColor) => {
  let threshold = 0.5;

  let [red, green, blue] = await (async () => {
    return new Promise((resolve) => {
      resolve(hexToRGB(hexColor));
    });
  })();

  let r = red * 0.2126;
  let g = green * 0.7152;
  let b = blue * 0.0722;
  let sum = r + g + b;
  let perceivedLightness = sum / 255;

  /* shows either white or black color depending on perceived darkness */
  let color = `hsl(0, 0%, ${
    (perceivedLightness - threshold) * (-10000000 * 100)
  }%)`;

  return color;
};

let hexToRGB = async (h) => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length == 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return [r, g, b];
};

export default {
  updateColors,
  updateColorPrincipal,
  updateColorBackground,
};
