<template>
  <f7-sheet
    backdrop
    style="
      height: -webkit-fill-available;
      overflow: auto;
      margin: 2.5%;
      width: 95%;
      border-radius: 10px;
      position: fixed;
    "
    :opened="openBuyProSheet"
    :close-by-backdrop-click="false"
  >
    <f7-block
      class="no-margin onboarding-page-block"
      style="
        background-image: url('../../static/images/miqrositio_background_gradient_violet.jpg');
        background-size: cover;
        background-repeat: no-repeat;
      "
    >
      <f7-row>
        <f7-col class="text-align-center">
          <!-- <h1 class="no-margin no-padding"></h1> -->
          <h1 class="no-margin no-padding">👑 Plan Pro</h1>
          <h2>Sacale el máximo partido a tu MiqroSitio</h2>
          <!-- <p class="padding">
            Sacale todo el provecho a MiqroSitio usando las funciones avanzadas:
          </p> -->
          <br />
          <!-- <br /> -->
          <span class="text-align-left">
            <p class="">
              ⭐ Utilizá los bloques avanzados (Producto, Formulario, WhatsApp y
              más).
            </p>
            <p class="">⭐ Convertí posts de Instagram en Productos.</p>
            <p class="">⭐ Vendé y cobrá con Mercado Pago.</p>
            <p class="">⭐ Aumentá el límite de bloques en tu sitio.</p>
            <p class="">
              ⭐ Estadísticas de visitas y clicks. Ranking de Bloques.
            </p>
            <p class="">⭐ Elimina nuestra marca de tu sitio.</p>
          </span>
        </f7-col>
      </f7-row>
      <f7-row
        class="
          padding-top
          text-align-center
          justify-content-center
          margin-top
          align-items-center
          flex-direction-column
        "
      >
        <f7-col style="width: 100%">
          <f7-button
            fill
            round
            @click="enableFreeTrialPro()"
            style="background-color: #ffc314; color: #1d223f"
            >SUSCRIBIRME POR $ 699,00.-/Mes</f7-button
          >
        </f7-col>
        <div class="padding-top-half" style="opacity: 0.65">
          Cancelalo cuando quieras. Sin Sorpresas.
        </div>
        <br />
        <!-- <br /> -->
        <div
          class="padding-top margin-top"
          style="opacity: 0.65; font-weight: bold"
        >
          <f7-link @click="closeSheetTryPro" style="opacity: 0.65; color: #212121;">MEJOR NO, QUIZAS DESPUÉS</f7-link>
        </div>
      </f7-row>
    </f7-block>
    <f7-page-content> </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import store from "../../js/store";
import utils from "../../js/utils";
import crudBloques from "../../js/crud-bloques";
import crudLocal from "../../js/crud-local";

export default {
  setup() {
    const openBuyProSheet = useStore("openBuyProSheet");

    return {
      openBuyProSheet,
    };
  },
  methods: {
    closeSheetTryPro() {
      const self = this;
      store.dispatch("setOpenBuyProSheet", false);
    },
    enableFreeTrialPro() {
      const self = this;

      utils.axiosCall(
        "post",
        "linqr",
        "suscripcion",
        "enable_free_trial",
        {},
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          if (r.data.message.success === true) {
            utils.toastConfig.text = r.data.message.message;
            utils.toastConfig.cssClass = "success-toast";
            utils.toastConfig.closeTimeout = 6000;
            f7.toast.create(utils.toastConfig).open();

            self.closeSheetTryPro();
            crudLocal.getLocal(self);
          }
        },
        function (e) {
          utils.toastConfig.text = e;
          utils.toastConfig.cssClass = "error-toast";
          f7.toast.create(utils.toastConfig).open();

          self.closeSheetTryPro();
          crudLocal.getLocal(self);
        }
      );
    },
  },
};
</script>