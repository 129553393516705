<template>
  <!-- REGISTRO -->
  <f7-login-screen
    id="my-signin-screen"
    :opened="props.signupScreenOpen === true"
  >
    <f7-view>
      <f7-page login-screen>
        <logoAuth />
        <div style="width:100%">
          <h2
            class="
              text-align-center
              margin-horizontal
              no-margin-vertical
              margin-bottom-half
            "
            style="color: #222222; font-size: 30px; font-weight: 400"
            >Creá una cuenta gratuita</h2
          >
          <p class="margin-horizontal text-align-center no-margin-top">No requiere tarjeta de crédito.</p>
          <f7-list
            no-hairlines
            no-hairlines-between
            form
            @submit="
              (e) => {
                signup(e);
              }
            "
          >
            <f7-list-input
              class="no-margin-horizontal"
              outline
              floating-label
              type="text"
              name="nombre_unico_local"
              placeholder="El nombre de tu sitio"
              v-model:value="uniquePageName"
              validate
              error-message-force
              @input="
                (e) => {
                  uniquePageNameMiddleware = e.currentTarget.value;
                }
              "
            >
              <template #content-start>
                <span class="url-local-form-signup">miqro.app/</span></template
              >
            </f7-list-input>
            <f7-list-input
              outline
              floating-label
              class="transform-lowercase"
              type="email"
              name="usuario_app"
              placeholder="Ingresa tu Email"
              v-model:value="usuarioSignup"
              validate
              error-message="Ingresa un email válido"
            ></f7-list-input>
            <f7-list-input
              outline
              floating-label
              :type="inputTypePasswordSignup"
              name="contrasenia"
              placeholder="Ingrese una contraseña"
              v-model:value="contraseniaSignup"
              validate
              pattern=".{8,}"
              error-message="Debe contener 8 o mas caracteres"
            >
              <template #content>
                <span
                  class="mostrar-contrasenia-icon"
                  v-show="inputTypePasswordSignup === 'password'"
                  @click="inputTypePasswordSignup = 'text'"
                  ><i class="fas fa-eye"></i
                ></span>
                <span
                  class="mostrar-contrasenia-icon"
                  v-show="inputTypePasswordSignup === 'text'"
                  @click="inputTypePasswordSignup = 'password'"
                  ><i class="fas fa-eye-slash"></i
                ></span>
              </template>
            </f7-list-input>
            <!-- <f7-list-input
            outline
            floating-label
            :type="inputTypePasswordSignupRepeat"
            name="repetirContrasenia"
            placeholder="Reingrese la contraseña"
            v-model:value="repetirContrasenia"
            required
            validate
            :pattern="contraseniaSignup"
          >
            <template #content>
              <span
                class="mostrar-contrasenia-icon"
                v-show="inputTypePasswordSignupRepeat === 'password'"
                @click="inputTypePasswordSignupRepeat = 'text'"
                ><i class="fas fa-eye"></i
              ></span>
              <span
                class="mostrar-contrasenia-icon"
                v-show="inputTypePasswordSignupRepeat === 'text'"
                @click="inputTypePasswordSignupRepeat = 'password'"
                ><i class="fas fa-eye-slash"></i
              ></span>
            </template>
          </f7-list-input> -->
            <f7-list-item class="margin-top padding-horizontal-half">
              <span style="font-size: 14px;"
                >Acepto los
                <a
                  href="https://get.miqro.app/wp-content/uploads/2022/01/Terminos-y-Condiciones-MiqroSitio.pdf"
                  class="external"
                  target="_blank"
                  >Terminos y Condiciones</a
                ></span
              >
              <f7-toggle
                color="green"
                name="acepta_tyc"
                v-model:checked="aceptaTyC"
                @change="aceptaTyC = !aceptaTyC"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item
              class="padding-horizontal-half"
              style="margin-top: -5px"
            >
              <span style="font-size: 14px;"
                >Mirá también nuestra
                <a
                  href="https://get.miqro.app/wp-content/uploads/2022/01/Politicas-de-Privacidad-MiqroSitio.docx.pdf"
                  class="external"
                  target="_blank"
                  >Política de Privacidad</a
                ></span
              >
            </f7-list-item>
            <li>
              <div
                class="
                  item-content item-input margin-vertical
                "
              >
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <f7-button
                      color="green"
                      type="submit"
                      fill
                      href="#"
                      style="font-weight: 600"
                      >Crear</f7-button
                    >
                  </div>
                </div>
              </div>
            </li>
          </f7-list>
        </div>

        <f7-list class="margin-bottom-half">
          <f7-block-footer class="no-margin-bottom">
            ¿Ya estas registrado?
            <f7-button
              @click="
                props.signupScreenOpen = false;
                props.loginScreenOpen = true;
              "
              href="#"
              style="font-weight: 600"
              >Ingresa con tu cuenta</f7-button
            ></f7-block-footer
          >
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</template>

<script>
import { f7, f7ready, useStore } from "framework7-vue";
import authentication from "../../js/authentication";
import utils from "../../js/utils";
import logoAuth from "./logo-auth";

export default {
  components: {
    logoAuth,
  },
  props: {
    props: Object,
  },
  data() {
    return {
      uniquePageNameMiddleware: "",
      usuarioSignup: "",
      contraseniaSignup: "",
      repetirContrasenia: "",
      inputTypePasswordLogin: "password",
      inputTypePasswordSignup: "password",
      inputTypePasswordSignupRepeat: "password",
      aceptaTyC: false,
    };
  },
  mounted() {
    const self = this;
    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("register") && urlParams.get("register") === "true") {
      self.props.signupScreenOpen = true;
      self.props.loginScreenOpen = false;
      self.uniquePageNameMiddleware = urlParams.get("user") || "";
    } else {
      self.props.signupScreenOpen = false;
      self.props.loginScreenOpen = true;
    }
  },
  computed: {
    uniquePageName: function () {
      let nombre_local_sanitized = this.uniquePageNameMiddleware
        .replace(/[`\s~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        .replace(/[ñ]/gi, "n")
        .replace(/[á]/gi, "a")
        .replace(/[é]/gi, "e")
        .replace(/[í]/gi, "i")
        .replace(/[ó]/gi, "o")
        .replace(/[ú]/gi, "u")
        .replace(/[ü]/gi, "u")
        .toLowerCase();

      return nombre_local_sanitized;
    },
    urlPage: function () {
      return `https://app.miqrositio.binar.io/${this.uniquePageName}`;
    },
  },
  methods: {
    signup(e) {
      e.preventDefault();
      const self = this;

      if (self.aceptaTyC === false) {
        utils.toastConfig.text =
          "Debe aceptar los términos y condiciones para continuar";
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
      } else {
        authentication.signup(self, e);
      }
    },
  },
};
</script>