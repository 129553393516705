<template>
  <f7-block v-if="local" class="no-margin margin-top no-margin-bottom padding-top block-contact">
        <f7-row class="justify-content-center">
          <!-- Web -->
          <f7-col
            v-if="local.sitio_web"
            width="20"
            class="text-align-center padding-vertical-half"
          >
            <a
              :href="local.sitio_web"
              class="external block-social-media-icon"
              :style="`color: ${mainColorHex} !important`"
            >
              <i class="fas fa-globe-americas"></i>
            </a>
          </f7-col>
          <!-- Corrreo Electrónico -->
          <f7-col
            v-if="local.email"
            width="20"
            class="text-align-center padding-vertical-half"
          >
            <a
              :href="'mailto:' + local.email"
              class="external block-social-media-icon"
              :style="`color: ${mainColorHex} !important`"
            >
              <i class="fas fa-envelope"></i>
            </a>
          </f7-col>
          <!-- Telefono -->
          <f7-col
            v-if="local.telefono_fijo"
            width="20"
            class="text-align-center padding-vertical-half"
          >
            <a
              :href="'tel:' + local.telefono_fijo"
              class="external block-social-media-icon"
              :style="`color: ${mainColorHex} !important`"
            >
              <i class="fas fa-phone"></i>
            </a>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="text-align-center padding-vertical-half" :style="`color: ${mainColorHex} !important`">
            <span v-if="local.direccion_1">{{ local.direccion_1 }}</span>
            <span v-if="local.direccion_2">&nbsp;{{ local.direccion_2 }}</span>
            <span v-if="local.localidad">&nbsp;| {{ local.localidad }}</span>
            <span v-if="local.codigo_postal"
              >&nbsp;({{ local.codigo_postal }})</span
            >
            <span v-if="local.provincia">&nbsp;| {{ local.provincia }}</span>
            <span v-if="local.pais">&nbsp;| {{ local.pais }}</span>
          </f7-col>
        </f7-row>
      </f7-block>
</template>

<script>
import { useStore } from "framework7-vue"

export default {
  setup() {
    const mainColorHex = useStore("mainColorHex");

    return {
      mainColorHex
    }
  },
  props: {
    local: Object,
    props: Object
  }
}
</script>