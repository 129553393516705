<template>
  <!-- Formulario -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'formulario'"
    class="item-formulario link-item-list-preview padding"
  >
    <f7-row class="text-align-center item-formulario-header">
      <f7-col width="100">
        <div class="item-title">{{ bloque.nombre_bloque }}</div>
      </f7-col>
    </f7-row>
    <f7-list
      no-hairlines
      no-hairlines-between
      class="margin-vertical-half"
      :class="bloque.name"
      :id="bloque.name"
      form
      @submit="
        (e) => {
          sendFormulario(e, bloque);
        }
      "
    >
      <f7-list-input
        outline
        v-show="bloque.form_nombre_apellido == 1"
        placeholder="Nombre y Apellido"
        name="form_nombre_apellido"
        id="form_nombre_apellido"
        type="text"
        autocomplete="off"
        validate
        required
      ></f7-list-input>
      <f7-list-input
        outline
        class="transform-lowercase"
        v-show="bloque.form_email == 1"
        placeholder="E-mail"
        name="form_email"
        id="form_email"
        type="email"
        autocomplete="off"
        validate
        required
        error-message="Ingresa un email válido"
      ></f7-list-input>
      <f7-list-input
        outline
        v-show="bloque.form_telefono == 1"
        placeholder="Teléfono"
        name="form_telefono"
        id="form_telefono"
        type="number"
        autocomplete="off"
        validate
        required
      ></f7-list-input>
      <f7-list-input
        outline
        v-show="bloque.form_consulta == 1"
        type="textarea"
        name="form_consulta"
        id="form_consulta"
        placeholder="Escribe tu consulta"
        autocomplete="off"
      ></f7-list-input>
      <li
        v-show="bloque.form_archivos == 1"
        class="no-hairlines no-hairlines-between"
      >
        <div class="item-content margin-bottom">
          <div class="item-inner">
            <!-- <div class="item-title item-label">Archivo</div> -->
            <span style="margin-bottom: 10px"></span>
            <div style="width: 100%">
              <div
                class="DashboardContainerHome"
                :class="'DashboardContainerHome_' + bloque.name"
              ></div>
            </div>
          </div>
        </div>
      </li>
      <!-- <li>
        <div class="item-content item-input margin-vertical">
          <div class="item-inner">
            <div class="item-input-wrap"> -->
      <f7-button
        fill
        href="#"
        class="preview-disable-btn"
        v-if="props.uploadingFiles"
      >
        <span style="margin-top: 0px">{{
          bloque.form_texto_boton
        }}</span></f7-button
      >
      <f7-button
        type="submit"
        fill
        href="#"
        class="preview-btn"
        v-if="!props.uploadingFiles"
      >
        <span style="margin-top: 0px">{{
          bloque.form_texto_boton
        }}</span></f7-button
      >
      <!-- </div>
          </div>
        </div>
      </li> -->
    </f7-list>
  </div>
</template>

<script>
import { f7 } from "framework7-vue";
import crudBloques from "../../../js/crud-bloques.js";

export default {
  props: {
    props: Object,
    bloque: Object,
    f7router: Object
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
    async sendFormulario(e, bloque) {
      const self = this;
      e.preventDefault();
      f7.dialog.preloader("Enviando Consulta");

      var el = f7.$(`#${CSS.escape(bloque.name)}`);
      var formData = f7.form.convertToData(el);

      let x = await (async function () {
        if (self.props.formArchivos[bloque.name]) {
          for (
            let i = 0;
            i < Object.keys(self.props.formArchivos[bloque.name]).length;
            i++
          ) {
            formData[`form_archivo_url_${i}`] =
              self.props.formArchivos[bloque.name][`archivo_url_${i}`];
          }
        }
      })();

      // setTimeout(function () {
      var formDataJson = JSON.stringify(formData);
      self.registraEvento(bloque, "click_bloque");
      crudBloques.envioFormulario(self, formDataJson, bloque.name);
      el.forEach(element => {
        el[0].reset();
      });
      // document.getElementById(el).reset();
      // }, 1000);
    },
  },
};
</script>
