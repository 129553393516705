<template>
  <f7-sheet
    :swipe-to-close="false"
    :close-by-backdrop-click="false"
    :close-by-outside-click="false"
    :close-on-escape="false"
    style="height: -webkit-fill-available; max-height: 60%; overflow: auto"
    class="customize-page-sheet"
    @sheet:open="onSheetOpen"
  >
    <f7-page-content>
      <f7-navbar class="no-shadow" style="position: fixed">
        <template #title>Personalizar</template>
        <template #right>
          <span class="padding-right-half">
            <f7-button
              v-if="!minimizedQrCustomizeSheet"
              class="btn-links"
              @click="updateColorPalettes"
            >
              <i class="fas fa-save"></i>
            </f7-button>
          </span>
          <span v-if="minimizedQrCustomizeSheet" class="padding-right-half">
            <f7-button class="btn-links btn-links-disabled">
              <i class="fas fa-save"></i>
            </f7-button>
          </span>
          <span class="padding-right-half">
            <f7-button
              v-if="!minimizedQrCustomizeSheet"
              class="btn-links"
              @click="minimizeQrCustomizeSheet"
              ><i class="fas fa-window-minimize"></i
            ></f7-button>
            <f7-button
              v-if="minimizedQrCustomizeSheet"
              class="btn-links"
              @click="maximizeQrCustomizeSheet"
              ><i class="fas fa-window-maximize"></i
            ></f7-button>
          </span>
          <span v-if="!minimizedQrCustomizeSheet" class="padding-right-half">
            <f7-button
              class="btn-links"
              back
              data-animate="false"
              @click="closeSheet"
            >
              <i class="fas fa-times"></i>
            </f7-button>
          </span>
          <span v-if="minimizedQrCustomizeSheet" class="padding-right-half">
            <f7-button class="btn-links btn-links-disabled">
              <i class="fas fa-times"></i>
            </f7-button>
          </span>
        </template>
      </f7-navbar>

      <!-- <f7-row
        style="
          position: fixed;
          z-index: 10;
          background: #fff;
          width: 100%;
          box-shadow: 0 0rem 2rem rgb(213 221 236 / 40%);
          padding-top: 25px !important;
        "
      >
        <f7-col width="75">
          <f7-block-title class="no-margin-top" large
            >Personalizar</f7-block-title
          >
        </f7-col>
        <f7-col width="15" class="display-flex justify-content-flex-start"
          ><f7-button
            v-if="true"
            class="btn-links btn-links-green"
            @click="updateColorPalettes"
            sheet-close
            ><i class="fas fa-save"></i
          ></f7-button>
        </f7-col>
        <f7-col width="15" class="display-flex justify-content-flex-start"
          ><f7-button
            class="btn-links btn-links-red"
            href="#"
            sheet-close
            @click="onSheetClose"
            ><i class="fas fa-times"></i></f7-button
        ></f7-col>
        <f7-col width="5"></f7-col>
      </f7-row> -->

      <!-- Skeleton -->
      <f7-block v-if="loading" class="no-padding-horizontal">
        <!-- <f7-button fill class="UppyModalOpenerBtn">Imagen</f7-button> -->
        <!-- <dashboard :uppy="uppy" /> -->
        <span style="height: 75px; width: 100%; display: block"></span>
        <f7-list
          no-hairlines
          no-hairlines-between
          class="margin-vertical-half skeleton-text skeleton-effect-fade"
          v-for="n in skeletonRows"
          :key="n"
        >
          <f7-list-item class="skeleton-text">
            TestTestTestTestTestTestTestTestTestTest
            TestTestTestTestTestTestTestTestTestTest
          </f7-list-item>
        </f7-list>
      </f7-block>
      <!-- / Skeleton -->

      <f7-block v-if="!loading" class="no-padding-horizontal">
        <!-- <f7-button fill class="UppyModalOpenerBtn">Imagen</f7-button> -->
        <!-- <dashboard :uppy="uppy" /> -->
        <f7-list
          no-hairlines
          no-hairlines-between
          class="margin-vertical-half virtual-list-links"
        >
          <!-- Margin List -->
          <f7-list-item>
            <span style="height: 30px"></span>
          </f7-list-item>

          <!-- Color de Fondo -->
          <!-- <f7-list-item accordion-item>
            <template #title>
              <span>
                <i class="fas fa-eye-dropper"></i>&nbsp;&nbsp;
                <span>Color de Fondo</span>
              </span>
            </template>
            <f7-accordion-content> -->
          <!-- Predefined Colors -->
          <li>
            <f7-row class="margin-half">
              <f7-col>
                <f7-list-input
                  label="Estilo del Sitio"
                  class="no-padding"
                  type="select"
                  outline
                  :value="siteStyle"
                  @change="
                    (e) => {
                      changeSiteStyle(e);
                    }
                  "
                >
                  <option :selected="siteStyle === 'bloques'" value="bloques">
                    Bloques
                  </option>
                  <option :selected="siteStyle === 'limpio'" value="limpio">
                    Limpio
                  </option>
                </f7-list-input>
              </f7-col>
            </f7-row>
          </li>
          <li class="list-item-divider-custom no-margin-top">
            <div class="item-content">
              <div class="item-inner">
                <span>
                  <i class="fas fa-eye-dropper"></i>&nbsp;&nbsp;
                  <span>Color De Fondo</span>
                </span>
              </div>
            </div>
          </li>
          <li class="padding-horizontal-half">
            <f7-row class="text-align-center justify-content-center">
              <f7-col
                v-for="color in predefinedBackgroundColors"
                :key="color.name"
                width="20"
                class="display-flex justify-content-center margin-vertical-half"
                @click="
                  changeColorPredefinedBackground(color.name, color.color)
                "
              >
                <div
                  class="
                    color-selection-circle-principal
                    display-flex
                    justify-content-center
                    flex-direction-column
                    align-items-center
                  "
                  v-bind:style="{
                    background: color.color,
                  }"
                  :data-background-color="color.color"
                >
                  <span
                    v-show="local.color_fondo == color.name"
                    style="color: #ffffff; font-size: 30px; margin-top: 5px"
                    >✓</span
                  >
                  <!-- <span
                      v-show="local.color_principal != color.name"
                      :style="
                        'color: ' +
                        transparent +
                        '; ' +
                        ' font-size: 30px; margin-top: 5px;'
                      "
                      >✓</span> -->
                </div>
              </f7-col>
              <!-- <f7-col
                  width="25"
                  class="display-flex justify-content-center margin-vertical-half"
                >
                  <div
                    class="color-selection-circle-principal-custom display-flex justify-content-center flex-direction-column align-items-center"
                    @click="selectCustomColorPrincipal()"
                  >
                    <i class="fas fa-eye-dropper"></i>
                  </div>
                </f7-col> -->
            </f7-row>
            <!-- Color Personalizado -->
            <f7-row class="margin-half">
              <f7-col>
                <div class="padding-left-half item-title item-label">
                  Color Personalizado
                </div>
                <f7-input
                  type="colorpicker"
                  outline
                  placeholder="Color"
                  readonly
                  :value="
                    local.nombre_color_fondo &&
                    local.nombre_color_fondo.startsWith('#')
                      ? { hex: local.nombre_color_fondo }
                      : {}
                  "
                  :color-picker-params="{
                    modules: ['wheel', 'hex'],
                    hexLabel: true,
                    hexValueEditable: true,
                    groupedModules: true,
                  }"
                  @change="
                    (e) =>
                      changeColorPredefinedBackground('', e.currentTarget.value)
                  "
                ></f7-input>
              </f7-col>
            </f7-row>
          </li>

          <!-- Custom Colors -->
          <!-- <f7-list-input
                class="margin-horizontal-half padding-horizontal"
                v-show="customColorBackgroundSelected"
                type="colorpicker"
                placeholder="Color"
                label="Elija un color personalizado"
                readonly
                id="color-fondo-custom-input"
                name="color-fondo-custom"
                style="display: none"
                :value="{ hex: customColorBackground }"
                :color-picker-params="{
                  modules: [
                    'initial-current-colors',
                    'sb-spectrum',
                    'hue-slider',
                    'hex',
                  ],
                  hexLabel: true,
                  hexValueEditable: true,
                  groupedModules: true,
                }"
                @change="changeCustomColorBackground"
              >
                <template #media>
                  <div
                    class="color-selection-circle-fondo"
                    v-bind:style="{
                      background: customColorBackground,
                    }"
                  >
                    <div
                      class="color-selection-circle-fondo display-flex justify-content-center flex-direction-column align-items-center"
                    >
                      <i
                        class="fas fa-check"
                        style="color: #ffffff; font-size: 14px"
                      ></i>
                    </div>
                  </div>
                </template>
              </f7-list-input> -->
          <!-- </f7-accordion-content>
          </f7-list-item> -->

          <!-- Color Principal -->
          <!-- <f7-list-item accordion-item class="accordion-item-opened">
            <template #title>
              <span>
                <i class="fas fa-eye-dropper"></i>&nbsp;&nbsp;
                <span>Color Principal</span>
              </span>
            </template>
            <f7-accordion-content> -->
          <!-- Predefined Colors -->
          <li class="list-item-divider-custom">
            <div class="item-content">
              <div class="item-inner">
                <span>
                  <i class="fas fa-eye-dropper"></i>&nbsp;&nbsp;
                  <span>Color de Textos e Iconos</span>
                </span>
              </div>
            </div>
          </li>
          <li class="padding-horizontal-half">
            <f7-row class="text-align-center justify-content-center">
              <f7-col
                v-for="color in predefinedColors"
                :key="color.name"
                width="20"
                class="display-flex justify-content-center margin-vertical-half"
                @click="changeColorPredefinedPrincipal(color.name, color.color)"
              >
                <div
                  class="
                    color-selection-circle-principal
                    display-flex
                    justify-content-center
                    flex-direction-column
                    align-items-center
                  "
                  v-bind:style="{
                    background: color.color,
                  }"
                  :data-background-color="color.color"
                >
                  <span
                    v-show="local.color_principal == color.name"
                    style="color: #ffffff; font-size: 30px; margin-top: 5px"
                    >✓</span
                  >
                  <span
                    v-show="local.color_principal != color.name"
                    :style="
                      'color: ' +
                      color.color +
                      '; ' +
                      ' font-size: 30px; margin-top: 5px;'
                    "
                    >✓</span
                  >
                </div>
              </f7-col>
              <!-- <f7-col
                  width="25"
                  class="display-flex justify-content-center margin-vertical-half"
                >
                  <div
                    class="color-selection-circle-principal-custom display-flex justify-content-center flex-direction-column align-items-center"
                    @click="selectCustomColorPrincipal()"
                  >
                    <i class="fas fa-eye-dropper"></i>
                  </div>
                </f7-col> -->
            </f7-row>

            <f7-row class="margin-half">
              <f7-col>
                <div class="padding-left-half item-title item-label">
                  Color Personalizado
                </div>
                <f7-input
                  type="colorpicker"
                  outline
                  placeholder="Color"
                  readonly
                  :value="
                    local.nombre_color_principal &&
                    local.nombre_color_principal.startsWith('#')
                      ? { hex: local.nombre_color_principal }
                      : {}
                  "
                  :color-picker-params="{
                    modules: ['wheel', 'hex'],
                    hexLabel: true,
                    hexValueEditable: true,
                    groupedModules: true,
                  }"
                  @change="
                    (e) =>
                      changeColorPredefinedPrincipal('', e.currentTarget.value)
                  "
                ></f7-input>
              </f7-col>
            </f7-row>
          </li>

          <!-- Custom Colors -->
          <!-- <f7-list-input
            class="margin-horizontal-half padding-horizontal"
            type="colorpicker"
            placeholder="Color"
            label="Elija un color personalizado"
            readonly
            id="color-principal-custom-input"
            name="color-principal-custom"
            style="display: none"
            :value="{
              hex: local ? local.nombre_color_principal : '#000000',
            }"
            :color-picker-params="{
              modules: [
                'initial-current-colors',
                'sb-spectrum',
                'hue-slider',
                'hex',
              ],
              hexLabel: true,
              hexValueEditable: true,
              groupedModules: true,
            }"
          >
            <template #media>
              <div
                class="color-selection-circle-principal"
                v-bind:style="{
                  background: customColorPrincipal,
                }"
              >
                <div
                  class="
                    color-selection-circle-principal
                    display-flex
                    justify-content-center
                    flex-direction-column
                    align-items-center
                  "
                >
                  <i
                    class="fas fa-check"
                    style="color: #ffffff; font-size: 14px"
                  ></i>
                </div>
              </div>
            </template>
          </f7-list-input> -->
          <!-- </f7-accordion-content>
          </f7-list-item> -->

          <!-- <f7-list-item accordion-item>
            <template #title>
              <span>
                <i class="fas fa-eye-dropper"></i>&nbsp;&nbsp;
                <span>Imagen de Fondo</span>
              </span>
            </template>
            <f7-accordion-content> </f7-accordion-content>
          </f7-list-item> -->
        </f7-list>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
import store from "../js/store";
import utils from "../js/utils";
import theming from "../js/theming";
import crudLocal from "../js/crud-local";

export default {
  props: {
    f7router: Object,
    f7route: Object,
  },
  data() {
    return {
      color: "#222222",
      loading: false,
      skeletonRows: 1,
      local: undefined,
      backgroundColor: {
        color: "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
      },
      predefinedBackgroundColors: [],
      predefinedColors: [],
      customColorPrincipalSelected: false,
      currentColorPrincipal: {},
      customColorPrincipal: "",
      colorPrincipal: "",
      colorPrincipalName: "",
      customColorBackgroundSelected: false,
      customColorBackground: "",
      currentColorBackground: {},
      colorBackground: "",
      minimizedQrCustomizeSheet: false,
      siteStyle: "",
    };
  },
  setup() {
    const local = useStore("local");

    return {
      local,
    };
  },
  mounted() {
    const self = this;
    // if (self.local.color_principal_personalizado === 1) {
    //   self.customColorPrincipalSelected = true;
    // }

    self.siteStyle = self.local.estilo_sitio;

    self.currentColorPrincipal = {
      name: self.local.color_principal,
      color: self.local.nombre_color_principal,
    };
    self.currentColorBackground = {
      name: self.local.color_fondo,
      color: self.local.nombre_color_fondo,
    };
  },
  methods: {
    onSheetOpen() {
      const self = this;

      self.skeletonRows = Math.floor(
        (window.visualViewport.height * 0.9 - (106 + 25)) / 64
      );

      self.getColorPalettes();
    },
    getColorPalettes() {
      const self = this;

      utils.axiosCall(
        "post",
        "linqr",
        "paleta_de_color",
        "get_paleta_de_colores",
        {},
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          let coloresPrincipales = r.data.message.data.colores_principales;
          let coloresDeFondo = r.data.message.data.colores_de_fondo;

          for (let index = 0; index < coloresPrincipales.length; index++) {
            self.predefinedColors.push(coloresPrincipales[index]);
          }

          for (let index = 0; index < coloresDeFondo.length; index++) {
            self.predefinedBackgroundColors.push(coloresDeFondo[index]);
          }

          self.siteStyle = self.local.estilo_sitio || "bloques";

          self.loading = false;
        },
        function (e) {}
      );
    },
    updateColorPalettes() {
      const self = this;

      utils.axiosCall(
        "post",
        "linqr",
        "paleta_de_color",
        "update_paleta_de_colores",
        {
          estilo_sitio: self.local.estilo_sitio,
          color_principal: self.local.color_principal,
          nombre_color_principal: self.local.nombre_color_principal,
          color_principal_personalizado:
            self.local.nombre_color_principal_personalizado,
          color_fondo: self.local.color_fondo,
          nombre_color_fondo: self.local.nombre_color_fondo,
          color_fondo_personalizado:
            self.local.nombre_color_fondo_personalizado,
        },
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          utils.toastConfig.text = r.data.message.message;
          utils.toastConfig.cssClass = "success-toast";
          f7.toast.create(utils.toastConfig).open();
          // f7.sheet.close();
          self.f7router.navigate("/admin", {
            reloadCurrent: true,
            // ignoreCache: true,
          });

          self.loading = false;
        },
        function (e) {}
      );
    },
    changeColorPredefinedPrincipal(name, color) {
      const self = this;
      self.customColorPrincipalSelected = false;
      theming.updateColorPrincipal(
        name,
        color,
        self.local.estilo_sitio || "bloques"
      );

      self.local.color_principal = name;
      self.local.nombre_color_principal = color;

      // store.dispatch("setLocal", self.local);
      store.dispatch("setMainColor", name);
      store.dispatch("setMainColorHex", color);
    },
    // selectCustomColorPrincipal() {
    //   const self = this;
    //   self.customColorPrincipalSelected = true;
    //   theming.updateColorPrincipal(
    //     self,
    //     "custom",
    //     self.local.nombre_color_principal
    //   );

    //   self.local.color_principal_personalizado = 1;
    //   self.local.color_principal = "";
    //   store.dispatch("setLocal", self.local);
    // },
    // changeCustomColorPrincipal(e) {
    //   const self = this;
    //   theming.updateColorPrincipal(self, "custom", e.currentTarget.value);

    //   self.local.color_principal_personalizado = 1;
    //   self.local.color_principal = "";
    //   self.local.nombre_color_principal = e.currentTarget.value;
    //   store.dispatch("setLocal", self.local);
    // },
    changeColorPredefinedBackground(name, color) {
      const self = this;
      self.customColorBackgroundSelected = false;
      theming.updateColorBackground(
        name,
        color,
        self.local.estilo_sitio || "bloques"
      );

      self.local.color_fondo = name;
      self.local.nombre_color_fondo = color;

      // store.dispatch("setLocal", self.local);
      store.dispatch("setBackgroundColor", name);
      store.dispatch("setBackgroundColorHex", color);
    },
    changeSiteStyle(e) {
      const self = this;
      // debugger;
      self.siteStyle = e.currentTarget.value;
      self.local.estilo_sitio = e.currentTarget.value;
      store.dispatch("setSiteStyle", e.currentTarget.value);

      self.changeColorPredefinedPrincipal(
        self.local.color_principal,
        self.local.nombre_color_principal
      );
      self.changeColorPredefinedBackground(
        self.local.color_fondo,
        self.local.nombre_color_fondo
      );
    },
    // changeCustomColorBackground(e) {
    //   const self = this;
    //   const backgroundColor = useStore("backgroundColor");

    //   if (backgroundColor !== e.currentTarget.value) {
    //     self.customColorBackground = e.currentTarget.value;
    //     self.colorBackground = e.currentTarget.value;

    //     // Selection Circle
    //     var allSelectionCircle = f7.$(".color-selection-circle-fondo");

    //     for (let i = 0; i < allSelectionCircle.length; i++) {
    //       f7.$(allSelectionCircle[i]).find("path").css("fill", "transparent");
    //     }
    //     theming.updateColorBackground(self, e.currentTarget.value);

    //     store.dispatch("setBackgroundColor", e.currentTarget.value);
    //     store.dispatch("setBackgroundColorCustom", 1);
    //   }
    // },
    minimizeQrCustomizeSheet() {
      const self = this;
      self.minimizedQrCustomizeSheet = true;

      let el = document.querySelector(".customize-page-sheet");
      el.style.transform = "translate3d(0,85%,0)";
    },
    maximizeQrCustomizeSheet() {
      const self = this;
      self.minimizedQrCustomizeSheet = false;

      let el = document.querySelector(".customize-page-sheet");
      el.style.removeProperty("transform");
    },
    closeSheet() {
      const self = this;
      crudLocal.getLocal(self);
      // self.changeColorPredefinedPrincipal(
      //   self.currentColorPrincipal.name,
      //   self.currentColorPrincipal.color
      // );
      // self.changeColorPredefinedBackground(
      //   self.currentColorBackground.name,
      //   self.currentColorBackground.color
      // );

      // f7.sheet.close();
      // f7.sheet.destroy();
      // setTimeout(() => {
      //   let el = document.querySelector(".customize-page-sheet").remove();
      // }, 500);
    },
    // onSheetClose() {
    //   const self = this;

    //   self.changeColorPredefinedPrincipal(
    //     self.currentColorPrincipal.name,
    //     self.currentColorPrincipal.color
    //   );
    //   self.changeColorPredefinedBackground(
    //     self.currentColorBackground.name,
    //     self.currentColorBackground.color
    //   );
    // },
  },
};
</script>
