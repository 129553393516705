<template>
  <f7-actions
    :opened="props.actionsDownloadQR"
    @actions:closed="props.actionsDownloadQR = false"
  >
    <f7-actions-group>
      <f7-actions-label>Descargar</f7-actions-label>
      <f7-actions-button>
        <a
          class="external"
          id="download-qrcode-template"
          :download="local.nombre_unico_local + ' Plantilla QR.png'"
          ><i class="fas fa-download"></i>&nbsp;&nbsp;Descargar Plantilla</a
        ></f7-actions-button
      >
      <f7-actions-button>
        <a
          class="external"
          id="download-qrcode"
          :download="local.nombre_unico_local + ' Código QR.png'"
          ><i class="fas fa-download"></i>&nbsp;&nbsp;Descargar Solo Código
          QR</a
        ></f7-actions-button
      >
      <!-- <f7-actions-button
              ><i class="fas fa-envelope"></i>&nbsp;&nbsp;Enviar por
              Email</f7-actions-button
            > -->
    </f7-actions-group>
  </f7-actions>
</template>

<script>
export default {
  props: {
    props: Object,
    local: Object
  },
};
</script>