<template>
  <f7-actions
    :opened="props.actionAyudaVideoOpen"
    @actions:closed="props.actionAyudaVideoOpen = false"
    style="background: #ffffff; height: -webkit-fill-available;"
  >
    <f7-actions-group
      style="height: 100%"
      class="display-flex flex-direction-column"
    >
      <f7-actions-label
        class="justify-content-flex-end"
        style="z-index: 1; position: absolute; background: transparent"
      >
        <f7-row style="width: 100%">
          <f7-col class="justify-content-flex-end display-flex"
            ><a @click="props.actionAyudaVideoOpen = false"
              ><i
                class="fas fa-times"
                style="
                  color: #fff;
                  border-radius: 100%;
                  background: var(--f7-color-red);
                  padding: 5px 8px;
                  font-size: 22px;
                "
              ></i></a
          ></f7-col>
        </f7-row>
      </f7-actions-label>
      <f7-block
        class="no-margin no-padding"
        style="width: 100%; z-index: 0; height: 100%"
      >
        <f7-row>
          <f7-col
            class="display-flex justify-content-center"
            style="height: 100%"
          >
            <video
              :src="srcHelpVideo"
              style="
                width: 100%;
                display: block;
                position: absolute;
                object-fit: contain;
                border-radius: 10 px;
                height: 100%;
              "
              controls
            ></video>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-actions-group>
  </f7-actions>
</template>

<script>
export default {
  props: {
    props: Object,
    srcHelpVideo: String,
  },
};
</script>