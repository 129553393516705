<template>
  <!-- Imagen -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'galeria_imagenes'"
    class="item-imagen link-item-list-preview-no-bgnd item-titulo-de-seccion"
    @click="registraEvento(bloque, 'click_bloque')"
  >
    <!-- TITULO -->
    <f7-row class="text-align-center item-formulario-header">
      <f7-col width="100">
        <div class="item-title">{{ bloque.nombre_bloque }}</div>
      </f7-col>
    </f7-row>
    <!-- GALERIA ORDENADA -->
    <f7-row class="justify-content-space-around" v-if="bloque.tipo_galeria_imagenes === 'ordenada'">
      <f7-col
        v-for="imagen in bloque.galeria_imagenes"
        :key="imagen"
        :width="columnGallery"
      >
        <span class="grid-image-container">
          <img class="grid-image" :src="imagen" alt="" /> </span
      ></f7-col>
    </f7-row>

    <!-- GALERIA MASONRY -->
    <div class="masonry"  v-if="bloque.tipo_galeria_imagenes === 'masonry'">
      <div v-for="imagen in bloque.galeria_imagenes"
        :key="imagen" class="grid">
        <img :src="imagen" />
        <div class="grid__body">
          <div class="relative">
            <a class="grid__link" target="_blank" href="/"></a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";

export default {
  props: {
    bloque: Object,
  },
  data() {
    return {
      columnGallery: 50,
    }
  },
  mounted() {
    const self = this;
    if (window.innerWidth >= 568) {
      self.columnGallery = 33
    } else {
      self.columnGallery = 50
    }
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
  },
};
</script>
