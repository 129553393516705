<template>
  <f7-sheet
    :opened="props.sheetChangePasswordOpen"
    backdrop
    style="height: auto; max-height: 60%; overflow: auto"
    class="customize-page-sheet qr-customize-sheet"
  >
    <f7-page>
      <f7-page-content>
        <f7-navbar class="no-shadow" style="position: fixed">
          <template #title>Cambiar Contraseña</template>
          <template #right>
            <span class="padding-right-half">
              <f7-button
                class="btn-links btn-links"
                @click="props.sheetChangePasswordOpen = false"
              >
                <i class="fas fa-times"></i>
              </f7-button>
            </span>
          </template>
        </f7-navbar>
        <f7-block class="no-padding-horizontal no-margin-bottom">
          <f7-row>
            <f7-col>
              <f7-list
                form
                no-hairlines
                no-hairlines-between
                @submit="
                  (e) => {
                    changePassword(e);
                  }
                "
              >
                <f7-list-input
                  outline
                  floating-label
                  :type="inputTypeCurrentPassword"
                  name="contrasenia-actual"
                  placeholder="Contraseña Actual"
                  v-model:value="contraseniaActual"
                  validate
                  pattern=".{8,}"
                  error-message="Debe contener 8 o mas caracteres"
                >
                  <template #content>
                    <span
                      class="mostrar-contrasenia-icon"
                      v-show="inputTypeCurrentPassword === 'password'"
                      @click="inputTypeCurrentPassword = 'text'"
                      ><i class="fas fa-eye"></i
                    ></span>
                    <span
                      class="mostrar-contrasenia-icon"
                      v-show="inputTypeCurrentPassword === 'text'"
                      @click="inputTypeCurrentPassword = 'password'"
                      ><i class="fas fa-eye-slash"></i
                    ></span>
                  </template>
                </f7-list-input>
                <f7-list-input
                  outline
                  floating-label
                  :type="inputTypeNewPassword"
                  name="contrasenia"
                  placeholder="Contraseña"
                  v-model:value="contraseniaNew"
                  validate
                  pattern=".{8,}"
                  error-message="Debe contener 8 o mas caracteres"
                >
                  <template #content>
                    <span
                      class="mostrar-contrasenia-icon"
                      v-show="inputTypeNewPassword === 'password'"
                      @click="inputTypeNewPassword = 'text'"
                      ><i class="fas fa-eye"></i
                    ></span>
                    <span
                      class="mostrar-contrasenia-icon"
                      v-show="inputTypeNewPassword === 'text'"
                      @click="inputTypeNewPassword = 'password'"
                      ><i class="fas fa-eye-slash"></i
                    ></span>
                  </template>
                </f7-list-input>
                <f7-list-input
                  outline
                  floating-label
                  :type="inputTypeRepeatPassword"
                  name="repetirContrasenia"
                  placeholder="Reingrese la contraseña"
                  v-model:value="repetirContrasenia"
                  validate
                  :pattern="contraseniaNew"
                >
                  <template #content>
                    <span
                      class="mostrar-contrasenia-icon"
                      v-show="inputTypeRepeatPassword === 'password'"
                      @click="inputTypeRepeatPassword = 'text'"
                      ><i class="fas fa-eye"></i
                    ></span>
                    <span
                      class="mostrar-contrasenia-icon"
                      v-show="inputTypeRepeatPassword === 'text'"
                      @click="inputTypeRepeatPassword = 'password'"
                      ><i class="fas fa-eye-slash"></i
                    ></span> </template
                ></f7-list-input>
                <li>
                  <div
                    class="
                      item-content item-input margin-vertical
                    "
                  >
                    <div class="item-inner">
                      <div class="item-input-wrap">
                        <f7-button color="green" type="submit" fill href="#"
                          >CAMBIAR CONTRASEÑA</f7-button
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </f7-list>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-page-content>
    </f7-page>
  </f7-sheet>
</template>

<script>
import authentication from "../../js/authentication";

export default {
  props: {
    props: Object,
  },
  data() {
    return {
      inputTypeCurrentPassword: "password",
      inputTypeNewPassword: "password",
      inputTypeRepeatPassword: "password",
      contraseniaActual: "",
      contraseniaNew: "",
      repetirContrasenia: "",
    };
  },
  methods: {
    changePassword(e) {
      e.preventDefault();
      const self = this;
      authentication.changePasswordInsideApp(self, e);
    },
  },
};
</script>