<template>
  <f7-actions
    :opened="props.actionsShareQR"
    @actions:closed="props.actionsShareQR = false"
  >
    <f7-actions-group>
      <f7-actions-label>Compartir</f7-actions-label>
      <f7-actions-button>
        <a
          @click="
            shareQr(
              props.templateQRFileURL,
              'Plantilla QR',
              'Plantilla de Código QR lista para descargar 👉 '
            )
          "
          ><i class="fas fa-share-alt"></i>&nbsp;&nbsp;Compartir Plantilla</a
        ></f7-actions-button
      >
      <f7-actions-button>
        <a
          @click="
            shareQr(
              props.qRFileURL,
              'Código QR',
              'Código QR listo para descargar 👉 '
            )
          "
          ><i class="fas fa-share-alt"></i>&nbsp;&nbsp;Compartir Solo Código
          QR</a
        ></f7-actions-button
      >
      <!-- <f7-actions-button
              ><i class="fas fa-envelope"></i>&nbsp;&nbsp;Enviar por
              Email</f7-actions-button
            > -->
    </f7-actions-group>
  </f7-actions>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import utils from "../../js/utils"

export default {
  props: {
    props: Object,
    local: Object,
  },
  methods: {
    shareQr(fileURL, shareTitle, shareText) {
      const self = this;
          var file = new File([fileURL], `${shareTitle}.png`, { type: "image/jpeg" });
          var filesArray = [file];

          if (navigator.canShare) {
            navigator
              .share({
                // url: fileURL,
                files: filesArray,
                title: shareTitle,
                text: shareText,
              })
              .then(() => console.log("Share was successful."))
              .catch((error) => {
                // utils.toastConfig.text = error;
                // utils.toastConfig.cssClass = "error-toast no-printme";
                // f7.toast.create(utils.toastConfig).open();
              });
          } else {
            utils.toastConfig.text =
              "Su dispositivo no permite compartir archivos. Intente con la opción de descarga.";
            utils.toastConfig.cssClass = "error-toast no-printme";
            f7.toast.create(utils.toastConfig).open();
          }
        // });
    },
  },
};
</script>