import { createStore } from "framework7/lite";

const store = createStore({
  state: {
    recoverPasswordToken: undefined,
    recoverPasswordEmail: undefined,
    openOnboardingSheet: false,
    openMainVideoTutorial: false,
    openTryProSheet: false,
    openBuyProSheet: false,
    openEndFreeTrialSheet: false,
    usuarioEmail: "",
    // loading: false,
    local: {},
    suscripcion: "",
    linksType: [],
    linkType: [],
    bloquesLocal: [],
    baseBlock: {},
    qrsLocal: [],
    bloquesLocalPublic: [],
    frappeHost: "",
    customQR: "",
    customQRStyle: "dot",
    customQRText: "",
    customQRSubText: "",
    customQRImage: "",
    customQRSize: "qr-display-grande",
    customQRMainColor: "#222222",
    customQRDotColor: "",
    customQRCornerColor: "",
    customQRDotCornerColor: "",
    userLogged: localStorage.getItem("token"),
    emojiPicker: {},
    siteStyle: "bloques",
    // backgroundColor: undefined,
    // backgroundColorCustom: undefined,
    // mainColor: undefined,
    // mainColorCustom: undefined,
    // mainBackgroundColor: {
    //   "background-color": "#ffffff",
    // },
    instagramPosts: [],
    emojiElementSelected: "",
    emojiPageData: {},
    setEmojiPickerLoaded: false,
  },
  getters: {
    frappeHost({ state }) {
      return state.frappeHost;
    },
    linksType({ state }) {
      return state.linksType;
    },
    linkType({ state }) {
      return state.linkType;
    },
    bloquesLocal({ state }) {
      return state.bloquesLocal;
    },
    qrsLocal({ state }) {
      return state.qrsLocal;
    },
    bloquesLocalPublic({ state }) {
      return state.bloquesLocalPublic;
    },
    baseBlock({ state }) {
      return state.baseBlock;
    },
    openOnboardingSheet({ state }) {
      return state.openOnboardingSheet;
    },
    recoverPasswordToken({ state }) {
      return state.recoverPasswordToken;
    },
    recoverPasswordEmail({ state }) {
      return state.recoverPasswordEmail;
    },
    openMainVideoTutorial({ state }) {
      return state.openMainVideoTutorial;
    },
    openTryProSheet({ state }) {
      return state.openTryProSheet;
    },
    openBuyProSheet({ state }) {
      return state.openBuyProSheet;
    },
    openEndFreeTrialSheet({ state }) {
      return state.openEndFreeTrialSheet;
    },
    usuarioEmail({ state }) {
      return state.usuarioEmail;
    },
    // loading({ loading }) {
    //   return state.loading;
    // },
    local({ state }) {
      return state.local;
    },
    suscripcion({ state }) {
      return state.suscripcion;
    },
    customQR({ state }) {
      return state.customQR;
    },
    customQRStyle({ state }) {
      return state.customQRStyle;
    },
    customQRText({ state }) {
      return state.customQRText;
    },
    customQRSubText({ state }) {
      return state.customQRSubText;
    },
    customQRImage({ state }) {
      return state.customQRImage;
    },
    customQRSize({ state }) {
      return state.customQRSize;
    },
    customQRMainColor({ state }) {
      return state.customQRMainColor;
    },
    customQRDotColor({ state }) {
      return state.customQRDotColor;
    },
    customQRCornerColor({ state }) {
      return state.customQRCornerColor;
    },
    customQRDotCornerColor({ state }) {
      return state.customQRDotCornerColor;
    },
    userLogged({ state }) {
      return state.userLogged;
    },
    siteStyle({ state }) {
      return state.siteStyle;
    },
    backgroundColor({ state }) {
      return state.backgroundColor;
    },
    backgroundColorHex({ state }) {
      return state.backgroundColorHex;
    },
    backgroundColorCustom({ state }) {
      return state.backgroundColorCustom;
    },
    mainColor({ state }) {
      return state.mainColor;
    },
    mainColorHex({ state }) {
      return state.mainColorHex;
    },
    mainColorCustom({ state }) {
      return state.mainColorCustom;
    },
    instagramPosts({ state }) {
      return state.instagramPosts;
    },
    emojiPicker({ state }) {
      return state.emojiPicker;
    },
    emojiElementSelected({ state }) {
      return state.emojiElementSelected;
    },
    emojiPageData({ state }) {
      return state.emojiPageData;
    },
    emojiPickerLoaded({ state }) {
      return state.emojiPickerLoaded;
    },
    // mainBackgroundColor({ state }) {
    //   return state.mainBackgroundColor;
    // },
  },
  actions: {
    setUsuarioEmail({ state }, usuarioEmail) {
      state.usuarioEmail = usuarioEmail;
    },
    // setLoading({ state }, loading) {
    //   state.loading = loading;
    // },
    setLocal({ state }, local) {
      state.local = local;
    },
    setOpenOnboardingSheet({ state }, openOnboardingSheet) {
      state.openOnboardingSheet = openOnboardingSheet;
    },
    setRecoverPasswordToken({ state }, recoverPasswordToken) {
      state.recoverPasswordToken = recoverPasswordToken;
    },
    setRecoverPasswordEmail({ state }, recoverPasswordEmail) {
      state.recoverPasswordEmail = recoverPasswordEmail;
    },
    setOpenMainVideoTutorial({ state }, openMainVideoTutorial) {
      state.openMainVideoTutorial = openMainVideoTutorial;
    },
    setOpenTryProSheet({ state }, openTryProSheet) {
      state.openTryProSheet = openTryProSheet;
    },
    setOpenBuyProSheet({ state }, openBuyProSheet) {
      state.openBuyProSheet = openBuyProSheet;
    },
    setOpenEndFreeTrialSheet({ state }, openEndFreeTrialSheet) {
      state.openEndFreeTrialSheet = openEndFreeTrialSheet;
    },
    setSuscripcion({ state }, suscripcion) {
      state.suscripcion = suscripcion;
    },
    setLinksType({ state }, linksType) {
      state.linksType = linksType;
    },
    setLinkType({ state }, linkType) {
      state.linkType = linkType;
    },
    setBloquesLocal({ state }, bloquesLocal) {
      state.bloquesLocal = bloquesLocal;
    },
    setQRsLocal({ state }, qrsLocal) {
      state.qrsLocal = qrsLocal;
    },
    setBloquesLocalPublic({ state }, bloquesLocalPublic) {
      state.bloquesLocalPublic = bloquesLocalPublic;
    },
    setBaseBlock({ state }, baseBlock) {
      state.baseBlock = baseBlock;
    },
    addLink({ state }, link) {
      state.links = [...state.links, link];
    },
    customizeQR({ state }, customQR) {
      state.customQR = customQR;
    },
    customizeQRStyle({ state }, customQRStyle) {
      state.customQRStyle = customQRStyle;
    },
    customizeQRMainColor({ state }, customQRMainColor) {
      state.customQRMainColor = customQRMainColor;
    },
    customizeQRText({ state }, customQRText) {
      state.customQRText = customQRText;
    },
    customizeQRSubText({ state }, customQRSubText) {
      state.customQRSubText = customQRSubText;
    },
    customizeQRImage({ state }, customQRImage) {
      state.customQRImage = customQRImage;
    },
    customizeQRSize({ state }, customQRSize) {
      state.customQRSize = customQRSize;
    },
    customizeQRDotColor({ state }, customQRDotColor) {
      state.customQRDotColor = customQRDotColor;
    },
    customizeQRCornerColor({ state }, customQRCornerColor) {
      state.customQRCornerColor = customQRCornerColor;
    },
    customizeQRDotCornerColor({ state }, customQRDotCornerColor) {
      state.customQRDotCornerColor = customQRDotCornerColor;
    },
    setUserLogged({ state }, userLogged) {
      state.userLogged = userLogged;
    },
    setSiteStyle({ state }, siteStyle) {
      state.siteStyle = siteStyle;
    },
    setBackgroundColor({ state }, backgroundColor) {
      state.backgroundColor = backgroundColor;
    },
    setBackgroundColorHex({ state }, backgroundColorHex) {
      state.backgroundColorHex = backgroundColorHex;
    },
    setBackgroundColorCustom({ state }, backgroundColorCustom) {
      state.backgroundColorCustom = backgroundColorCustom;
    },
    setMainColor({ state }, mainColor) {
      state.mainColor = mainColor;
    },
    setMainColorHex({ state }, mainColorHex) {
      state.mainColorHex = mainColorHex;
    },
    setMainColorCustom({ state }, mainColorCustom) {
      state.mainColorCustom = mainColorCustom;
    },
    // setMainBackgroundColor({ state }, mainBackgroundColor) {
    //   state.mainBackgroundColor = mainBackgroundColor;
    // },
    setInstagramPosts({ state }, instagramPosts) {
      state.instagramPosts = instagramPosts;
    },
    setEmojiPicker({ state }, emojiPicker) {
      state.emojiPicker = emojiPicker;
    },
    setEmojiElementSelected({ state }, emojiElementSelected) {
      state.emojiElementSelected = emojiElementSelected;
    },
    setEmojiPageData({ state }, emojiPageData) {
      state.emojiPageData = emojiPageData;
    },
    setEmojiPickerLoaded({ state }, emojiPickerLoaded) {
      state.emojiPickerLoaded = emojiPickerLoaded;
    },
  },
});
export default store;
