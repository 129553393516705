<template>
  <f7-sheet
    backdrop
    style="
      height: -webkit-fill-available;
      overflow: auto;
      margin: 2.5%;
      width: 95%;
      border-radius: 10px;
      position: fixed;
      left: 0;
      right: 0;
      margin: 10px auto;
    "
    :opened="openOnboardingSheet"
    @sheet:open="sheetBeforeIn"
    :close-by-backdrop-click="false"
  >
    <f7-page-content>
      <!-- BOTON DE SALTEAR -->
      <f7-block
        class="
          margin-top
          no-margin-bottom
          display-flex
          justify-content-flex-end
        "
        style="width: 100%; position: absolute; top: 0px; z-index: 15000"
      >
        <f7-row class="text-align-center">
          <f7-col>
            <f7-link @click="closeOnboardingSheet"
              ><f7-badge
                color="gray"
                style="padding: 16px 10px; opacity: 0.5; font-weight: 600"
                ><span>&nbsp;<i class="fas fa-times"></i>&nbsp;</span></f7-badge
              >
            </f7-link>
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- PASO CERO -->
      <f7-block v-show="stepOnboarding" class="no-margin onboarding-page-block">
        <f7-row>
          <f7-col class="text-align-center">
            <h1 class="no-margin no-padding">🎉 🎉 🎉</h1>
            <h1>¡Felicidades! Tu Sitio ya esta online</h1>
            <!-- <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            > -->
            <p class="padding">
              Ahora vas a poder compartir todo el contenido que necesites con tu
              audiencia, con tu propio estilo y accesible desde tu propio link o
              código QR.
            </p>
            <p class="padding">
              Mirá el video y enterate lo fácil que es configurar y personalizar
              tu Sitio.
            </p>
            <!-- <br />
            <p class="padding">
              Una vez terminado el período de prueba podras continuar usando las
              funciones básicas o contratar alguno de
              <a
                href="https://miqro.app/planes"
                class="external"
                target="_blank"
                >nuestros planes</a
              >
              desde <strong>$ 599,00.- finales por mes</strong>.
            </p> -->
          </f7-col>
        </f7-row>
        <f7-row class="padding">
          <f7-col>
            <f7-button
              fill
              round
              @click="
                stepOnboarding = false;
                stepZero = true;
                scrollToTop();
              "
              >VER PASO A PASO</f7-button
            >
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- PASO CERO -->
      <!-- <f7-block
        v-show="stepVideo"
        class="no-margin no-padding"
        style="width: 100%; z-index: 0; height: 100%"
      >
        <f7-row>
          <f7-col
            class="display-flex justify-content-center"
            style="height: 100%"
          >
            <video
              :src="srcHelpVideo"
              style="
                width: 100%;
                display: block;
                position: absolute;
                object-fit: contain;
                border-radius: 10 px;
                height: 100%;
              "
              controls
            ></video>
          </f7-col>
        </f7-row>
      </f7-block> -->

      <!-- PASO CERO -->
      <f7-block v-show="stepZero" class="no-margin onboarding-page-block">
        <f7-row>
          <f7-col class="text-align-center">
            <h1>🤔 ¿Cómo uso miqro.app?</h1>
            <p>
              En este breve paso a paso te explicamos como en solo
              <strong>unos pocos minutos</strong> podes tener tu Sitio listo
              para compartir contenido.
            </p>
            <br />
            <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              😎 Completá tu perfil
            </p>
            <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              💡 Subí contenido
            </p>
            <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              🎨 Personalizá tu Sitio
            </p>
            <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              🚀 Compartí tu link o código QR
            </p>
          </f7-col>
        </f7-row>
        <f7-row class="padding">
          <f7-col>
            <f7-button
              fill
              round
              @click="
                stepZero = false;
                stepOne = true;
                scrollToTop();
              "
              >EMPECEMOS</f7-button
            >
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- PASO UNO -->
      <f7-block v-show="stepOne" class="no-margin onboarding-page-block">
        <f7-row>
          <f7-col class="text-align-center">
            <h2 class="no-margin-bottom">😎 Completá tu perfil</h2>
            <!-- <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              Una de las partes mas importantes de tu miqrositio es tu
              perfil.<br /><br />
              En el podrás completar todos los datos de contacto de tu negocio,
              así como también una imagen de perfil, redes sociales, horarios de
              apertura y mas.
            </p> -->
            <!-- <div> -->
            <!-- <div
                class="text-align-center margin-bottom"
                style="
                  font-size: 16px;
                  display: block;
                  position: relative;
                  font-weight: 600;
                "
              >
                Mirá el video 👇
              </div> -->
            <!-- <f7-row>
                <f7-col
                  class="display-flex justify-content-center"
                  style="height: 100%"
                >
                  <video
                    :src="srcHelpVideo"
                    style="
                      width: 100%;
                      display: block;
                      position: absolute;
                      object-fit: contain;
                      border-radius: 10 px;
                      height: 100%;
                    "
                    controls
                  ></video>
                </f7-col>
              </f7-row> -->
            <!-- <img
                style="width: 100%; box-shadow: #7b7b7b 0px 0rem 1rem -0.25rem"
                src="../static/images/miqrositio_barra_inferior_perfil.png"
                alt=""
              /> -->
            <!-- <p>Podrás encontrar tu perfil en la barra inferior.</p> -->
            <!-- </div> -->
            <!-- <br /> -->
          </f7-col>
        </f7-row>
        <f7-row class="margin-top" style="height: 100vh">
          <f7-col
            class="display-flex justify-content-center align-items-center"
            style="height: 100%"
          >
            <video
              src="/static/media/Tutorial_MiqroSitio_Paso_1.webm"
              class="onboarding-video margin-bottom-half"
              controls
            ></video>
          </f7-col>
        </f7-row>
        <f7-row class="padding-half onboarding-arrows">
          <f7-col>
            <span class="display-flex justify-content-flex-start"
              ><i
                class="fas fa-angle-left onboarding-arrow-icon"
                @click="
                  stepZero = true;
                  stepOne = false;
                  scrollToTop();
                "
              ></i
            ></span>
          </f7-col>
          <f7-col>
            <span class="display-flex justify-content-flex-end"
              ><i
                class="fas fa-angle-right onboarding-arrow-icon"
                @click="
                  stepOne = false;
                  stepTwo = true;
                  scrollToTop();
                "
              ></i
            ></span>
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- PASO DOS -->
      <f7-block v-show="stepTwo" class="no-margin onboarding-page-block">
        <f7-row>
          <f7-col class="text-align-center">
            <h2 class="no-margin-bottom">💡 Publicá contenido</h2>
            <!-- <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              Una de las partes mas importantes de tu miqrositio es tu
              perfil.<br /><br />
              En el podrás completar todos los datos de contacto de tu negocio,
              así como también una imagen de perfil, redes sociales, horarios de
              apertura y mas.
            </p> -->
            <!-- <div> -->
            <!-- <div
                class="text-align-center margin-bottom"
                style="
                  font-size: 16px;
                  display: block;
                  position: relative;
                  font-weight: 600;
                "
              >
                Mirá el video 👇
              </div> -->
            <!-- <f7-row>
                <f7-col
                  class="display-flex justify-content-center"
                  style="height: 100%"
                >
                  <video
                    :src="srcHelpVideo"
                    style="
                      width: 100%;
                      display: block;
                      position: absolute;
                      object-fit: contain;
                      border-radius: 10 px;
                      height: 100%;
                    "
                    controls
                  ></video>
                </f7-col>
              </f7-row> -->
            <!-- <img
                style="width: 100%; box-shadow: #7b7b7b 0px 0rem 1rem -0.25rem"
                src="../static/images/miqrositio_barra_inferior_perfil.png"
                alt=""
              /> -->
            <!-- <p>Podrás encontrar tu perfil en la barra inferior.</p> -->
            <!-- </div> -->
            <!-- <br /> -->
          </f7-col>
        </f7-row>
        <f7-row class="margin-top" style="height: 100vh">
          <f7-col
            class="display-flex justify-content-center align-items-center"
            style="height: 100%"
          >
            <video
              src="/static/media/Tutorial_MiqroSitio_Paso_2.webm"
              class="onboarding-video margin-bottom-half"
              controls
            ></video>
          </f7-col>
        </f7-row>

        <f7-row class="padding-half onboarding-arrows">
          <f7-col>
            <span class="display-flex justify-content-flex-start"
              ><i
                class="fas fa-angle-left onboarding-arrow-icon"
                @click="
                  stepOne = true;
                  stepTwo = false;
                  scrollToTop();
                "
              ></i
            ></span>
          </f7-col>
          <f7-col>
            <span class="display-flex justify-content-flex-end"
              ><i
                class="fas fa-angle-right onboarding-arrow-icon"
                @click="
                  stepTwo = false;
                  stepThree = true;
                  scrollToTop();
                "
              ></i
            ></span>
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- PASO TRES -->
      <f7-block v-show="stepThree" class="no-margin onboarding-page-block">
        <f7-row>
          <f7-col class="text-align-center">
            <h2 class="no-margin-bottom">🎨 Personaliza tu Sitio</h2>
            <!-- <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              Una de las partes mas importantes de tu miqrositio es tu
              perfil.<br /><br />
              En el podrás completar todos los datos de contacto de tu negocio,
              así como también una imagen de perfil, redes sociales, horarios de
              apertura y mas.
            </p> -->
            <!-- <div> -->
            <!-- <div
                class="text-align-center margin-bottom"
                style="
                  font-size: 16px;
                  display: block;
                  position: relative;
                  font-weight: 600;
                "
              >
                Mirá el video 👇
              </div> -->
            <!-- <f7-row>
                <f7-col
                  class="display-flex justify-content-center"
                  style="height: 100%"
                >
                  <video
                    :src="srcHelpVideo"
                    style="
                      width: 100%;
                      display: block;
                      position: absolute;
                      object-fit: contain;
                      border-radius: 10 px;
                      height: 100%;
                    "
                    controls
                  ></video>
                </f7-col>
              </f7-row> -->
            <!-- <img
                style="width: 100%; box-shadow: #7b7b7b 0px 0rem 1rem -0.25rem"
                src="../static/images/miqrositio_barra_inferior_perfil.png"
                alt=""
              /> -->
            <!-- <p>Podrás encontrar tu perfil en la barra inferior.</p> -->
            <!-- </div> -->
            <!-- <br /> -->
          </f7-col>
        </f7-row>
        <f7-row class="margin-top" style="height: 100vh">
          <f7-col
            class="display-flex justify-content-center align-items-center"
            style="height: 100%"
          >
            <video
              src="/static/media/Tutorial_MiqroSitio_Paso_3.webm"
              class="onboarding-video margin-bottom-half"
              controls
            ></video>
          </f7-col>
        </f7-row>
        <f7-row class="padding-half onboarding-arrows">
          <f7-col>
            <span class="display-flex justify-content-flex-start"
              ><i
                class="fas fa-angle-left onboarding-arrow-icon"
                @click="
                  stepTwo = true;
                  stepThree = false;
                  scrollToTop();
                "
              ></i
            ></span>
          </f7-col>
          <f7-col>
            <span class="display-flex justify-content-flex-end"
              ><i
                class="fas fa-angle-right onboarding-arrow-icon"
                @click="
                  stepThree = false;
                  stepFour = true;
                  scrollToTop();
                "
              ></i
            ></span>
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- PASO CINCO -->
      <f7-block v-show="stepFour" class="no-margin onboarding-page-block">
        <f7-row>
          <f7-col class="text-align-center">
            <h2 class="no-margin-bottom">🚀 Compartí el link o el QR</h2>
            <!-- <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              Una de las partes mas importantes de tu miqrositio es tu
              perfil.<br /><br />
              En el podrás completar todos los datos de contacto de tu negocio,
              así como también una imagen de perfil, redes sociales, horarios de
              apertura y mas.
            </p> -->
            <!-- <div> -->
            <!-- <div
                class="text-align-center margin-bottom"
                style="
                  font-size: 16px;
                  display: block;
                  position: relative;
                  font-weight: 600;
                "
              >
                Mirá el video 👇
              </div> -->
            <!-- <f7-row>
                <f7-col
                  class="display-flex justify-content-center"
                  style="height: 100%"
                >
                  <video
                    :src="srcHelpVideo"
                    style="
                      width: 100%;
                      display: block;
                      position: absolute;
                      object-fit: contain;
                      border-radius: 10 px;
                      height: 100%;
                    "
                    controls
                  ></video>
                </f7-col>
              </f7-row> -->
            <!-- <img
                style="width: 100%; box-shadow: #7b7b7b 0px 0rem 1rem -0.25rem"
                src="../static/images/miqrositio_barra_inferior_perfil.png"
                alt=""
              /> -->
            <!-- <p>Podrás encontrar tu perfil en la barra inferior.</p> -->
            <!-- </div> -->
            <!-- <br /> -->
          </f7-col>
        </f7-row>
        <f7-row class="margin-top" style="height: 100vh">
          <f7-col
            class="display-flex justify-content-center align-items-center"
            style="height: 100%"
          >
            <video
              src="/static/media/Tutorial_MiqroSitio_Paso_4.webm"
              class="onboarding-video margin-bottom-half"
              controls
            ></video>
          </f7-col>
        </f7-row>
        <f7-row class="padding-half onboarding-arrows">
          <f7-col>
            <span class="display-flex justify-content-flex-start"
              ><i
                class="fas fa-angle-left onboarding-arrow-icon"
                @click="
                  stepThree = true;
                  stepFour = false;
                  scrollToTop();
                "
              ></i
            ></span>
          </f7-col>
          <f7-col>
            <span class="display-flex justify-content-flex-end"
              ><i
                class="fas fa-angle-right onboarding-arrow-icon"
                @click="closeOnboardingSheet()"
              ></i
            ></span>
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- 🚀 -->

      <!-- PASO CUATRO -->
      <!-- <f7-block
        v-show="stepFour"
        class="no-margin"
        style="
          height: calc(100vh - 100px);
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <f7-row>
          <f7-col class="text-align-center">
            <h1>🎉 ¡Felicitaciones!</h1>
            <p>
              Tu miqrositio ya se encuentra online.<br />Ahora es tu turno para
              dejar tu Miqrositio como mas te guste, y compartirlo para que las
              personas puedan ingresar.
            </p>
            Recordá que:
            <br />
            <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              ❓ Podes acceder el video tutorial ingresando desde el menú
              <i class="fas fa-bars"></i> y tocando la opción
              <strong>TUTORIAL</strong>
            </p>
            Además:
            <br />
            <p
              class="padding-half"
              style="background: #f3f3f3; border-radius: 10px"
            >
              <i class="fab fa-whatsapp" style="color: #25d366"></i>
              &nbsp; Podes escribirnos por WhatsApp desde
              <a class="external" href="https://wa.me/541158901003">este link</a
              >, o accediendo al menú <i class="fas fa-bars"></i> y tocando la
              opción <strong>CONTACTANOS</strong>
            </p>
          </f7-col>
        </f7-row>
        <f7-row class="padding">
          <f7-col>
            <f7-button color="red" @click="closeOnboardingSheet"
              >Cerrar Tutorial</f7-button
            >
          </f7-col>
        </f7-row>
      </f7-block> -->
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { useStore } from "framework7-vue";
import store from "../js/store";
import utils from "../js/utils";

export default {
  data() {
    return {
      stepOnboarding: true,
      stepVideo: false,
      stepZero: false,
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      stepFour: false,
      stepFive: false,
    };
  },
  setup() {
    const openOnboardingSheet = useStore("openOnboardingSheet");
    const local = useStore("local");
    const suscripcion = useStore("suscripcion");
    const srcHelpVideo = "";

    return {
      suscripcion,
      openOnboardingSheet,
      local,
      srcHelpVideo,
    };
  },
  computed: {
    vencimientoSuscription() {
      if (this.suscripcion) {
        let parsedDate = new Date(
          Date.parse(this.suscripcion.vencimiento_suscripcion.split("T")[0])
        );
        let formattedDate = `${parsedDate.getDate()}/${parsedDate.getMonth()}/${parsedDate.getFullYear()}`;
        return formattedDate;
      }
    },
  },
  methods: {
    sheetBeforeIn() {
      const self = this;

      if (self.local.onboarding_completo === 1) {
        self.stepOnboarding = false;
        self.stepZero = true;
      }
    },
    closeOnboardingSheet() {
      const self = this;

      store.dispatch("setOpenOnboardingSheet", false);
      if (self.local.onboarding_completo === 0) {
        store.dispatch("setOpenTryProSheet", true);
      }

      utils.axiosCall(
        "post",
        "linqr",
        "local",
        "onboarding_complete",
        {},
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {},
        function (e) {}
      );

      self.stepZero = true;
      self.stepOne = false;
      self.stepTwo = false;
      self.stepThree = false;
      self.stepFour = false;
      self.stepFive = false;
    },
    scrollToTop() {
      let elementToScroll = document.querySelector(
        ".sheet-modal-inner .page-content"
      );
      elementToScroll.scroll({ top: 0, behavior: "smooth" });
    },
  },
};
</script>