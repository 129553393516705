import { f7, useStore } from "framework7-vue";
import store from "./store";
import utils from "./utils";
import crudLocal from "./crud-local";

let signOut = () => {
  window.localStorage.removeItem("token");
  store.dispatch("setUserLogged", null);
  f7.views.main.router.navigate("/admin/", {
    reloadCurrent: true,
  });
  // f7.views.main.router.refreshPage();
};

let changeProPlan = (self) => {
  utils.axiosCall(
    "post",
    "linqr",
    "suscripcion",
    "change_pro_plan",
    {
      local: self.local.name,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        crudLocal.getSuscripcion(self.local);
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();
      }
    }
  );
};

let cancelProPlan = (self) => {
  utils.axiosCall(
    "post",
    "linqr",
    "suscripcion",
    "cancel_pro_plan",
    {
      local: self.local.name,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        crudLocal.getSuscripcion(self.local);
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();
      }
    }
  );
};

let removeAccount = (self) => {
  f7.dialog.preloader("Aguarde");

  utils.axiosCall(
    "post",
    "linqr",
    "usuario_app",
    "remove_account",
    {
      local: self.local.name,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();
        f7.dialog.close();
        signOut();
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
        f7.dialog.close();
      }
    }
  );
};

let changePassword = (self) => {};

export default {
  signOut,
  changeProPlan,
  cancelProPlan,
  removeAccount,
  changePassword,
};
