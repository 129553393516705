<template>
  <!-- RECUPERAR CONTRASEÑA -->
  <f7-login-screen
    id="my-recover-screen"
    :opened="props.forgetPasswordOpen || recoverPasswordToken !== undefined"
  >
    <f7-view>
      <f7-page login-screen>
        <logoAuth />
        <div style="width: 100%">
          <h2
            class="
              text-align-center
              margin-horizontal
              no-margin-vertical
              margin-bottom-half
            "
            style="color: #222222; font-size: 30px; font-weight: 400"
            >{{
              recoverPasswordToken
                ? "Restaura tu contraseña"
                : "Ingresa el email con el que te registraste"
            }}</h2
          >
          <p class="margin-horizontal no-margin-top text-align-center"
            >{{
              recoverPasswordToken
                ? "Ingresa la contraseña que usaras de ahora en adelante. La que tenías anteriormente ya no será válida."
                : "Recibiras un correo electrónico con un enlace para que puedas restaurar tu contraseña. No compartas ese enlace con ninguna persona."
            }}</p
          >
          <f7-list
            form
            @submit="
              (e) => {
                recoverPasswordToken === undefined
                  ? recoverPassword(e)
                  : changePassword(e);
              }
            "
          >
            <f7-list-input
              outline
              floating-label
              class="transform-lowercase"
              type="email"
              name="recover_password_email"
              placeholder="Tu correo electrónico"
              v-model:value="recoverPasswordEmail"
              validate
              error-message="Ingresa un email válido"
              v-if="recoverPasswordToken === undefined ? true : false"
            ></f7-list-input>
            <f7-list-input
              v-if="recoverPasswordToken !== undefined"
              outline
              floating-label
              :type="inputTypePasswordRecover"
              name="contrasenia"
              placeholder="Contraseña"
              v-model:value="contraseniaRecover"
              validate
              pattern=".{8,}"
              error-message="Debe contener 8 o mas caracteres"
            >
              <template #content>
                <span
                  class="mostrar-contrasenia-icon"
                  v-show="inputTypePasswordRecover === 'password'"
                  @click="inputTypePasswordRecover = 'text'"
                  ><i class="fas fa-eye"></i
                ></span>
                <span
                  class="mostrar-contrasenia-icon"
                  v-show="inputTypePasswordRecover === 'text'"
                  @click="inputTypePasswordRecover = 'password'"
                  ><i class="fas fa-eye-slash"></i
                ></span>
              </template>
            </f7-list-input>
            <f7-list-input
              v-if="recoverPasswordToken !== undefined"
              outline
              floating-label
              :type="inputTypePasswordRecoverRepeat"
              name="repetirContrasenia"
              placeholder="Reingrese la contraseña"
              validate
              :pattern="contraseniaRecover"
              error-message="Las contraseñas no coinciden"
            >
              <template #content>
                <span
                  class="mostrar-contrasenia-icon"
                  v-show="inputTypePasswordRecoverRepeat === 'password'"
                  @click="inputTypePasswordRecoverRepeat = 'text'"
                  ><i class="fas fa-eye"></i
                ></span>
                <span
                  class="mostrar-contrasenia-icon"
                  v-show="inputTypePasswordRecoverRepeat === 'text'"
                  @click="inputTypePasswordRecoverRepeat = 'password'"
                  ><i class="fas fa-eye-slash"></i
                ></span>
              </template>
            </f7-list-input>
            <li>
              <div
                class="
                  item-content item-input margin-vertical
                "
              >
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <f7-button color="green" type="submit" fill href="#"
                      >RESTAURAR CONTRASEÑA</f7-button
                    >
                  </div>
                </div>
              </div>
            </li>
          </f7-list>
        </div>
        <f7-list class="margin-bottom-half">
          <f7-block-footer class="no-margin-bottom">
            <!-- ¿Ya estas registrado? -->
            <f7-button
              @click="
                props.forgetRecoverPassword = false;
                props.loginScreenOpen = true;
                closeRecoverSheetByStore();
              "
              href="#"
              style="font-weight: 600"
              >Volver al Inicio</f7-button
            ></f7-block-footer
          >
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</template>

<script>
import authentication from "../../js/authentication";
import store from "../../js/store";
import logoAuth from "./logo-auth";
import { useStore } from "framework7-vue";

export default {
  components: {
    logoAuth,
  },
  props: {
    props: Object,
  },
  setup() {
    let recoverPasswordToken = useStore("recoverPasswordToken");
    let recoverPasswordEmail = useStore("recoverPasswordEmail");

    return { recoverPasswordToken, recoverPasswordEmail };
  },
  data() {
    return {
      contraseniaRecover: "",
      inputTypePasswordRecover: "password",
      inputTypePasswordRecoverRepeat: "password",
    };
  },
  methods: {
    recoverPassword(e) {
      e.preventDefault();
      const self = this;
      authentication.recoverPassword(self, e);
    },
    changePassword(e) {
      e.preventDefault();
      const self = this;
      authentication.changePassword(self, e);
    },
    closeRecoverSheetByStore() {
      const self = this;

      store.dispatch("setRecoverPasswordToken", undefined);
    },
  },
};
</script>