<template>
  <f7-sheet
    backdrop
    style="height: -webkit-fill-available; overflow: auto"
    :close-by-backdrop-click="false"
    @sheet:opened="sheetOpened"
  >
    <f7-page-content>
      <!-- SKELETON - BARRA DE NAVEGACION -->
      <!-- <f7-row
        class="new-link-sheet-row skeleton-effect-fade"
        v-if="props.loading"
      >
        <f7-col width="75">
          <f7-block-title class="no-margin-top skeleton-text" large
            >TestTestTestTestTest</f7-block-title
          >
          <f7-block-title
            v-if="props.linkTypeName"
            class="no-margin-top skeleton-text"
          >
            TestTestTest
          </f7-block-title>
        </f7-col>
      </f7-row> -->

      <!-- BARRA DE NAVEGACION -->
      <linkNavbar
        :props="props"
        :operation="props.operation"
        :f7router="f7router"
      />

      <!-- SKELETON - LISTADO DE CAMPOS -->
      <!-- <f7-block v-if="props.loading" class="no-padding-horizontal">
        <span style="height: 75px; width: 100%; display: block"></span>
        <f7-list
          no-hairlines
          no-hairlines-between
          class="margin-vertical-half skeleton-text skeleton-effect-fade"
          v-for="n in skeletonRows"
          :key="n"
        >
          <f7-list-item class="skeleton-text">
            TestTestTestTestTestTestTestTestTestTestTestTest
            TestTestTestTestTestTestTestTestTestTestTestTest
          </f7-list-item>
        </f7-list>
      </f7-block> -->

      <!-- LISTADO DE CAMPOS -->
      <f7-block v-if="!props.loading" class="no-padding-horizontal">
        <f7-list
          no-hairlines
          no-hairlines-between
          class="margin-vertical-half virtual-list-links"
          form
          id="link-form"
        >
          <!-- FILA VACIA - REEMPLAZAR CON CSS -->
          <f7-list-item>
            <span style="height: 75px"></span>
          </f7-list-item>
          <!-- VISIBILIDAD -->
          <f7-list-item>
            <span>Visible en la Página</span>
            <f7-toggle
              color="green"
              name="habilitado"
              :checked="
                props.dataBloque.habilitado && props.dataBloque.habilitado === 1
                  ? true
                  : false
              "
              @change="(e) => setValueForm(e)"
            ></f7-toggle>
          </f7-list-item>

          <!-- ACORDEON - INFORMACION GENERAL -->
          <li class="list-item-divider-custom">
            <div class="item-content">
              <div class="item-inner">
                <span>
                  <i class="fas fa-info-circle"></i>&nbsp;&nbsp;
                  <span>Información General</span>
                </span>
              </div>
            </div>
          </li>
          <!-- <f7-list-item accordion-item class="accordion-item-opened">
            <template #title>
              <span>
                <i class="fas fa-info-circle"></i>&nbsp;&nbsp;
                <span>Información General</span>
              </span>
            </template>
            <f7-accordion-content> -->
          <li>
            <span>
              <sectionCommonFields :props="props" />
            </span>
          </li>
          <!-- </f7-accordion-content>
          </f7-list-item> -->

          <!-- ACORDEON - INSTAGRAM -->
          <!-- <f7-list-item accordion-item class="accordion-item-opened">
            <template #title>
              <span>
                <i class="fas fa-info-circle"></i>&nbsp;&nbsp;
                <span>Información General</span>
              </span>
            </template>
            <f7-accordion-content>
              <sectionItemsGallery :props="props" />
            </f7-accordion-content>
          </f7-list-item> -->

          <!-- ACORDEON - ITEMS-->
          <!-- <f7-list-item
            accordion-item
            v-if="
              props.linkTypeId === 'catalogo' ||
              props.linkTypeId === 'galeria_de_imagenes'
            "
          >
            <template #title>
              <span>
                <i class="fas fa-align-justify"></i>&nbsp;&nbsp;
                <span>Items</span>
              </span>
            </template>
            <f7-accordion-content> -->
          <!-- <div
            v-if="
              props.linkTypeId === 'catalogo' ||
              props.linkTypeId === 'galeria_de_imagenes'
            "
          >
            <sectionItemsGallery :props="props" />
          </div> -->
          <!-- </f7-accordion-content>
          </f7-list-item> -->

          <!-- ACORDEON - CAMPOS DEL FORMULARIO -->
          <!-- <f7-list-item accordion-item v-if="props.linkTypeId === 'formulario'">
            <template #title>
              <span>
                <i class="fas fa-align-justify"></i>&nbsp;&nbsp;
                <span>Campos del Formulario</span>
              </span>
            </template>
            <f7-accordion-content> -->
          <li
            class="list-item-divider-custom"
            v-if="props.linkTypeId === 'formulario'"
          >
            <div class="item-content">
              <div class="item-inner">
                <span>
                  <i class="fas fa-align-justify"></i>&nbsp;&nbsp;
                  <span>Campos del Formulario</span>
                </span>
              </div>
            </div>
          </li>
          <li v-if="props.linkTypeId === 'formulario'">
            <span>
              <sectionFormFields :props="props" />
            </span>
          </li>
          <!-- </f7-accordion-content>
          </f7-list-item> -->
          <!-- ACORDEON - PROGRAMACION DE HORARIOS -->
          <!-- <f7-list-item
            accordion-item
            :opened="true"
            v-if="
              props.linkTypeId !== 'titulo_de_seccion' &&
              suscripcion.nombre_suscripcion_actual === 'Pro'
            "
          > -->
          <!-- <template #title>
              <span>
                <i class="fas fa-calendar-alt"></i>&nbsp;&nbsp;
                <span>Programar Horarios</span>
              </span>
            </template>
            <f7-accordion-content> -->
          <li
            v-if="props.linkTypeId !== 'titulo_de_seccion'"
            class="list-item-divider-custom"
            :class="
              suscripcion.nombre_suscripcion_actual === 'Gratuito'
                ? 'list-item-divider-custom-disabled'
                : ''
            "
          >
            <div class="item-content">
              <div class="item-inner">
                <span>
                  <i class="fas fa-calendar-alt"></i>&nbsp;&nbsp;
                  <span>Programar Horarios</span>&nbsp;&nbsp;
                  <span
                    v-if="suscripcion.nombre_suscripcion_actual === 'Gratuito'"
                    class="badge badge-pro"
                    ><i class="fas fa-crown"></i>&nbsp;&nbsp;Pro</span
                  >
                </span>
              </div>
            </div>
          </li>
          <li>
            <span>
              <sectionScheduler :props="props" />
            </span>
          </li>
          <!-- </f7-accordion-content> -->
          <!-- </f7-list-item> -->
          <!-- ACORDEON - RENDIMIENTO -->
          <!-- <f7-list-item
            accordion-item
            :opened="true"
            v-if="
              props.linkTypeId !== 'titulo_de_seccion' &&
              suscripcion.nombre_suscripcion_actual === 'Pro'
            "
          >
            <template #title>
              <span>
                <i class="far fa-chart-bar"></i>&nbsp;&nbsp;
                <span>Rendimiento (Interacciones)</span>
              </span>
            </template>
            <f7-accordion-content> -->
          <li
            v-if="props.linkTypeId !== 'titulo_de_seccion'"
            class="list-item-divider-custom"
            :class="
              suscripcion.nombre_suscripcion_actual === 'Gratuito'
                ? 'list-item-divider-custom-disabled'
                : ''
            "
          >
            <div class="item-content">
              <div class="item-inner">
                <span>
                  <i class="fas fa-chart-bar"></i>&nbsp;&nbsp;
                  <span
                    >Interacciones&nbsp;&nbsp;
                    <span
                      v-if="
                        suscripcion.nombre_suscripcion_actual === 'Gratuito'
                      "
                      class="badge badge-pro"
                      ><i class="fas fa-crown"></i>&nbsp;&nbsp;Pro</span
                    ></span
                  >
                </span>
              </div>
            </div>
          </li>
          <li v-if="props.linkTypeId !== 'titulo_de_seccion'">
            <span>
              <sectionPerformance :props="props" />
            </span>
          </li>

          <!-- </f7-accordion-content>
          </f7-list-item> -->
        </f7-list>
      </f7-block>
    </f7-page-content>
    <div class="instagram-posts-container"></div>
    <fabEmojiPicker />
  </f7-sheet>
</template>

<script>
const axios = require("axios").default;
import { useStore, f7, $ref } from "framework7-vue";
import store from "../../js/store";
import utils from "../../js/utils";
import fileUploader from "../../js/file-uploader";
import crudBloques from "../../js/crud-bloques";
import { attr } from "dom7";
import linkNavbar from "./link-navbar.vue";
import sectionCommonFields from "./blocks/section_common_fields.vue";
import sectionFormFields from "./blocks/section_form_fields.vue";
import sectionScheduler from "./blocks/section_scheduler.vue";
import sectionPerformance from "./blocks/section_performance.vue";
import sectionItemsGallery from "./blocks/section_items_gallery.vue";
import fabEmojiPicker from "../fab-emoji-picker.vue";

const Dashboard = require("@uppy/dashboard");

export default {
  props: {
    f7route: Object,
    f7router: Object,
    operation: String,
  },
  components: {
    Dashboard,
    linkNavbar,
    sectionCommonFields,
    sectionFormFields,
    sectionScheduler,
    sectionPerformance,
    sectionItemsGallery,
    fabEmojiPicker,
  },
  data() {
    return {
      props: {
        linkId: null,
        linkTypeId: null,
        linkTypeName: null,
        linkTypeLS: {},
        preloadedImage: false,
        dataBloque: {},
        formDataBloque: {},
        loading: true,
        disableSave: false,
        fileUploaded: false,
        operation: this.operation,
        galleryItemParent: null,
        customButtons: null,
        instagramPosts: [],
        currentInputSel: "",
        actionSelectInstagramPostProduct: false,
        galleryImages: [],
      },
      skeletonRows: 1,
    };
  },
  setup() {
    const local = useStore("local");
    const suscripcion = useStore("suscripcion");

    return {
      local,
      suscripcion,
    };
  },
  // computed: {
  //   connectingWithInstagram() {
  //     if (this.local.integracion_instagram) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   },
  //   connectedWithInstagram() {
  //     if (this.local.integracion_instagram) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   },
  // },
  mounted() {
    const self = this;

    utils.loadPickerWidget(self);

    if (self.props.operation === "create") {
      setTimeout(() => {
        self.props.linkTypeLS = JSON.parse(localStorage.getItem("block_type"));
        if (self.props.linkTypeLS) {
          self.props.linkTypeId = self.props.linkTypeLS.id_nombre;
          self.props.linkTypeName = self.props.linkTypeLS.nombre_tipo_de_bloque;
        }
        self.uppyConfig();
      }, 500);
    } else if (self.props.operation === "edit") {
      self.props.linkId = self.f7route.params.id;
      crudBloques.getBloque(self, self.props.linkId, self.uppyConfig);
    }

    if (self.local.ig_user && self.local.ig_access_token) {
      crudBloques.getInstagramPosts(
        self.local.ig_user,
        self.local.ig_access_token
      );
    }

    self.textEditorCustomButtons();
    self.props.dataBloque.habilitado = 1;
    self.props.formDataBloque.habilitado = 1;
    self.props.loading = false;
  },
  methods: {
    sheetOpened() {
      const self = this;
      fileUploader.uppyConfigGaleria(self);
      fileUploader.uppyConfig(self);
    },
    calculateSkeletonRows() {
      const self = this;
      self.skeletonRows = Math.floor(
        (window.visualViewport.height * 0.9 - (106 + 25)) / 64
      );
    },
    uppyConfig() {
      const self = this;
      let filesTypeBlock = ["enlace_simple", "imagen", "archivo", "producto"];

      if (filesTypeBlock.includes(self.props.linkTypeId)) {
        fileUploader.uppyConfig(self);
      }
    },
    setValueForm(e) {
      const self = this;
      crudBloques.setValueForm(self, e);
    },
    textEditorCustomButtons() {
      const self = this;

      self.props.customButtons = {
        hr: {
          // button html content
          content: "&lt;hr&gt;",
          // button click handler
          onClick(event, buttonEl) {
            document.execCommand(
              "insertHTML",
              false,
              `<div style="border-bottom: 1px solid #dadce4; width: 50%; margin: 0 auto; margin-bottom: 15px;"></div>`
            );
          },
        },
        customlink: {
          content: "<i class='fas fa-link'></i>",
          onClick(event, buttonEl) {
            var selectedText = "";
            if (window.getSelection) {
              selectedText = window.getSelection().toString();
              var value = prompt("Ingrese una URL");
              if (value) {
                document.execCommand(
                  "insertHTML",
                  false,
                  `<a class="external" href='${value}'>${selectedText}</a>`
                );
              }
            } else {
              utils.toastConfig.text = "Seleccione un texto primero";
              utils.toastConfig.cssClass = "warning-toast";
              f7.toast.create(utils.toastConfig).open();
            }
          },
        },
      };
    },
    // conectarInstagram() {
    //   const self = this;
    //   self.connectingWithInstagram = true;

    //   let base_url =
    //     "dev.miqro.app/api/method/linqr.linqr.doctype.integracion_instagram.integracion_instagram.get_instagram_access_token";
    //   let full_url =
    //     `https://www.instagram.com/oauth/authorize` +
    //     `?app_id=1094239901020484` +
    //     `&redirect_uri=https%3A%2F%2F${base_url}` +
    //     `&scope=user_profile%2Cuser_media` +
    //     `&response_type=code` +
    //     `&state=${localStorage.getItem("token")}`;

    //   window.open(full_url, "_blank");
    // },
    // checkIntegracionInstagram() {
    //   const self = this;
    //   crudBloques.checkIntegracionInstagram(self);
    // },
    // getInstagramPosts(ig_user, ig_access_token) {
    //   const self = this;

    //   axios({
    //     method: "get",
    //     url: `https://graph.instagram.com/v12.0/${ig_user}/media?fields=caption,media_url&access_token=${ig_access_token}`,
    //   })
    //     .then(function (r_user) {
    //       self.props.instagramPosts = r_user.data;
    //     })
    //     .catch(function (error) {});
    // },
  },
};
</script>
