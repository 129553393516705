<template>
  <f7-block class="no-margin-bottom">
    <f7-row>
      <f7-col
        class="text-align-center"
        v-show="!props.loggedUser || props.showPreview"
      >
        <a
          class="external container-watermark"
          href="https://miqro.app"
          target="_blank"
          >
          <!-- <span class="margin-right-half">Realizado con</span> -->
          <img
            class="logo-watermark"
            src="/static/images/miqroapp_logo_v3.png"
            alt=""
        /></a>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
export default {
  props: {
    props: Object,
  },
};
</script>