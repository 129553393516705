import { useStore, f7 } from "framework7-vue";
import utils from "./utils";
import store from "./store";
import crudBloques from "./utils";
import crudLocal from "./crud-local";

let setValueForm = function (self, e, radioValue, options) {
  // let textEditorInstance = f7.textEditor.get("#" + e.currentTarget.id);
  // console.log(textEditorInstance[0].getValue());

  var eventType = e.type;
  var eventFieldname = e.target.name;

  if (eventType === "texteditor:input") {
    var textEditorValue = e.target.f7TextEditor.value;
    var inputName = "editor_texto";
  } else {
    var inputType = e.currentTarget.type;
    var inputName = e.currentTarget.name;
    var inputValue = e.currentTarget.value;
    var inputChecked = e.currentTarget.checked === true ? 1 : 0;
  }

  if (eventType === "input") {
    if (options && options.child) {
      if (!self.props.formDataSettings[options.child]) {
        self.props.formDataSettings[options.child] = {};
      }

      if (!self.props.formDataSettings[options.child][inputName]) {
        self.props.formDataSettings[options.child][inputName] = {};
      }

      if (!self.props.dataSettings[options.child]) {
        self.props.dataSettings[options.child] = {};
      }

      if (!self.props.dataSettings[options.child][inputName]) {
        self.props.dataSettings[options.child][inputName] = {};
      }

      self.props.formDataSettings[options.child][inputName][
        "usuario_red_social"
      ] = inputValue;
      self.props.dataSettings[options.child][inputName]["usuario_red_social"] =
        inputValue;
      // if (eventFieldname === "video_url") {
      //   var regExp =
      //     /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      //   var match = inputValue.match(regExp);
      //   if (match && match[7].length == 11) {
      //     self.props.formDataBloque[inputName] =
      //       "https://www.youtube.com/embed/" + match[7];
      //     self.props.dataBloque[inputName] =
      //       "https://www.youtube.com/embed/" + match[7];
      //   } else {
      //     self.props.formDataBloque[inputName] = inputValue;
      //     self.props.dataBloque[inputName] = inputValue;
      //   }
    } else {
      self.props.formDataSettings[inputName] = inputValue;
      self.props.dataSettings[inputName] = inputValue;
    }
  } else if (eventType === "change") {
    if (inputType === "radio") {
      self.props.formDataSettings[inputName] = radioValue;
      self.props.dataSettings[inputName] = radioValue;
    } else {
      if (self.props.dataSettings[inputName] !== inputChecked) {
        self.props.formDataSettings[inputName] = inputChecked;
        self.props.dataSettings[inputName] = inputChecked;
      }
    }
  } else if (eventType === "texteditor:input") {
    self.props.formDataSettings[inputName] = textEditorValue;
    self.props.dataSettings[inputName] = textEditorValue;
  } else if (eventType === "textarea") {
    self.props.formDataSettings[inputName] = inputValue;
    self.props.dataSettings[inputName] = inputValue;
  }
};

let updateSettings = async (self, redirect) => {
  let continueChangeUrl = await checkChangeUrl(self);

  if (!continueChangeUrl) {
    f7.dialog.close();
    return;
  }

  f7.dialog.preloader("Actualizando el Perfil");

  utils.axiosCall(
    "post",
    "linqr",
    "local",
    "update_settings",
    {
      data_settings: self.props.formDataSettings,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r1) {
      if (r1.data.message.success === true) {
        crudLocal.getLocal(self, true).then((r) => {
          utils.toastConfig.text = r1.data.message.message;
          utils.toastConfig.cssClass = "success-toast";
          f7.toast.create(utils.toastConfig).open();

          if (redirect) {
            setTimeout(() => {
              f7.dialog.close();
            }, 1000);
            self.f7router.navigate("/admin", {
              reloadCurrent: true,
            });
          }
        });
      } else {
        f7.dialog.close();
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();

        self.f7router.navigate.refreshPage();
      }
    },
    function (e) {}
  );
};

let checkChangeUrl = async (self) => {
  return new Promise(async (resolve) => {
    let existMiqrositio = await crudLocal.checkIfMiqrositioExist(
      self.uniquePageName,
      window.location.hostname
    );

    if (
      self.uniquePageName !== self.local.nombre_unico_local &&
      existMiqrositio
    ) {
      utils.toastConfig.text =
        "La URL elegida ya existe. Intente con una diferente.";
      utils.toastConfig.cssClass = "error-toast";
      f7.toast.create(utils.toastConfig).open();
      return;
    }

    if (self.uniquePageName !== self.local.nombre_unico_local) {
      f7.dialog.confirm(
        `Esta a punto de cambiar la URL de su local.
        Sus códigos QR actuales <strong>ya no serán válidos</strong> y deberá generarlos nuevamente.
        <br><br>
        ¿Desea Continuar?`,
        () => {
          self.props.formDataSettings["nombre_unico_local"] =
            self.uniquePageName;
          self.props.formDataSettings["url_local"] =
            "https://miqro.app/" + self.uniquePageName;
          resolve(true);
        }
      );
    } else {
      resolve(true);
    }
  });
};

let conectarMercadoPago = (self) => {
  f7.dialog.preloader("Redirigiendo a Mercado Pago");

  // let redirectUrlAdminMP = "https://devadmin.miqro.app"; // Development
  let redirectUrlAdminMP = "https://admin.miqro.app"; // Production

  window.open(
    `https://auth.mercadopago.com/authorization?client_id=2177763042071465&response_type=code&platform_id=mp&state=${self.local.name}&redirect_uri=${redirectUrlAdminMP}/api/method/linqr.linqr.doctype.utils.mp_integration_return_url`,
    "_self",
    "",
    true
  );
};

let deleteConnectionMercadoPago = (self) => {
  f7.dialog.preloader("Desconectando Mercado Pago");

  utils.axiosCall(
    "post",
    "linqr",
    "integracion_mercado_pago",
    "delete_integracion_mercado_pago",
    {},
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();

        crudLocal.getLocal(self);
      }

      f7.dialog.close();
    },
    function (e) {}
  );
};

let checkIntegracionInstagram = function (self) {
  utils.axiosCall(
    "post",
    "linqr",
    "integracion_instagram",
    "check_integracion_instagram",
    {},
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();

        self.props.disableSave = false;
        self.connectingWithInstagram = false;
        self.connectedWithInstagram = true;
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
      }
    },
    function (e) {}
  );
};

let connectToInstagram = () => {
  f7.dialog.preloader("Redireccionando a Instagram");
  // let base_url =
  //   "dev.miqro.app/api/method/linqr.linqr.doctype.integracion_instagram.integracion_instagram.get_instagram_access_token"; // Development
  let base_url =
    "admin.miqro.app/api/method/linqr.linqr.doctype.integracion_instagram.integracion_instagram.get_instagram_access_token"; // Production

  let full_url = encodeURI(
    `https://www.instagram.com/oauth/authorize` +
      `?app_id=1094239901020484` +
      `&redirect_uri=https://${base_url}` +
      `&scope=user_profile,user_media` +
      `&response_type=code` +
      `&state=${localStorage.getItem("token")}`
  );

  // window.open(full_url, "_blank");
  window.open(full_url, "_self", "", true);
};

let deleteConnectionInstagram = (self) => {
  f7.dialog.preloader("Desconectando Instagram");

  utils.axiosCall(
    "post",
    "linqr",
    "integracion_instagram",
    "delete_integracion_instagram",
    {},
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();

        crudLocal.getLocal(self);
      }

      f7.dialog.close();
    },
    function (e) {}
  );
};

export default {
  setValueForm,
  updateSettings,
  conectarMercadoPago,
  deleteConnectionMercadoPago,
  checkIntegracionInstagram,
  connectToInstagram,
  deleteConnectionInstagram,
};
