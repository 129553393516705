<template>
  <!-- ENLACE SIMPLE -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'enlace_simple'"
    class="item-enlace-simple link-item-list-preview"
    @click="registraEvento(bloque, 'click_bloque', true, bloque.enlace)"
    :style="
      'background: url(' +
      bloque.vista_previa_url +
      '); background-size: cover; background-position: center center; backdrop-filter: opacity(0%);'
    "
  >
    <div
      style="
        height: 90px;
        backdrop-filter: brightness(150%);
        border-radius: 8px;
        display: flex;
        place-content: center;
        flex-direction: column;
        width: 100%;
      "
      :style="
        bloque.vista_previa_url
          ? 'background: rgb(255 255 255 / 65%)'
          : 'background: rgb(255 255 255)'
      "
    >
      <a style="width: 100%">
        <f7-row
          class="
            display-flex
            text-align-center
            justify-content-center
            align-items-center
          "
        >
          <!-- <f7-col
                  v-if="bloque.vista_previa_url"
                  width="25"
                  class="text-align-left"
                  style="height: 60px"
                >
                  <img
                    :src="bloque.vista_previa_url"
                    alt=""
                    style="
                      width: 60px;
                      height: 60px;
                      object-fit: cover;
                      border-radius: 10px 0 0 10px;
                      margin-left: -8px;
                    "
                  />
                </f7-col> -->
          <!-- <f7-col :width="bloque.vista_previa_url ? '75' : '100'"> -->
          <f7-col width="100">
            <div class="item-title">{{ bloque.nombre_bloque }}</div>
          </f7-col>
        </f7-row>
      </a>
    </div>
  </div>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";

export default {
  props: {
    bloque: Object,
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
  },
};
</script>
