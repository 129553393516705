<template>
  <f7-page
    class="home-preview"
    name="home-local"
    @page:beforein="pageBeforeIn"
    @page:reinit="pageReinit"
  >
    <!-- SKELETON DE CARGA -->
    <skeletonHome :props="props" />

    <span
      v-show="props.loggedUser && !props.loading && !props.showPreview"
      class="badge edit-profile-badge"
      @click="navigateLocal"
    >
      <i class="fas fa-pen" style="color: #2e90cf"></i>
      &nbsp;&nbsp;Editar Perfil
    </span>

    <div class="home-container" v-show="!props.loading">
      <!-- NAVBAR FLOTANTE -->
      <!-- <FrontNavbarPreview v-if="loggedUser" :props="props" /> -->

      <!-- IMAGEN DE COVER -->
      <FrontImagenCover :props="props" :local="local" />

      <!-- IMAGEN DE PERFIL -->
      <FrontImagenPerfil :props="props" :local="local" />

      <!-- ICONOS SOCIALES -->
      <FrontIconosSociales :props="props" :f7router="f7router" />

      <!-- EMPTY STATE BLOQUES -->
      <emptyStateHome :props="props" :bloquesLocal="bloquesLocal" />

      <!-- LISTADO DE BLOQUES FINAL -->
      <listBloquesHome :f7router="f7router" :props="props" />

      <!-- FOOTER DE CONTACTO -->
      <footerContactHome :local="local" :props="props" />

      <!-- MARCA DE AGUA MIQROSITIO -->
      <footerWatermarkHome :props="props" />
    </div>

    <!-- FLOATING BUTTONS -->
    <template #fixed>
      <floatingButtonsHome :props="props" />
    </template>
  </f7-page>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
// import InstagramFeed from "../../js/instafeed";
import crudBloques from "../js/crud-bloques";
import crudLocal from "../js/crud-local";

import skeletonHome from "../components/home/skeleton-home.vue";
import FrontImagenPerfil from "../components/home/front-imagen-perfil.vue";
import FrontIconosSociales from "../components/home/front-iconos-sociales.vue";
import FrontNavbarPreview from "../components/home/front-navbar-preview.vue";
import FrontImagenCover from "../components/home/front-imagen-cover.vue";
import listBloquesHome from "../components/home/list-bloques-home.vue";
import footerContactHome from "../components/home/footer-contact-home.vue";
import footerWatermarkHome from "../components/home/footer-watermark-home.vue";
import emptyStateHome from "../components/home/empty-state-home.vue";
import floatingButtonsHome from "../components/home/floating-buttons-home.vue";

export default {
  components: {
    skeletonHome,
    FrontImagenPerfil,
    FrontIconosSociales,
    FrontNavbarPreview,
    FrontImagenCover,
    listBloquesHome,
    footerContactHome,
    footerWatermarkHome,
    emptyStateHome,
    floatingButtonsHome,
  },
  props: {
    f7router: Object,
    f7route: Object,
  },
  data() {
    return {
      props: {
        loading: false,
        formArchivos: {},
        showPreview: false,
        uploadingFiles: false,
        loggedUser: "",
        profileImageLoaded: false,
        coverImageLoaded: false,
      },
      instagramLoader: true,
    };
  },
  setup() {
    const bloquesLocal = useStore("bloquesLocal");
    const local = useStore("local");
    // const loggedUser = localStorage.getItem("token");

    return {
      bloquesLocal,
      local,
      // loggedUser,
    };
  },
  watch: {
    props: {
      handler(val) {
        setTimeout(() => {
          crudBloques.fitVideos();
        }, 1000);
      },
      deep: true,
    },
    local(val, oldVal) {
      const self = this;
      let pageContentPreview = f7.$("div.home-preview").find(".page-content");
      pageContentPreview.css("padding-bottom", "0px");

      if (self.props.loggedUser) {
        // crudBloques.getBloquesLocal(self);
        self.props.loading = false;
      } else {
        let navbarPreview = f7.$("div.home-preview").find(".navbar");
        navbarPreview.hide();
        pageContentPreview.css("padding-top", "0px");
      }

      if (val) {
        this.imagesPlaceholder();
      }
    },
  },
  methods: {
    pageBeforeIn() {
      const self = this;

      self.props.loggedUser = localStorage.getItem("token");
      self.props.loading = true;

      if (!self.props.loggedUser) {
        self.props.showPreview = true;
      }
      self.handleURLs();
      this.imagesPlaceholder();
      
    },
    pageReinit() {
    },
    async imagesPlaceholder() {
      const self = this;

      let elementsReady = await new Promise((resolve) => {
        if (self.local.imagen_perfil && self.local.imagen_cover) {
          if (
            document.querySelectorAll("#profile-image-front").length > 0 &&
            document.querySelectorAll("#cover-image-front").length > 0
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else if (self.local.imagen_perfil && !self.local.imagen_cover) {
          if (document.querySelectorAll("#profile-image-front").length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else if (!self.local.imagen_perfil && self.local.imagen_cover) {
          if (document.querySelectorAll("#cover-front").length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });

      if (elementsReady) {
        // console.log(document.querySelectorAll("#profile-image-front"));
        document.querySelectorAll("#profile-image-front").forEach((imageEl) => {
          if (imageEl.src) {
            self.props.profileImageLoaded = true;
          } else {
            imageEl.addEventListener("load", function () {
              self.props.profileImageLoaded = true;
            });
          }
        });

        // console.log(document.querySelectorAll("#cover-image-front"));
        document.querySelectorAll("#cover-image-front").forEach((imageEl) => {
          if (imageEl.src) {
            self.props.coverImageLoaded = true;
          } else {
            imageEl.addEventListener("load", function () {
              self.props.coverImageLoaded = true;
            });
          }
        });
      } else {
        setTimeout(() => {
          self.imagesPlaceholder();
        }, 250);
      }
    },
    navigateLocal() {
      const self = this;
      self.f7router.navigate(`/admin/local/`, {
        reloadCurrent: true,
      });
    },
    handleURLs() {
      const self = this;
      const currentPathName = window.location.pathname;

      if (currentPathName.startsWith("/admin")) {
        // self.handleURLAdmin();
      } else {
        self.props.loggedUser = false;
        const nombreUnicoLocal = window.location.pathname.replaceAll("/", "");
        const currentLocalURL = window.location.hostname;
        self.handleURLNoAdmin(nombreUnicoLocal, currentLocalURL);
      }
    },

    handleURLAdmin() {
      const self = this;

      self.f7router.navigate(`/admin`, {
        ignoreCache: true,
        realoadAll: true,
      });
    },

    handleURLNoAdmin(nombreUnicoLocal, currentLocalURL) {
      const self = this;

      crudLocal
        .checkIfMiqrositioExist(nombreUnicoLocal, currentLocalURL)
        .then((r) => {
          if (r.data.message.success === true) {
            crudLocal.getLocalIdByUrl(self, nombreUnicoLocal, currentLocalURL);
          } else {
            self.props.loading = false;
            self.f7router.navigate(`/no-encontrado`, {
              ignoreCache: true,
              realoadAll: true,
              props: {
                currentLocal: currentLocalURL,
              },
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
// checkDeviceDesktop() {
//   const self = this;

//   const desktop = f7.device.desktop;
//   return desktop;
// },
// convertStringIntoHtml(bloque) {
//   const self = this;

//   setTimeout(function () {
//     var el = f7.$(`#${CSS.escape(bloque.name)}`);
//     el[0].insertAdjacentHTML("afterbegin", bloque.editor_texto);
//   }, 1000);
// },
// extractLatLong(mapaUrl) {
//   const self = this;
//   const regexpLat = /\@(-?[\d\.]*)/;
//   const regexpLon = /\@[-?\d\.]*\,([-?\d\.]*)/;
//   const gMapsLat = parseFloat(mapaUrl.match(regexpLat)[1]);
//   const gMapsLon = parseFloat(mapaUrl.match(regexpLon)[1]);

//   return `geo:${gMapsLat},${gMapsLon}`;
// },
// getLocalIdByLocalName(currentLocalName) {
//   const self = this;
//   const frappeHost = useStore("frappeHost");

//   utils.axiosCall(
//     frappeHost.value,
//     "post",
//     "linqr",
//     "local",
//     "get_local_by_local_name",
//     {
//       local: currentLocalName,
//     },
//     {},
//     function (r) {
//       store.dispatch("setLocal", r.data.message.data);
//       crudBloques.getBloquesLocalPublic(self);
//       // f7.view.main.router.refreshPage();
//     },
//     function (e) {
//     }
//   );
// },
// openImagePopup(bloqueName) {
//   const self = this;
//   let popupEl = document.getElementById(bloqueName);
//   let popup = f7.popup.get(popupEl);
//   popup.open();
// },
// closeImagePopup(bloqueName) {
//   const self = this;
//   let popupEl = document.getElementById(bloqueName);
//   let popup = f7.popup.get(popupEl);
//   popup.close();
// },
</script>