<template>
  <f7-actions
    backdrop
    convert-to-popover
    container-el=".instagram-posts-container"
    target-el=".instagram-posts-container"
    :opened="props.actionSelectInstagramPostProduct"
    @actions:closed="props.actionSelectInstagramPostProduct = false"
  >
    <f7-actions-group
      class="padding text-align-center"
      style="background: #ffffff; text-align: center"
    >
      <f7-button
        fill
        rounded
        color="red"
        class="margin-bottom"
        @click="props.actionSelectInstagramPostProduct = false"
        ><span><i class="fas fa-times"></i>&nbsp;&nbsp;VOLVER</span></f7-button
      >
      <f7-row v-if="instagramPosts">
        <f7-col width="33" v-for="post in instagramPosts" :key="post.id">
          <a @click="instagramMediaSelected(post)">
            <img
              class=""
              style="
                object-fit: cover;
                width: -webkit-fill-available;
                border-radius: 4px;
              "
              :src="post.media_url"
              alt=""
            />
          </a>
        </f7-col>
      </f7-row>
      <f7-row v-if="instagramPosts.length === 0">
        <p style="width: 100%;" class="text-align-center">No existen publicaciones en tu cuenta de Instagram</p>
      </f7-row>
    </f7-actions-group>
  </f7-actions>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import utils from "../../../../js/utils";
import crudBloques from "../../../../js/crud-bloques";

export default {
  props: {
    props: Object,
  },
  setup() {
    const instagramPosts = useStore("instagramPosts");

    return {
      instagramPosts,
    };
  },
  methods: {
    instagramMediaSelected(post) {
      const self = this;
      f7.dialog.preloader("Cargando Post");

      utils.axiosCall(
        "post",
        "linqr",
        "bloque",
        "save_instagram_image_to_s3",
        {
          instagram_media: post.media_url,
          last_instagram_media: self.props.dataBloque.vista_previa_url,
        },
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          let presigned_url = r.data.message.presigned_url.url;
          let presigned_key = r.data.message.presigned_url.fields.key;

          self.props.preloadedImage = true;
          self.props.dataBloque.vista_previa_url =
            presigned_url + presigned_key;
          self.props.formDataBloque.vista_previa_url =
            presigned_url + presigned_key;
          self.props.dataBloque.texto = post.caption;
          self.props.formDataBloque.texto = post.caption;
          self.props.actionSelectInstagramPostProduct = false;

          if (self.props.dataBloque["name"]) {
            crudBloques.updateBloque(self, self.props.linkId);
            f7.dialog.close();
          } else {
            if (!self.props.formDataBloque["nombre_bloque"]) {
              self.props.formDataBloque["nombre_bloque"] = "Producto Instagram";
              self.props.dataBloque["nombre_bloque"] = "Producto Instagram";
            }
            crudBloques.createBloque(self).then((r) => {
              self.props.operation = "edit";
              f7.dialog.close();
            });
          }
        },
        function (e) {}
      );
    },
  },
};
</script>