<template>
  <f7-page class="links-page" name="links" @page:reinit="tabOnShow">
    <!-- <fullscreenLoader v-if="props.loading" /> -->

    <f7-block class="margin-top-half no-margin-bottom">
      <!-- HEADER -->
      <f7-row class="align-items-center">
        <f7-col width="70" class="display-flex justify-content-start">
          <h2>
            <i class="fas fa-layer-group"></i>
            &nbsp;&nbsp;Ordenar Bloques
          </h2>
        </f7-col>
        <!-- <f7-col
            width="15"
            class="display-flex flex-direction-column align-items-flex-end"
          >
            <f7-button
              class="btn-links btn-links-disabled"
              @click="openOnboardingSheet"
              ><i class="fas fa-question-circle"></i
            ></f7-button>
          </f7-col> -->
        <!-- <f7-col
          width="15"
          class="display-flex flex-direction-column align-items-flex-end"
        >
          <f7-button
            @click="props.actionAyudaVideoOpen = true"
            style="font-size: 18px; display: block"
            ><i class="fas fa-question"></i
          ></f7-button>
        </f7-col>
        <f7-col
          width="15"
          class="display-flex flex-direction-column align-items-flex-end"
        >
          <f7-button
            style="font-size: 20px; display: block"
            class=""
            href="/main-menu"
            ><i class="fas fa-bars"></i
          ></f7-button>
        </f7-col> -->
      </f7-row>

      <!-- MAIN BUTTONS -->
      <!-- <f7-row class="margin-vertical"> -->
      <!-- <f7-col class="display-flex justify-content-center">
            <a
              class="
                display-flex
                flex-direction-column
                justify-content-center
                text-align-center
                align-items-center
                btn-links-lg
                padding-vertical-half
              "
              @click="navigateToPreview"
            >
              <i class="fas fa-eye padding-top-half"></i>
              <span class="padding-top-half">Vista Previa</span>
            </a>
          </f7-col> -->
      <!-- <f7-col class="display-flex justify-content-center">
          <a
            class="display-flex flex-direction-column justify-content-center text-align-center align-items-center btn-links padding-vertical-half"
            href="/qr/"
          >
            <i class="fas fa-qrcode padding-top-half"></i>
            <span class="padding-top-half">Mi Código QR</span>
          </a>
        </f7-col> -->
      <!-- <f7-col class="display-flex justify-content-center">
            <a
              class="
                display-flex
                flex-direction-column
                justify-content-center
                text-align-center
                align-items-center
                btn-links-lg
                padding-vertical-half
              "
              href="#"
              @click="nativeShare"
            >
              <i class="fas fa-share-alt padding-top-half"></i>
              <span class="padding-top-half">Compartir</span>
            </a>
          </f7-col> -->
      <!-- </f7-row> -->

      <!-- BLOCKS -->
      <!-- <f7-row class="align-items-center"> -->
      <!-- NAVIGATION -->
      <!-- <f7-col width="70" class="display-flex"> -->
      <!-- <f7-col width="85" class="display-flex justify-content-start"> -->
      <!-- <h2>
                Mis Bloques
              </h2> -->
      <!-- </f7-col> -->
      <!-- </f7-col> -->
      <!-- <f7-col
            width="15"
            class="display-flex flex-direction-column align-items-flex-end"
          >
            <f7-button class="btn-links" href="#" @click="addLink"
              ><i class="fas fa-plus"></i
            ></f7-button>
          </f7-col> -->
      <!-- <f7-col
          width="30"
          class="display-flex flex-direction-column align-items-flex-end"
          v-if="bloquesLocal.length > 1"
        >
          <f7-button
            v-if="!sortLinksEnabled"
            class=""
            href="#"
            @click="sortLinksEnabled = true"
            ><span><i class="fas fa-layer-group"></i>&nbsp;&nbsp;Ordenar</span>
          </f7-button>
          <f7-button
            v-else-if="sortLinksEnabled"
            class=""
            href="#"
            @click="saveSortedLinks"
            ><span><i class="fas fa-save"></i>&nbsp;&nbsp;Guardar</span>
          </f7-button>
        </f7-col> -->
      <!-- </f7-row> -->
    </f7-block>

    <!-- SKELETON -->
    <f7-block
      v-show="props.loading"
      class="no-padding-horizontal margin text-align-center"
    >
      <!-- <f7-row>
        <f7-col class="display-flex justify-content-center">
          <div
            class="skeleton-effect-fade home-avatar-placeholder"
            style="background: #cececc;"
          >
          </div>
        </f7-col>
      </f7-row>
      <f7-row class="margin-top">
        <f7-col class="display-flex justify-content-center">
          <div
            class="skeleton-text skeleton-effect-fade"
          >
            TestTestTestTestTest
          </div>
        </f7-col>
      </f7-row> -->
      <f7-list
        no-hairlines
        no-hairlines-between
        class="
          skeleton-list
          margin-vertical-half
          skeleton-text skeleton-effect-fade
        "
        v-for="n in skeletonRows"
        :key="n"
        media-list
      >
        <f7-list-item
          class="skeleton-text skeleton-effect-fade link-item-list-preview"
        >
          TestTestTestTestTestTestTestTestTestTest
          TestTestTestTestTestTestTestTestTestTest
          TestTestTestTestTestTestTestTestTestTest
        </f7-list-item>
      </f7-list>
    </f7-block>

    <div v-if="!props.loading">
      <f7-block class="margin-top-half" style="margin-bottom: 70px">
        <!-- EMPTY STATE -->
        <f7-block
          v-if="bloquesLocal.length === 0"
          class="
            align-items-center
            text-empty-state
            justify-content-center
            no-padding no-margin-top
          "
        >
          <!-- <f7-row>
            <span>Aún no tienes bloques creados</span>
          </f7-row> -->
          <!-- <f7-row
            v-if="bloquesLocal.length === 0"
            class="align-items-center text-empty-state margin-vertical"
          >
            <f7-col>
              <f7-button href="#" @click="addLink" class="btn-agregar-bloque"
                ><span style="font-weight: bold"
                  ><i class="fas fa-plus"></i>&nbsp;&nbsp;Agregar Bloque</span
                ></f7-button
              >
            </f7-col>
          </f7-row> -->
        </f7-block>

        <!-- BLOCK LIST -->
        <!-- <f7-block
          class="no-padding no-margin-top"
          v-if="bloquesLocal.length > 0"
        >
          <f7-row class="align-items-center text-empty-state margin-vertical">
            <f7-col>
              <f7-button href="#" @click="addLink" class="btn-agregar-bloque"
                ><span style="font-weight: bold"
                  ><i class="fas fa-plus"></i>&nbsp;&nbsp;Agregar Bloque</span
                ></f7-button
              >
            </f7-col>
          </f7-row>
        </f7-block> -->
        <f7-list
          sortable
          :sortable-enabled="sortLinksEnabled"
          no-hairlines
          no-hairlines-between
          class="no-margin-vertical virtual-list-links"
          media-list
        >
          <!-- LIST ITEM -->
          <f7-list-item
            class="link-item-list margin-vertical"
            v-for="bloque in bloquesLocal"
            :key="bloque.name"
            :link="!disableBloque(bloque) ? `/link/${bloque.name}/` : ''"
            :reload-current="false"
            @click="showPremiumToast(bloque)"
            :data-bloque-id="bloque.name"
          >
            <template #media>
              <span class="display-flex" v-if="disableBloque(bloque)">
                <i :class="bloque.icono + ' ' + 'icon-gray'"></i>
              </span>
              <span class="display-flex" v-else>
                <i
                  v-if="bloque.habilitado == 1"
                  :class="bloque.icono + ' ' + 'icon-green'"
                ></i>
                <i
                  v-if="bloque.habilitado == 0"
                  :class="bloque.icono + ' ' + 'icon-red'"
                ></i>
              </span>
            </template>
            <template #title
              ><div class="padding-top-half">
                {{ bloque.nombre_bloque }}
              </div></template
            >
            <template #subtitle> {{ bloque.nombre_tipo_de_bloque }}</template>
          </f7-list-item>
        </f7-list>
      </f7-block>
    </div>
    <!-- <tabbar activePage="links" /> -->
    <!-- <f7-fab position="right-bottom" class="custom-fab" @click="addLink">
      <i class="fas fa-plus"></i>
      <i class="fas fa-times"></i> -->
    <!-- <f7-fab-buttons position="left">
        <f7-fab-button @click="navigateToPreview"
          ><i class="fas fa-eye"></i
        ></f7-fab-button>
        <f7-fab-button
          v-if="!sortLinksEnabled"
          class=""
          href="#"
          @click="sortLinksEnabled = true"
          ><i class="fas fa-layer-group"></i>
        </f7-fab-button>
        <f7-fab-button
          v-else-if="sortLinksEnabled"
          class=""
          href="#"
          @click="saveSortedLinks"
          ><i class="fas fa-save"></i>
        </f7-fab-button>
        <f7-fab-button @click="nativeShare"
          ><i class="fas fa-share-alt"></i
        ></f7-fab-button>
      </f7-fab-buttons> -->
    <!-- </f7-fab> -->

    <!-- <f7-fab
      v-if="!sortLinksEnabled"
      position="left-bottom"
      class="custom-fab"
      @click="sortLinksEnabled = true"
    >
      <i class="fas fa-layer-group"></i>
    </f7-fab> -->
    <f7-fab position="right-top" class="custom-fab" @click="navigateToHome">
      <i class="fas fa-rocket"></i>
    </f7-fab>
    <f7-fab
      v-if="sortLinksEnabled"
      position="right-bottom"
      class="custom-fab"
      @click="saveSortedLinks"
    >
      <i class="fas fa-save"></i>
    </f7-fab>

    <helpActionVideos
      :props="props"
      srcVideo=""
    />
  </f7-page>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
import store from "../js/store";
import utils from "../js/utils";
import crudBloques from "../js/crud-bloques";
import fullscreenLoader from "../components/fullscreen-loader-container";
import helpActionVideos from "../components/helps/help-actions-videos";
// import tabbar from "../components/tabbar.vue";

export default {
  props: {
    f7router: Object,
  },
  components: {
    fullscreenLoader,
    // tabbar,
    helpActionVideos,
  },
  data() {
    return {
      sortLinksEnabled: true,
      skeletonRows: 1,
      props: {
        actionAyudaVideoOpen: false,
        loading: false,
      },
    };
  },
  setup() {
    const bloquesLocal = useStore("bloquesLocal");
    const local = useStore("local");
    const suscripcion = useStore("suscripcion");

    return {
      bloquesLocal,
      local,
      suscripcion,
    };
  },
  beforeMount() {
    const self = this;
    if (localStorage.getItem("token")) {
      self.props.loading = true;
      crudBloques
        .getBloquesLocal(self)
        .then((r) => (self.props.loading = false));
    }
    self.skeletonRows = Math.floor((window.visualViewport.height * 0.75) / 100);
  },
  methods: {
    openOnboardingSheet() {
      const self = this;

      store.dispatch("setOpenOnboardingSheet", true);
    },
    navigateToPreview() {
      const self = this;

      f7.views.main.router.navigate(`/admin/`, {
        ignoreCache: true,
        realoadAll: true,
      });
    },
    addLink() {
      f7.views.main.router.navigate("/admin/new-link-type/");
    },
    getLink(id) {
      f7.views.main.router.navigate(`/admin/new-link/${id}`);
    },
    saveSortedLinks(e) {
      const self = this;
      f7.dialog.preloader("Guardando Orden");

      crudBloques.saveSortedLinks(self, "", "", "order-bloques").then((r) => {
        setTimeout(() => {
          self.f7router.navigate("/admin", {
            reloadCurrent: true,
          });
          f7.dialog.close();
        }, 500);
      });

      // setTimeout(() => {
      //   f7.views.main.router.navigate(`/admin/${self.local.nombre_unico_local}/`, {
      //   ignoreCache: false,
      //   realoadAll: false,
      // });
      // }, 1000);
    },
    nativeShare() {
      const self = this;
      const localCap = "";

      if (self.local.nombre !== undefined) {
        localCap =
          self.local.nombre.charAt(0).toUpperCase() +
          self.local.nombre.slice(1);
      }

      if (navigator.share) {
        navigator
          .share({
            title: `${localCap}`,
            text: `${self.local.nombre_local} 👉`,
            url: self.local.url_local,
          })
          .then(() => "")
          .catch((error) => console.log(error));
      } else {
        f7.views.main.router.navigate("/admin/share/");
      }
    },
    tabOnShow() {
      const self = this;

      if (localStorage.getItem("token")) {
        self.props.loading = true;
        store.dispatch("setBloquesLocal", []);
        crudBloques.getBloquesLocal(self);
      }
    },
    disableBloque(bloque) {
      const self = this;

      if (
        self.suscripcion.nombre_suscripcion_actual === "Gratuito" &&
        bloque.suscripcion_de_bloque === "Pro"
      ) {
        return true;
      } else {
        return false;
      }
    },
    showPremiumToast(bloque) {
      const self = this;

      if (self.disableBloque(bloque)) {
        utils.toastConfig.text = `👑 Este bloque no es gratuito.<br><a href='/local/'>¡Sube de Plan!</a>`;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
      }
    },
    navigateToHome() {
      const self = this;
      self.f7router.navigate("/admin", {
        reloadCurrent: true,
      });
    },
  },
};
</script>
