<template>
  <!-- Video -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'video'"
    class="item-video link-item-list-preview"
    @click="registraEvento(bloque, 'click_bloque')"
  >
    <iframe type="text/html" :src="bloque.video_url" frameborder="0"></iframe>
  </div>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";

export default {
  props: {
    bloque: Object,
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
  },
};
</script>
