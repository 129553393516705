<template>
  <f7-page @page:afterin="pageAfterIn">
    <f7-page-content>
      <div>
        <div style="margin-bottom: 70px">
          <adminNavbar
            :f7router="f7router"
            pageName="Mi Código QR"
            srcHelpVideo=""
          />

          <div class="margin">
            <f7-block
              :id="customQRSize"
              class="printme qr-display no-padding no-margin"
              :style="`border: 2px solid ${customQRDotColor}; border-radius: 10px`"
            >
              <f7-row
                v-if="customQRText !== ''"
                class="justify-content-center qr-template-padding-element"
              >
                <span class="qr-display-text text-align-center">
                  {{ customQRText }}
                </span>
              </f7-row>
              <f7-row
                class="justify-content-center qr-template-padding-element"
              >
                <div
                  v-show="loadingQR"
                  class="
                    qrcode-loader
                    flex-direction-column
                    align-items-center
                    justify-content-center
                  "
                >
                  <i
                    class="fas fa-circle-notch fa-spin"
                    style="font-size: 38px"
                  ></i>
                  <br />
                  <span>Cargando Código QR</span>
                </div>
                <div
                  v-show="!loading"
                  id="qrcode"
                  style="page-break-inside: avoid"
                ></div>
              </f7-row>
              <f7-row
                class="
                  justify-content-center
                  qr-template-padding-element
                  no-padding-horizontal
                "
              >
                <!-- <div class="qr-display-logo-linqr">
                Realizado con miqro.app
              </div> -->
              </f7-row>
              <f7-row
                v-if="customQRSubText"
                class="justify-content-center align-items-center"
                style="border: 0px solid #ffffff"
              >
                <div
                  :style="`
                background-color: ${customQRDotColor};
                border-radius: 0 0 5px 5px;
                overflow: hidden;
                width: 100%;
                position: relative;
                -webkit-print-color-adjust: exact;
                border: 0px solid #ffffff
                ;padding: 10px 0;
                `"
                >
                  <span class="scanme-qr-template"
                    ><span
                      style="
                        margin-top: 5px !important;
                        padding: 0 5px;
                        width: 100%;
                      "
                      class="text-align-center qr-template-padding-element"
                      >{{ customQRSubText }}</span
                    ></span
                  >
                </div>
              </f7-row>
            </f7-block>
          </div>

          <actionDownloadQr :props="props" :local="local" />
          <actionShareQr :props="props" :local="local" />
          <actionPrintQr :props="props" :local="local" />
          <actionSizePrintQr :props="props" :local="local" />
        </div>
      </div>
    </f7-page-content>

    <fabCustomizeQrSheet />
    <fabExportQrCode :props="props" :local="local" />
  </f7-page>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import qr from "../js/qr";

import adminNavbar from "../components/admin-navbar";
import fabCustomizeQrSheet from "../components/qr/fab-customize-qr-sheet";
import fabExportQrCode from "../components/qr/fab-export-qr-code";
import actionShareQr from "../components/qr/action-share-qr";
import actionPrintQr from "../components/qr/action-print-qr";
import actionDownloadQr from "../components/qr/action-download-qr";
import actionSizePrintQr from "../components/qr/action-size-print-qr";

export default {
  props: {
    f7router: Object,
  },
  components: {
    adminNavbar,
    fabCustomizeQrSheet,
    fabExportQrCode,
    actionShareQr,
    actionSizePrintQr,
    actionPrintQr,
    actionDownloadQr,
  },
  data() {
    return {
      props: {
        actionsPrintQR: false,
        actionSelectPrintSizeOpen: false,
        actionsDownloadQR: false,
        printPopoverOpened: false,
        actionsShareQR: false,
        templateQRFileURL: "",
        qRFileURL: "",
        printTemplate: false,
        printQRCode: false,
      },
      displayText: "",
      loading: true,
      loadingQR: true,
    };
  },
  setup() {
    const self = this;
    const customQR = useStore("customQR");
    const customQRStyle = useStore("customQRStyle");
    const customQRText = useStore("customQRText");
    const customQRSubText = useStore("customQRSubText");
    const customQRImage = useStore("customQRImage");
    const customQRSize = useStore("customQRSize");
    const customQRDotColor = useStore("customQRDotColor");
    const customQRCornerColor = useStore("customQRCornerColor");
    const customQRDotCornerColor = useStore("customQRDotCornerColor");
    const local = useStore("local");

    return {
      customQR,
      customQRStyle,
      customQRText,
      customQRSubText,
      customQRImage,
      customQRSize,
      local,
      customQRDotColor,
      customQRCornerColor,
      customQRDotCornerColor,
    };
  },
  methods: {
    pageAfterIn() {
      const self = this;

      setTimeout(() => {
        self.getCustomQRCode();

        let observer = new MutationObserver((mutationRecords) => {
          self.loadingQR = false;
        });

        // observa todo exceptuando atributos
        let qrElement = document.getElementById("qrcode");

        observer.observe(qrElement, {
          childList: true, // observa hijos directos
          subtree: false, // y descendientes inferiores también
          characterDataOldValue: true, // pasa el dato viejo al callback
        });
      }, 500);
    },
    getCustomQRCode() {
      const self = this;
      qr.getCustomQRCode(self);
    },
    buildQRCode() {
      const self = this;
      qr.buildQRCode(self);
    },
  },
};
</script>
