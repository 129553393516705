<template>
  <f7-fab-backdrop></f7-fab-backdrop>
  <f7-fab position="right-bottom" class="custom-fab no-printme">
    <i class="fas fa-share-alt"></i>
    <i class="fas fa-times"></i>
    <f7-fab-buttons position="left">
      <f7-fab-button @click="props.actionsPrintQR = true"
        ><i class="fas fa-print"></i
      ></f7-fab-button>
      <f7-fab-button @click="openActionsDownloadQR"
        ><i class="fas fa-download"></i
      ></f7-fab-button>
      <f7-fab-button
        @click="openActionsShareQR"
        v-if="isMobile() && androidChrome()"
        ><i class="fas fa-share-alt"></i
      ></f7-fab-button>
    </f7-fab-buttons>
  </f7-fab>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import qr from "../../js/qr";

export default {
  props: {
    props: Object,
    local: Object,
  },
  methods: {
    async openActionsDownloadQR() {
      const self = this;
      f7.dialog.preloader("Generando Código QR");
      qr.generateQrFile(
        self,
        f7.$(".qr-display"),
        "templateQRFileURL",
        `${self.local.nombre_unico_local} Plantilla QR.png`,
        "#download-qrcode-template",
        "download"
      ).then(() => {
        qr.generateQrFile(
          self,
          f7.$("#qrcode").find("canvas"),
          "qRFileURL",
          `${self.local.nombre_unico_local} Código QR.png`,
          "#download-qrcode",
          "download"
        ).then(() => {
          f7.dialog.close();
          self.props.actionsDownloadQR = true;
        });
      });
    },
    async openActionsShareQR() {
      const self = this;
      f7.dialog.preloader("Generando Código QR");

      qr.generateQrFile(
        self,
        f7.$(".qr-display"),
        "templateQRFileURL",
        `${self.local.nombre_unico_local} Plantilla QR.png`,
        undefined,
        "share"
      ).then(() => {
        qr.generateQrFile(
          self,
          f7.$("#qrcode").find("canvas"),
          "qRFileURL",
          `${self.local.nombre_unico_local} Código QR.png`,
          undefined,
          "share"
        ).then(() => {
          f7.dialog.close();
          self.props.actionsShareQR = true;
        });
      });
    },
    isMobile() {
      return f7.device.desktop === false;
    },
    androidChrome() {
      // return (f7.device.androidChrome === true);
      return true;
    },
  },
};
</script>