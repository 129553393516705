<template>
  <f7-page
    class="settings-profile"
    name="settings-profile"
    @page:beforein="pageBeforeIn"
  >
    <!-- <fullscreenLoader v-if="loading" /> -->
    <div>
      <div style="margin-bottom: 70px">
        <f7-block v-if="!loading" class="margin-top-half no-margin-bottom">
          <f7-row class="align-items-center">
            <f7-col width="70" class="display-flex justify-content-start">
              <h2>
                <i class="fas fa-store"></i>
                &nbsp;&nbsp;Mi Perfil
              </h2>
            </f7-col>
            <!-- <f7-col
              width="15"
              class="display-flex flex-direction-column align-items-flex-end"
            >
              <f7-button
                @click="props.actionAyudaVideoOpen = true"
                style="font-size: 18px; display: block"
                ><i class="fas fa-question"></i
              ></f7-button>
            </f7-col> -->
            <!-- <f7-col
              width="30"
              class="display-flex flex-direction-column align-items-flex-end"
            >
              <f7-button
                class=""
                style="font-size: 20px; display: block"
                href="/main-menu"
                ><i class="fas fa-bars"></i
              ></f7-button>
            </f7-col> -->
          </f7-row>
        </f7-block>
        <!-- LISTADO DE CAMPOS -->
        <f7-block v-if="!loading" class="no-padding-horizontal no-margin">
          <f7-list
            no-hairlines
            no-hairlines-between
            class="margin-vertical-half virtual-list-links"
            form
            id="profile-form"
            autocomplete="off"
          >
            <!-- Imagen de Cover -->
            <li>
              <div class="item-content item-input item-input-outline no-margin">
                <div class="item-inner">
                  <!-- <div class="item-title item-label">Imagen de Perfil</div> -->
                  <div class="item-input-wrap item-input-wrap-uppy">
                    <!-- DASHBOARD DE UPPY -->
                    <div
                      v-show="!props.dataSettings.imagen_cover"
                      class="DashboardContainerProfileCover"
                    ></div>
                    <!-- IMAGEN/ARCHIVO PRECARGADO -->
                    <div
                      v-show="props.dataSettings.imagen_cover"
                      class="link-item-preview-settings-cover"
                    >
                      <!-- IMAGEN -->
                      <div
                        v-show="!props.coverImageLoaded"
                        class="link-settings-preview-image-cover"
                        style="background-color: #f1f4f7; height: 125px"
                      >
                        <span class="spinner-placeholder-right-top">
                          <i class="fa fa-spinner fa-spin"></i
                        ></span>
                      </div>
                      <img
                        v-show="props.coverImageLoaded"
                        id="cover-image"
                        :src="props.dataSettings.imagen_cover"
                        class="link-settings-preview-image-cover"
                      />
                      <div
                        v-show="props.coverImageLoaded"
                        class="link-item-remove-preview-image-cover"
                        @click="deleteImage('cover')"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <!-- Imagen de Perfil -->
            <li>
              <div class="item-content item-input item-input-outline no-margin">
                <div class="item-inner">
                  <!-- <div class="item-title item-label">Imagen de Perfil</div> -->
                  <div class="item-input-wrap item-input-wrap-uppy">
                    <!-- DASHBOARD DE UPPY -->
                    <div
                      v-show="!props.dataSettings.imagen_perfil"
                      class="DashboardContainerProfile"
                    ></div>
                    <!-- IMAGEN/ARCHIVO PRECARGADO -->
                    <div
                      :style="
                        props.dataSettings.imagen_cover
                          ? 'margin-top: -85px'
                          : null
                      "
                      v-show="props.dataSettings.imagen_perfil"
                      class="link-item-preview-settings"
                    >
                      <!-- IMAGEN -->
                      <div
                        v-show="!props.profileImageLoaded"
                        class="link-settings-preview-image"
                        style="background-color: #e5eaef"
                      >
                        <span class="spinner-placeholder-center">
                          <i class="fa fa-spinner fa-spin"></i
                        ></span>
                      </div>
                      <img
                        v-show="props.profileImageLoaded"
                        id="profile-image"
                        :src="props.dataSettings.imagen_perfil"
                        class="link-settings-preview-image"
                      />
                      <div
                        v-show="props.coverImageLoaded"
                        class="link-item-remove-preview-image"
                        @click="deleteImage('perfil')"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- Datos Generales -->
            <f7-list-input
              outline
              id="input_nombre_local"
              label="Nombre del Negocio"
              type="text"
              name="nombre_local"
              @input="setValueForm"
              :value="props.dataSettings.nombre_local"
              @click="
                (e) => {
                  emojiElementSelect(e, 'input');
                }
              "
            >
            </f7-list-input>
            <f7-list-input
              v-if="props.dataSettings.dominio_propio === 1"
              class="no-margin-horizontal"
              outline
              label="Tu Propio Dominio"
              type="text"
              name="url_local"
              :value="props.dataSettings.url_local"
              validate
              disabled
              @input="setValueForm"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            >
            </f7-list-input>
            <f7-list-input
              v-if="props.dataSettings.url_local === 0"
              class="no-margin-horizontal"
              outline
              label="Tu URL"
              type="text"
              name="url_local"
              v-model:value="uniquePageName"
              validate
              @input="
                (e) => {
                  uniquePageNameMiddleware = e.currentTarget.value;
                }
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            >
              <template #content-start>
                <span class="url-local-form">miqro.app/</span></template
              >
            </f7-list-input>
            <!-- <f7-list-input
              outline
              label="Link del Negocio"
              type="text"
              name="url_local"
              @input="setValueForm"
              :value="props.dataSettings.url_local"
            ></f7-list-input> -->
            <f7-list-input
              outline
              id="textarea_informacion_local"
              label="Bio"
              type="textarea"
              name="informacion_local"
              @input="setValueForm"
              :value="props.dataSettings.informacion_local"
              @click="
                (e) => {
                  emojiElementSelect(e, 'textarea');
                }
              "
            >
            </f7-list-input>

            <!-- Redes Sociales -->
            <li class="list-item-divider-custom">
              <div class="item-content">
                <div class="item-inner">
                  <span>
                    <i class="fab fa-instagram"></i>&nbsp;&nbsp;
                    <span>Redes Sociales</span>
                  </span>
                </div>
              </div>
            </li>
            <f7-list-input
              outline
              label="Instagram"
              type="text"
              name="instagram"
              class="social-media-inputs"
              @input="(e) => setValueForm(e, null, { child: 'rrss' })"
              :value="
                props.dataSettings.rrss && props.dataSettings.rrss.instagram
                  ? props.dataSettings.rrss.instagram.usuario_red_social
                  : ''
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Facebook"
              type="text"
              name="facebook"
              class="social-media-inputs"
              @input="(e) => setValueForm(e, null, { child: 'rrss' })"
              :value="
                props.dataSettings.rrss && props.dataSettings.rrss.facebook
                  ? props.dataSettings.rrss.facebook.usuario_red_social
                  : ''
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Twitter"
              type="text"
              name="twitter"
              class="social-media-inputs"
              @input="(e) => setValueForm(e, null, { child: 'rrss' })"
              :value="
                props.dataSettings.rrss && props.dataSettings.rrss.twitter
                  ? props.dataSettings.rrss.twitter.usuario_red_social
                  : ''
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Youtube"
              type="text"
              name="youtube"
              class="social-media-inputs"
              @input="(e) => setValueForm(e, null, { child: 'rrss' })"
              :value="
                props.dataSettings.rrss && props.dataSettings.rrss.youtube
                  ? props.dataSettings.rrss.youtube.usuario_red_social
                  : ''
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="TikTok"
              type="text"
              name="tiktok"
              class="social-media-inputs"
              @input="(e) => setValueForm(e, null, { child: 'rrss' })"
              :value="
                props.dataSettings.rrss && props.dataSettings.rrss.tiktok
                  ? props.dataSettings.rrss.tiktok.usuario_red_social
                  : ''
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Linked In"
              type="text"
              name="linkedin"
              class="social-media-inputs"
              @input="(e) => setValueForm(e, null, { child: 'rrss' })"
              :value="
                props.dataSettings.rrss && props.dataSettings.rrss.linkedin
                  ? props.dataSettings.rrss.linkedin.usuario_red_social
                  : ''
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Behance"
              type="text"
              name="behance"
              class="social-media-inputs"
              @input="(e) => setValueForm(e, null, { child: 'rrss' })"
              :value="
                props.dataSettings.rrss && props.dataSettings.rrss.behance
                  ? props.dataSettings.rrss.behance.usuario_red_social
                  : ''
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Dribbble"
              type="text"
              name="dribbble"
              class="social-media-inputs"
              @input="(e) => setValueForm(e, null, { child: 'rrss' })"
              :value="
                props.dataSettings.rrss && props.dataSettings.rrss.dribbble
                  ? props.dataSettings.rrss.dribbble.usuario_red_social
                  : ''
              "
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>

            <!-- Datos de Contacto -->
            <li class="list-item-divider-custom">
              <div class="item-content">
                <div class="item-inner">
                  <span>
                    <i class="fab fa-whatsapp"></i>&nbsp;&nbsp;
                    <span>Datos de Contacto</span>
                  </span>
                </div>
              </div>
            </li>
            <f7-list-input
              outline
              label="WhatsApp"
              type="text"
              name="whatsapp"
              @input="setValueForm"
              :value="props.dataSettings.whatsapp"
              validate
              pattern="[0-9]*"
              error-message="Ingresa un número válido"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Sitio Web"
              type="text"
              name="sitio_web"
              class="social-media-inputs"
              @input="setValueForm"
              :value="props.dataSettings.sitio_web"
              validate
              pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
              error-message="Ingresa una dirección web válida"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Correo Electrónico"
              type="email"
              name="email"
              class="social-media-inputs"
              validate
              error-message="Ingresa un email válido"
              @input="setValueForm"
              :value="props.dataSettings.email"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Teléfono"
              type="text"
              name="telefono_fijo"
              @input="setValueForm"
              :value="props.dataSettings.telefono_fijo"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>

            <!-- Dirección -->
            <li class="list-item-divider-custom">
              <div class="item-content">
                <div class="item-inner">
                  <span>
                    <i class="fas fa-map"></i>&nbsp;&nbsp;
                    <span>Dirección</span>
                  </span>
                </div>
              </div>
            </li>
            <f7-list-input
              outline
              label="Dirección"
              type="text"
              name="direccion_1"
              @input="setValueForm"
              :value="props.dataSettings.direccion_1"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Dirección (Más Info)"
              type="text"
              name="direccion_2"
              @input="setValueForm"
              :value="props.dataSettings.direccion_2"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Localidad"
              type="text"
              name="localidad"
              @input="setValueForm"
              :value="props.dataSettings.localidad"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Codigo Postal"
              type="text"
              name="codigo_postal"
              @input="setValueForm"
              :value="props.dataSettings.codigo_postal"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="Provincia"
              type="text"
              name="provincia"
              @input="setValueForm"
              :value="props.dataSettings.provincia"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>
            <f7-list-input
              outline
              label="País"
              type="text"
              name="pais"
              @input="setValueForm"
              :value="props.dataSettings.pais"
              @click="
                (e) => {
                  emojiElementDeselect();
                }
              "
            ></f7-list-input>

            <!-- Integraciones -->
            <li
              class="list-item-divider-custom"
              v-if="suscripcion.nombre_suscripcion_actual === 'Pro'"
            >
              <div class="item-content">
                <div class="item-inner">
                  <span>
                    <i class="fas fa-arrows-alt-h"></i>&nbsp;&nbsp;
                    <span>Integraciones</span>
                  </span>
                </div>
              </div>
            </li>
            <li v-if="suscripcion.nombre_suscripcion_actual === 'Pro'">
              <f7-list-input
                outline
                label="Código Google Analytics"
                type="text"
                name="codigo_google_analytics"
                @input="setValueForm"
                :value="props.dataSettings.codigo_google_analytics"
                @click="
                  (e) => {
                    emojiElementDeselect();
                  }
                "
              ></f7-list-input>

              <f7-list-input
                outline
                label="Código Google Tag Manager"
                type="text"
                name="codigo_gtm"
                @input="setValueForm"
                :value="props.dataSettings.codigo_gtm"
                @click="
                  (e) => {
                    emojiElementDeselect();
                  }
                "
              ></f7-list-input>

              <br />

              <div
                class="item-content item-input item-input-outline no-margin"
                style="width: 100%"
              >
                <div class="item-inner">
                  <!-- <div class="item-title item-label">Instagram</div> -->
                  <!-- <div class="item-input-wrap"> -->
                  <f7-button
                    v-show="connectedWithInstagram === false"
                    @click="conectarInstagram"
                    fill
                    style="width: 100%"
                    >Conectar con Instagram</f7-button
                  >
                  <!-- <f7-button v-show="connectedWithInstagram === true" disabled
                    >Cuenta Conectada</f7-button
                  > -->
                  <f7-button
                    color="red"
                    v-show="connectedWithInstagram === true"
                    @click="deleteConnectionInstagram"
                    fill
                    style="width: 100%"
                    >Desconectar Instagram</f7-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </li>
            <li v-if="suscripcion.nombre_suscripcion_actual === 'Pro'">
              <div
                class="item-content item-input item-input-outline no-margin"
                style="width: 100%"
              >
                <div class="item-inner">
                  <!-- <div class="item-title item-label">Mercado Pago</div> -->
                  <!-- <div class="item-input-wrap"> -->
                  <f7-button
                    v-show="connectedWithMercadoPago === false"
                    @click="conectarMercadoPago"
                    fill
                    style="width: 100%"
                    >Conectar con Mercado Pago</f7-button
                  >
                  <!-- <f7-button v-show="connectedWithMercadoPago === true" disabled
                    >Cuenta Conectada</f7-button
                  > -->
                  <f7-button
                    color="red"
                    v-show="connectedWithMercadoPago === true"
                    @click="deleteConnectionMercadoPago"
                    fill
                    style="width: 100%"
                    >Desconectar Mercado Pago</f7-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </li>

            <f7-list-item>
              <!-- <f7-row style="width: 100%" class="margin-top">
                <f7-col
                  ><f7-button fill color="red" @click="signOut"
                    ><i class="fas fa-sign-out-alt"></i>&nbsp;&nbsp;Cerrar
                    Sesión</f7-button
                  ></f7-col
                >
              </f7-row> -->
            </f7-list-item>

            <!-- <f7-list-item accordion-item>
          <template #title>
            <span>
              <i class="fas fa-clock"></i>&nbsp;&nbsp;
              <span>Horarios</span>
            </span>
          </template>
          <f7-accordion-content> </f7-accordion-content>
        </f7-list-item> -->
          </f7-list>
        </f7-block>
      </div>
    </div>
    <f7-fab
      position="right-top"
      class="custom-fab"
      :class="props.uploadingFiles === true ? 'custom-fab-disabled' : null"
      @click="
        props.uploadingFiles === true
          ? null
          : checkDirtyFormOnExit(navigateToHome, true)
      "
    >
      <i class="fas fa-rocket"></i>
    </f7-fab>
    <f7-fab
      position="right-bottom"
      class="custom-fab"
      :class="props.uploadingFiles === true ? 'custom-fab-disabled' : null"
      @click="props.uploadingFiles === true ? null : updateSettings()"
    >
      <i class="fas fa-save"></i>
    </f7-fab>
    <!-- <tabbar activePage="settings-profile" /> -->

    <helpActionVideos :props="props" srcVideo="" />

    <!-- <f7-fab
      v-if="emojiElementSelected != ''"
      position="right-bottom"
      class="custom-fab"
      @click="triggerEmojiPicker"
      style="bottom: 75px;"
    >
      <i class="fas fa-smile"></i>
    </f7-fab> -->
  </f7-page>
  <fabEmojiPicker />
</template>

<script>
// import tabbar from "../components/tabbar";
import { f7, useStore } from "framework7-vue";
import crudSettings from "../js/crud-settings";
import utils from "../js/utils";
import fileUploader from "../js/file-uploader";
import fullscreenLoader from "../components/fullscreen-loader-container";
import helpActionVideos from "../components/helps/help-actions-videos";
import store from "../js/store";
import fabEmojiPicker from "../components/fab-emoji-picker.vue";

export default {
  components: {
    // tabbar,
    fullscreenLoader,
    helpActionVideos,
    fabEmojiPicker,
  },
  props: {
    f7route: Object,
    f7router: Object,
  },
  data() {
    return {
      uniquePageNameMiddleware: "",
      loading: true,
      props: {
        formDataSettings: {},
        dataSettings: {},
        actionAyudaVideoOpen: false,
        currentInputSel: "",
        inputSelectionStart: null,
        uploadingFiles: false,
        coverImageLoaded: false,
        profileImageLoaded: false,
        dirtyForm: false,
      },
    };
  },
  computed: {
    uniquePageName: function () {
      let nombre_local_sanitized = this.uniquePageNameMiddleware
        .replace(/[`\s~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        .replace(/[ñ]/gi, "n")
        .replace(/[á]/gi, "a")
        .replace(/[é]/gi, "e")
        .replace(/[í]/gi, "i")
        .replace(/[ó]/gi, "o")
        .replace(/[ú]/gi, "u")
        .replace(/[ü]/gi, "u")
        .toLowerCase();

      // this.props.formDataSettings["nombre_unico_local"] = nombre_local_sanitized;
      // this.props.formDataSettings["url_local"] = "https://miqro.app/" + nombre_local_sanitized;
      // this.props.dataSettings["nombre_unico_local"] = nombre_local_sanitized;
      // this.props.dataSettings["url_local"] = "https://miqro.app/" + nombre_local_sanitized;

      return nombre_local_sanitized;
    },
    // connectingWithInstagram() {
    //   if (this.local.integracion_instagram) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    connectedWithInstagram() {
      if (this.local.integracion_instagram) {
        return true;
      } else {
        return false;
      }
    },
    // connectingWithMercadoPago() {
    //   if (this.local.integracion_mercado_pago) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    connectedWithMercadoPago() {
      if (this.local.integracion_mercado_pago) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    const local = useStore("local");
    const suscripcion = useStore("suscripcion");
    const emojiPicker = useStore("emojiPicker");
    const frappeHost = useStore("frappeHost");
    const tipoEmoji = "settings";
    const emojiElementSelected = useStore("emojiElementSelected");

    return {
      local,
      emojiPicker,
      suscripcion,
      frappeHost,
      tipoEmoji,
      emojiElementSelected,
    };
  },
  mounted() {
    const self = this;
    utils.loadPickerWidget(self);
  },
  watch: {
    local(val, oldVal) {
      this.uniquePageNameMiddleware = this.local.nombre_unico_local || "";
      this.props.formDataSettings = this.local;
      this.props.dataSettings = this.local;
      this.loading = false;
      if (val) {
        this.imagesPlaceholder();
      }
    },
  },
  methods: {
    setValueForm(e, radioValue, options) {
      const self = this;
      self.props.dirtyForm = true;
      crudSettings.setValueForm(self, e, radioValue, options);
    },
    async updateSettings(redirect = true) {
      const self = this;

      let invalidFields = document.querySelectorAll("input.input-invalid");

      return new Promise((resolve) => {
        if (invalidFields.length > 0) {
          utils.toastConfig.text =
            "No se guardarons los cambios. Algunos de los campos contienen errores";
          utils.toastConfig.cssClass = "error-toast";
          f7.toast.create(utils.toastConfig).open();
          resolve();
        } else {
          self.emojiElementDeselect();
          self.emojiPicker.hidePicker();

          crudSettings.updateSettings(self, redirect);
          self.imagesPlaceholder();
          resolve();
        }
      });
    },
    pageBeforeIn() {
      const self = this;
      self.props.formDataSettings = self.local;
      self.props.dataSettings = self.local;
      setTimeout(() => {
        self.loading = false;
      }, 1000);

      fileUploader.uppyConfigProfile(self);
      fileUploader.uppyConfigProfileCover(self);
    },
    async imagesPlaceholder() {
      const self = this;
      let elementsReady = await new Promise((resolve) => {
        if (
          self.props.formDataSettings.imagen_perfil &&
          self.props.formDataSettings.imagen_cover
        ) {
          if (
            document.querySelectorAll("#profile-image").length > 0 &&
            document.querySelectorAll("#cover-image").length > 0
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else if (
          self.props.formDataSettings.imagen_perfil &&
          !self.props.formDataSettings.imagen_cover
        ) {
          if (document.querySelectorAll("#profile-image").length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else if (
          !self.props.formDataSettings.imagen_perfil &&
          self.props.formDataSettings.imagen_cover
        ) {
          if (document.querySelectorAll("#cover-image").length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });

      if (elementsReady) {
        // console.log(document.querySelectorAll("#profile-image"));
        document.querySelectorAll("#profile-image").forEach((imageEl) => {
          if (imageEl.src) {
            self.props.profileImageLoaded = true;
          } else {
            imageEl.addEventListener("load", function () {
              self.props.profileImageLoaded = true;
            });
          }
        });

        // console.log(document.querySelectorAll("#cover-image"));
        document.querySelectorAll("#cover-image").forEach((imageEl) => {
          if (imageEl.src) {
            self.props.coverImageLoaded = true;
          } else {
            imageEl.addEventListener("load", function () {
              self.props.coverImageLoaded = true;
            });
          }
        });
      } else {
        setTimeout(() => {
          self.imagesPlaceholder();
        }, 250);
      }
    },
    async deleteImage(imageField) {
      const self = this;

      let imageToDelete = await new Promise((resolve) => {
        if (imageField === "perfil") {
          resolve(self.props.dataSettings.imagen_perfil);
          self.props.dataSettings.imagen_perfil = "";
        } else if (imageField === "cover") {
          resolve(self.props.dataSettings.imagen_cover);
          self.props.dataSettings.imagen_cover = "";
        }
      });

      utils.axiosCall(
        "post",
        "linqr",
        "local",
        "remove_file_from_profile",
        {
          image_field: imageField,
          file_name: imageToDelete,
        },
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          // utils.toastConfig.text = "Se ha modificado su perfil";
          // utils.toastConfig.cssClass = "success-toast";
          // f7.toast.create(utils.toastConfig).open();
        },
        function (e) {}
      );

      crudSettings.updateSettings(self, false).then((r) => {
        f7.dialog.close();
      });
      // self.f7router.refreshPage();
    },
    conectarInstagram() {
      const self = this;
      // self.connectingWithInstagram = true;
      let connectToInstagramCallback = () => {
        crudSettings.connectToInstagram(self);
      };
      self.checkDirtyFormOnExit(connectToInstagramCallback, false);
    },
    deleteConnectionInstagram() {
      const self = this;
      crudSettings.deleteConnectionInstagram(self);
    },
    conectarMercadoPago() {
      const self = this;
      self.connectingWithMP = true;
      let conectarMercadoPagoCallback = () => {
        crudSettings.conectarMercadoPago(self);
      };
      self.checkDirtyFormOnExit(conectarMercadoPagoCallback, false);
    },
    deleteConnectionMercadoPago() {
      const self = this;
      crudSettings.deleteConnectionMercadoPago(self);
    },
    navigateToHome() {
      const self = this;
      self.emojiElementDeselect();
      self.emojiPicker.hidePicker();
      self.f7router.navigate("/admin", {
        reloadCurrent: true,
        // ignoreCache: true,
      });
    },
    emojiElementSelect(e, dataType) {
      const self = this;
      e.target.setAttribute("data-type", dataType);
      e.target.setAttribute("data-page-type", "settings");
      store.dispatch("setEmojiElementSelected", e.target);
      store.dispatch("setEmojiPageData", self.props.dataSettings);
      // store.dispatch("setEmojiFormData", self.props.formDataSettings);

      // if (self.emojiPicker.isPickerVisible()) {
      //   self.emojiPicker.hidePicker();
      // }
    },
    emojiElementDeselect() {
      const self = this;
      store.dispatch("setEmojiElementSelected", "");
      self.emojiPicker.hidePicker();
    },
    checkDirtyFormOnExit(callback, redirect) {
      const self = this;

      if (self.props.dirtyForm) {
        f7.dialog
          .create({
            // title: "Vertical Buttons",
            text: "Hay cambios no guardados en tu perfil. ¿Qué deseas hacer?",
            buttons: [
              {
                text: "Descartar",
                onClick: () => {
                  self.navigateToHome();
                },
              },
              {
                text: "Guardar",
                bold: true,
                onClick: async () => {
                  await new Promise((resolve) => {
                    resolve(self.updateSettings(redirect));
                  });

                  callback();
                },
              },
            ],
          })
          .open();
      } else {
        callback();
      }
    },
  },
};
</script>
