<template>
  <f7-row class="new-link-sheet-row" v-if="!props.loading">
    <f7-col>
      <f7-block-title class="no-margin-top margin-bottom-half" large>{{
        textoTituloNavbar
      }}</f7-block-title>
      <f7-block-title
        v-if="props.linkTypeName"
        class="no-margin-top block-subtitle"
        >{{
          props.linkTypeName[0].toUpperCase() + props.linkTypeName.substring(1)
        }}</f7-block-title
      >
    </f7-col>
    <f7-col>
      <f7-row class="justify-content-flex-end" style="flex-wrap: nowrap !important;">
        <div class="display-inline-flex padding-horizontal-half"
          ><f7-button
            v-if="!props.disableSave && operation === 'create'"
            class="btn-links btn-links-green"
            href="#"
            @click="createBloque"
            type="submit"
            form="link-form"
            ><i class="fas fa-save"></i
          ></f7-button>
          <f7-button
            v-if="!props.disableSave && operation === 'edit'"
            class="btn-links btn-links-green"
            href="#"
            @click="updateBloque"
            type="submit"
            form="link-form"
            ><i class="fas fa-save"></i
          ></f7-button>
          <f7-button
            v-if="props.disableSave"
            class="btn-links btn-links-disabled"
            href="#"
            ><i class="fas fa-save"></i></f7-button
        ></div>
        <div class="display-inline-flex padding-horizontal-half"
          ><f7-button
            v-if="!props.disableSave"
            class="btn-links btn-links-green"
            href="#"
            @click="cloneBloque"
            ><i class="fas fa-copy"></i
          ></f7-button>
          <f7-button
            v-if="props.disableSave"
            class="btn-links btn-links-disabled"
            href="#"
            ><i class="fas fa-copy"></i></f7-button
        ></div>
        <!-- <f7-col width="15" class="display-flex justify-center"
      ><f7-button class="btn-links btn-links-red" href="#" @click="deleteBloque"
        ><i class="fas fa-trash"></i></f7-button
    ></f7-col> -->
        <div class="display-inline-flex padding-right-half"
          ><f7-button
            v-if="!props.disableSave"
            class="btn-links btn-links-black"
            href="#"
            sheet-close
            @click="closeSheet()"
            ><i class="fas fa-times"></i></f7-button
          ><f7-button v-if="props.disableSave" class="btn-links btn-links-black"
            ><i class="fas fa-times"></i></f7-button
        ></div>
      </f7-row>
    </f7-col>
  </f7-row>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
import utils from "../../js/utils";
import store from "../../js/store";
import crudBloques from "../../js/crud-bloques";
import crudLocal from "../../js/crud-local";
import { computed } from "vue";

export default {
  props: {
    props: Object,
    operation: String,
    f7router: Object,
  },
  setup() {
    const linkType = useStore("linkType");
    const baseBlock = useStore("baseBlock");
    const emojiPicker = useStore("emojiPicker");

    return {
      linkType,
      baseBlock,
      emojiPicker,
    };
  },
  computed: {
    textoTituloNavbar() {
      if (this.operation === "edit") {
        return "Editar";
      } else if (this.operation === "create") {
        return "Crear";
      }
    },
  },
  methods: {
    createBloque(e) {
      const self = this;
      e.preventDefault();

      var ele = document.getElementById("link-form");
      var chk_status = ele.checkValidity();

      if (chk_status) {
        self.fileValidations(crudBloques.createBloque(self, false, true));
      }
      // if (chk_status) {
      //   if (
      //     self.props.linkTypeId === "imagen_de_galeria" ||
      //     self.props.linkTypeId === "item_de_catalogo"
      //   ) {
      //     self.fileValidations(crudBloques.createBloque(self, false, true));
      //   } else {
      //     self.fileValidations(crudBloques.createBloque(self, false, false));
      //   }
      // }
    },
    updateBloque(e) {
      const self = this;
      e.preventDefault();

      var ele = document.getElementById("link-form");
      var chk_status = ele.checkValidity();

      if (chk_status) {
        self.fileValidations(crudBloques.updateBloque(self, self.props.linkId));
      }
    },
    fileValidations(callback) {
      const self = this;

      if (
        (self.linkType.id_tipo_de_bloque == "imagen" &&
          !self.props.fileUploaded) ||
        (self.linkType.id_tipo_de_bloque == "archivo" &&
          !self.props.fileUploaded)
      ) {
        utils.toastConfig.text = "No ha subido ningún archivo";
        utils.toastConfig.cssClass = "warning-toast";
        f7.toast.create(utils.toastConfig).open();
      } else {
        callback;
      }
    },
    cloneBloque() {
      const self = this;

      f7.dialog.confirm("¿Esta seguro que desea clonar este bloque?", () => {
        crudBloques.cloneBloque(self, self.props.linkId);
      });
    },
    deleteBloque() {
      const self = this;

      f7.dialog.confirm("¿Esta seguro que desea eliminar este bloque?", () => {
        crudBloques.deleteBloque(self, self.props.linkId);
      });
    },
    emojiElementDeselect() {
      const self = this;
      store.dispatch("setEmojiElementSelected", "");
    },
    closeSheet() {
      const self = this;
      self.f7router.navigate("/admin", {
        reloadCurrent: true
      })
      self.emojiPicker.hidePicker();
      self.emojiElementDeselect();
    },
    // onSheetClose() {
    //   const self = this;
    //   f7.sheet.close();

    //   if (
    //     self.props.linkTypeId === "imagen_de_galeria" ||
    //     self.props.linkTypeId === "item_de_catalogo"
    //   ) {
    //     let parentBlockName = localStorage.getItem("parent_block");
    //     f7.views.main.router.navigate(`/admin/link/${parentBlockName}/`, {
    //       ignoreCache: true,
    //       options: {
    //         props: {
    //           operation: "edit",
    //         },
    //       },
    //     });
    //   }
    // },
  },
};
</script>
