<template>
  <f7-page class="account" name="account" @page:beforein="pageBeforeIn">
    <!-- <fullscreenLoader v-if="loading" /> -->
    <div>
      <div style="margin-bottom: 70px">
        <f7-block v-if="!loading" class="margin-top-half no-margin-bottom">
          <f7-row class="align-items-center">
            <f7-col width="70" class="display-flex justify-content-start">
              <h2>
                <i class="fas fa-user"></i>
                &nbsp;&nbsp;Mi Cuenta
              </h2>
            </f7-col>
            <!-- <f7-col
              width="30"
              class="display-flex flex-direction-column align-items-flex-end"
            >
              <f7-button
                class=""
                style="font-size: 20px; display: block"
                href="//main-menu"
                ><i class="fas fa-bars"></i
              ></f7-button>
            </f7-col> -->
          </f7-row>
        </f7-block>
        <f7-block v-if="!loading" class="no-padding-horizontal no-margin">
          <!-- Datos Generales -->
          <f7-list no-hairlines no-hairlines-between>
            <f7-list-input
              outline
              label="Correo Electrónico"
              type="email"
              name="email"
              :value="usuarioEmail"
              disabled
            ></f7-list-input>
          </f7-list>

          <f7-list
            no-hairlines-md
            no-hairlines-between
            class="margin-vertical-half virtual-list-links"
            form
            id="profile-form"
            autocomplete="off"
          >
            <!-- Mi Suscripción -->
            <li class="list-item-divider-custom">
              <div class="item-content">
                <div class="item-inner">
                  <span>
                    <i class="fas fa-money-check-alt"></i>&nbsp;&nbsp;
                    <span>Mi Suscripción</span>
                  </span>
                </div>
              </div>
            </li>
            <f7-list-input
              outline
              label="Plan Actual"
              type="text"
              name="plan"
              v-model:value="suscripcion.nombre_suscripcion_actual"
              readonly
              disabled
            ></f7-list-input>
            <f7-list-input
              outline
              label="Estado de la Suscripción"
              type="text"
              name="estado_suscripcion"
              :value="suscripcion.estado_suscripcion"
              readonly
              disabled
            ></f7-list-input>
            <f7-list-input
              outline
              label="Renovación"
              type="date"
              name="renovacion_plan"
              v-model:value="suscripcion.vencimiento_suscripcion"
              readonly
              disabled
            ></f7-list-input>
            <li class="margin-top" v-if="false">
              <div class="item-content item-input item-input-outline">
                <div class="item-inner">
                  <f7-row style="width: 100%">
                    <f7-col
                      v-if="
                        suscripcion.nombre_suscripcion_actual === 'Gratuito'
                      "
                      ><f7-button fill color="green" @click="changeProPlan">
                        <span
                          ><i class="fas fa-crown"></i>&nbsp;&nbsp;Cambiar a
                          Pro</span
                        ></f7-button
                      >
                      <span
                        class="padding-top-half"
                        style="
                          text-align: center;
                          display: block;
                          color: #777777;
                        "
                        >Podras cancelarlo en cualquier momento.</span
                      >
                    </f7-col>
                    <f7-col
                      v-if="suscripcion.nombre_suscripcion_actual === 'Pro'"
                      ><f7-button
                        fill
                        rounded
                        color="red"
                        @click="cancelProPlan"
                        ><span
                          ><i class="fas fa-times"></i>&nbsp;&nbsp;Cancelar
                          Pro</span
                        ></f7-button
                      >
                      <!-- <span
                            class="padding-top-half"
                            style="
                              text-align: center;
                              display: block;
                              color: #777777;
                            "
                            >Conservarás tu cuenta con un plan Gratuito.</span> -->
                    </f7-col>
                  </f7-row>
                </div>
              </div>
            </li>
            <!-- Zona de Peligro -->
            <li class="list-item-divider-custom">
              <div class="item-content">
                <div class="item-inner">
                  <span>
                    <i class="fas fa-exclamation"></i>&nbsp;&nbsp;
                    <span>Zona de Peligro</span>
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div class="item-content item-input item-input-outline">
                <div class="item-inner">
                  <f7-row style="width: 100%" class="margin-top">
                    <f7-col
                      ><f7-button
                        fill
                        rounded
                        color="red"
                        @click="props.sheetChangePasswordOpen = true"
                        ><i class="fas fa-key"></i>&nbsp;&nbsp;Cambiar
                        Contraseña</f7-button
                      ></f7-col
                    >
                  </f7-row>
                  <f7-row style="width: 100%" class="margin-top">
                    <f7-col
                      ><f7-button
                        fill
                        rounded
                        color="red"
                        @click="removeAccount"
                        ><i class="fas fa-trash"></i>&nbsp;&nbsp;Eliminar
                        Cuenta</f7-button
                      ></f7-col
                    >
                  </f7-row>
                </div>
              </div>
            </li>
            <!-- Mi Cuenta -->
            <!-- <li class="list-item-divider-custom">
              <div class="item-content">
                <div class="item-inner">
                  <span>
                    <i class="fas fa-user"></i>&nbsp;&nbsp;
                    <span>Mi Cuenta</span>
                  </span>
                </div>
              </div>
            </li> -->
          </f7-list>
        </f7-block>
      </div>
      <!-- <f7-fab
        position="right-bottom"
        class="custom-fab"
        @click="updateSettings"
      >
        <i class="fas fa-save"></i>
      </f7-fab> -->
      <!-- <tabbar activePage="settings-profile" /> -->

      <f7-fab position="right-top" class="custom-fab" @click="navigateToHome">
        <i class="fas fa-rocket"></i>
      </f7-fab>

      <f7-actions
        :opened="actionAyudaAccountProfileOpen"
        @actions:closed="actionAyudaAccountProfileOpen = false"
        style="background: #ffffff"
      >
        <f7-actions-group>
          <f7-actions-label>Ayuda</f7-actions-label>
          <f7-block>
            <f7-row>
              <f7-col class="display-flex justify-content-center">
                <video
                  src=""
                  style="
                    width: calc(80vh / 1.8);
                    box-shadow: rgb(197 205 218) 0px 0rem 2rem;
                    border-radius: 10px;
                    max-height: 80vh;
                  "
                  controls
                ></video>
              </f7-col>
            </f7-row>
          </f7-block>
        </f7-actions-group>
      </f7-actions>
    </div>
    <sheetChangePassword :props="props" />
  </f7-page>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import crudSettings from "../js/crud-settings";
import utils from "../js/utils";
import fullscreenLoader from "../components/fullscreen-loader-container";
import sheetChangePassword from "../components/authentication/sheet-change-password";
import account from "../js/account";

export default {
  components: {
    fullscreenLoader,
    sheetChangePassword,
  },
  props: {
    f7route: Object,
    f7router: Object,
  },
  data() {
    return {
      loading: true,
      actionAyudaAccountProfileOpen: false,
      props: {
        formDataSettings: {},
        dataSettings: {},
        sheetChangePasswordOpen: false,
      },
    };
  },
  setup() {
    const local = useStore("local");
    const usuarioEmail = useStore("usuarioEmail");
    const suscripcion = useStore("suscripcion");
    const frappeHost = useStore("frappeHost");

    return {
      local,
      usuarioEmail,
      suscripcion,
      frappeHost,
    };
  },
  mounted() {
    const self = this;
  },
  watch: {
    local(val, oldVal) {
      this.props.formDataSettings = this.local;
      this.props.dataSettings = this.local;
      this.loading = false;
    },
  },
  methods: {
    setValueForm(e, radioValue, options) {
      const self = this;
      crudSettings.setValueForm(self, e, radioValue, options);
    },
    updateSettings() {
      const self = this;
      crudSettings.updateSettings(self, true);
    },
    pageBeforeIn() {
      const self = this;
      self.props.formDataSettings = self.local;
      self.props.dataSettings = self.local;
      setTimeout(() => {
        self.loading = false;
      }, 1000);
    },
    changeProPlan() {
      const self = this;
      account.changeProPlan(self);
    },
    cancelProPlan() {
      const self = this;
      account.cancelProPlan(self);
    },
    removeAccount() {
      const self = this;
      f7.dialog.confirm(
        "¿Esta seguro que desea eliminar su cuenta? Esta acción eliminará todos sus datos.",
        () => {
          account.removeAccount(self);
        }
      );
    },
    changePassword() {
      const self = this;
      account.changePassword(self);
    },
    navigateToHome() {
      const self = this;
      self.f7router.navigate("/admin", {
        reloadCurrent: true,
      });
    },
  },
};
</script>