<template>
  <f7-login-screen
    id="my-login-screen"
    :opened="props.loginScreenOpen === true"
  >
    <f7-view>
      <f7-page login-screen>
        <logoAuth />
        <div style="width: 100%">
          <h2
            class="
              text-align-center
              margin-horizontal
              no-margin-vertical
              margin-bottom-half
            "
            style="color: #222222; font-size: 30px; font-weight: 400"
            >Ingresa a tu cuenta de miqro.app</h2
          >
          <f7-list
            form
            @submit="
              (e) => {
                login(e);
              }
            "
          >
            <f7-list-input
              outline
              floating-label
              class="transform-lowercase"
              type="email"
              name="usuario_app"
              placeholder="Tu correo electrónico"
              v-model:value="usuarioLogin"
              validate
              error-message="Ingresa un email válido"
            ></f7-list-input>
            <f7-list-input
              outline
              floating-label
              :type="inputTypePasswordLogin"
              name="contrasenia"
              placeholder="Contraseña"
              v-model:value="contraseniaLogin"
              validate
            >
              <template #content>
                <span
                  class="mostrar-contrasenia-icon"
                  v-show="inputTypePasswordLogin === 'password'"
                  @click="inputTypePasswordLogin = 'text'"
                  ><i class="fas fa-eye"></i
                ></span>
                <span
                  class="mostrar-contrasenia-icon"
                  v-show="inputTypePasswordLogin === 'text'"
                  @click="inputTypePasswordLogin = 'password'"
                  ><i class="fas fa-eye-slash"></i
                ></span>
              </template>
              <template #inner-end>
                <span class="forget-password"
                  ><a
                    @click="
                      props.forgetPasswordOpen = true;
                      props.loginScreenOpen = false;
                    "
                    >¿Olvidaste tu contraseña?</a
                  ></span
                >
              </template>
            </f7-list-input>
            <li>
              <div
                class="
                  item-content item-input margin-vertical
                "
              >
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <f7-button color="green" type="submit" fill href="#"
                      >INICIAR SESIÓN</f7-button
                    >
                  </div>
                </div>
              </div>
            </li>
          </f7-list>
        </div>

        <f7-list class="margin-bottom-half">
          <f7-block-footer class="no-margin-bottom">
            ¿No tenes una cuenta?
            <f7-button
              @click="
                props.signupScreenOpen = true;
                props.loginScreenOpen = false;
              "
              href="#"
              style="font-weight: 600"
              >Crea una en ahora</f7-button
            ></f7-block-footer
          >
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</template>

<script>
import authentication from "../../js/authentication";
import logoAuth from "./logo-auth";

export default {
  components: {
    logoAuth,
  },
  props: {
    props: Object,
  },
  data() {
    return {
      usuarioLogin: "",
      contraseniaLogin: "",
      repetirContrasenia: "",
      inputTypePasswordLogin: "password",
    };
  },
  methods: {
    login(e) {
      e.preventDefault();
      const self = this;
      authentication.login(self, e);
    },
  },
};
</script>