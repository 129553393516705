<template>
  <f7-block class="margin-vertical-half no-printme">
    <f7-row class="align-items-center">
      <f7-col width="70" class="display-flex justify-content-start">
        <h2>
          <i class="fas fa-qrcode"></i>
          &nbsp;&nbsp;{{ pageName }}
        </h2>
      </f7-col>
      <!-- <f7-col
        width="15"
        class="display-flex flex-direction-column align-items-flex-end"
      >
        <f7-button
          @click="props.actionAyudaVideoOpen = true"
          style="font-size: 18px; display: block"
          ><i class="fas fa-question"></i
        ></f7-button>
      </f7-col> -->
      <!-- <f7-col
        width="30"
        class="display-flex flex-direction-column align-items-flex-end"
      >
        <f7-button
          class=""
          href="/admin/main-menu"
          style="font-size: 20px; display: block"
          ><i class="fas fa-bars"></i
        ></f7-button>
      </f7-col> -->
    </f7-row>
  </f7-block>

  <f7-fab
    position="right-top"
    class="custom-fab no-printme"
    @click="navigateToHome"
  >
    <i class="fas fa-rocket"></i>
  </f7-fab>

  <helpActionVideos :props="props" :srcHelpVideo="srcHelpVideo" />
</template>

<script>
import { f7, useStore } from "framework7-vue";
import helpActionVideos from "../components/helps/help-actions-videos";

export default {
  components: {
    helpActionVideos,
  },
  props: {
    f7router: Object,
    pageName: String,
    srcHelpVideo: String,
  },
  setup() {
    const local = useStore("local");

    return {
      local,
    };
  },
  data() {
    return {
      props: {
        actionAyudaVideoOpen: false,
      },
    };
  },
  methods: {
    navigateToHome() {
      const self = this;
      self.f7router.navigate("/admin", {
        reloadCurrent: true,
      });s
    },
  },
};
</script>