<template>
  <!-- Imagen -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'imagen'"
    class="item-imagen link-item-list-preview"
    @click="registraEvento(bloque, 'click_bloque')"
  >
    <!-- IMAGEN -->
    <img
      :src="bloque.imagen_url"
      :class="
        bloque.enlace_boton_cta || bloque.texto
          ? 'preview-imagen-block-with-info'
          : 'preview-imagen-block-without-info'
      "
    />
    <!-- CONTENIDO -->
    <div
      v-if="bloque.enlace_boton_cta || bloque.texto || bloque.subtitulo"
      class="padding"
      style="width: -webkit-fill-available"
    >
      <!-- SUBTITULO -->
      <f7-row class="justify-content-center" v-if="bloque.subtitulo">
        <div class="item-title">{{ bloque.subtitulo }}</div>
      </f7-row>
      <!-- TEXTO -->
      <div
        v-if="bloque.texto"
        class="padding-horizontal text-align-center"
      >
        {{ bloque.texto }}
      </div>
      <!-- CTA -->
      <br v-if="bloque.enlace_boton_cta && bloque.texto">
      <f7-button
        v-if="bloque.enlace_boton_cta"
        style="font-weight: 800; border-radius: 5px"
        fill
        class="external preview-btn"
        @click="
          registraEvento(
            bloque,
            'click_interno',
            true,
            bloque.enlace_boton_cta
          )
        "
      >
        <span style="margin-top: 0px">{{
          bloque.texto_boton_cta
        }}</span></f7-button
      >
    </div>
  </div>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";

export default {
  props: {
    bloque: Object,
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
  },
};
</script>
