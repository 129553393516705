const axios = require("axios").default;
import { EmojiButton } from "@joeattardi/emoji-button";
import { f7, f7ready, useStore } from "framework7-vue";
import store from "./store";
import crudBloques from "./crud-bloques";
import crudSettings from "./crud-settings";

let toastConfig = {
  text: "",
  html: "",
  position: "bottom",
  cssClass: "",
  // closeButton: true,
  // closeButtonText: "<i class='fas fa-times'></i>",
  // closeButtonColor: "#ffffff",
  // icon: "<i class='fas fa-check'></i>",
  horizontalPosition: "center",
  closeTimeout: 3000,
};

let axiosCall = (
  method,
  module,
  doctype,
  method_dt,
  data,
  headers,
  callback,
  callbackError = null
) => {
  // let frappeHost = "https://dev.miqro.app/api/method/"; // Development
  let frappeHost = "https://admin.miqro.app/api/method/"; // Production

  axios({
    method: method,
    url: `${frappeHost}linqr.${module}.doctype.${doctype}.${doctype}.${method_dt}`,
    data: data,
    headers: headers,
  })
    .then(function (response) {
      if (callback) {
        callback(response);
      } else {
        toastConfig.text = response.data.message;
        f7.toast.create(toastConfig).open();
      }
    })
    .catch(function (error) {
      if (callbackError) {
        callbackError(error);
      } else {
        toastConfig.text = error.message;
        f7.toast.create(toastConfig).open();
      }
    });
};

let newEmojiPicker = () => {
  const pickerOptions = {
    autoHide: false,
    showPreview: false,
    showSearch: false,
    showAnimation: true,
    showCategoryButtons: false,
    showRecents: true,
    initialCategory: "recents",
    emojisPerRow: 5,
    // emojisPerRow: Math.floor((window.innerWidth - 16 - 24) / 43.19),
    rows: 4,
    position: {
      bottom: 0,
    },
    i18n: {
      categories: {
        recents: "Emojis Recientes",
        smileys: "Smileys y Emociones",
        people: "Personas",
        animals: "Animales y Naturaleza",
        food: "Comidas y Bebidas",
        activities: "Actividades",
        travel: "Viajes y Lugares",
        objects: "Objetos",
        symbols: "Símbolos",
        flags: "Banderas",
      },
    },
  };
  let emoji_picker = new EmojiButton(pickerOptions);
  return emoji_picker;
};

let loadPickerWidget = (self) => {
  store.dispatch("setEmojiPicker", newEmojiPicker());
  let emojiPicker = useStore("emojiPicker").value;

  // emojiPicker.off("emoji", (selection) => onEmojiSelected(self, selection));

  // setTimeout(() => {
  emojiPicker.on("emoji", (selection) => onEmojiSelected(self, selection));
  // }, 500);
};

let onEmojiSelected = (self, selection) => {
  let target = useStore("emojiElementSelected").value;
  let emojiData = useStore("emojiPageData").value;

  const currentInputVal = target.value;
  let preEmojiString = currentInputVal.substring(0, target.selectionStart);
  let postEmojiString = currentInputVal.substring(target.selectionStart);
  let emojiedValue = preEmojiString + selection.emoji + postEmojiString;

  if (target.dataset.pageType === "qr") {
    emojiData = emojiedValue;
    if (target.name === "texto_superior") {
      store.dispatch("customizeQRText", emojiedValue);
    } else if (target.name === "texto_inferior") {
      store.dispatch("customizeQRSubText", emojiedValue);
    }
  } else {
    emojiData[target.name] = emojiedValue;
  }

  target.value = emojiedValue;

  if (target.dataset.pageType === "bloques") {
    crudBloques.setValueForm(self, {
      type: target.dataset.type,
      target: target,
      currentTarget: target,
    });
  } else if (target.dataset.pageType === "settings") {
    crudSettings.setValueForm(self, {
      type: target.dataset.type,
      target: target,
      currentTarget: target,
    });
  }
};

let triggerEmojiPicker = () => {
  let target = useStore("emojiElementSelected").value;
  let emojiPicker = useStore("emojiPicker").value;

  emojiPicker.togglePicker(target);
};

export default {
  toastConfig,
  axiosCall,
  newEmojiPicker,
  loadPickerWidget,
  triggerEmojiPicker,
};
