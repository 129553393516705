<template>
  <f7-sheet
    swipe-to-close
    backdrop
    style="height: -webkit-fill-available; overflow: auto"
  >
    <f7-page-content>
      <f7-row
        style="
          position: fixed;
          z-index: 10;
          background: #fff;
          width: 100%;
          box-shadow: 0 0rem 2rem rgb(213 221 236 / 40%);
          padding-top: 25px !important;
        "
      >
        <f7-col width="70">
          <f7-block-title class="no-margin-top padding-top-half" large
            >Elegí un Bloque</f7-block-title
          >
        </f7-col>
        <f7-col width="30" class="display-flex justify-content-flex-end padding-horizontal"
          ><f7-button
            class="btn-links btn-links-red"
            href="#"
            sheet-close
            @click="removeLocalStorage"
            ><i class="fas fa-times"></i></f7-button
        ></f7-col>
      </f7-row>

      <f7-block v-if="loading">
        <f7-list
          sortable
          no-hairlines
          no-hairlines-between
          class="no-margin-vertical skeleton-text skeleton-effect-fade"
        >
          <f7-list-item> </f7-list-item>
          <f7-list-item
            media-item
            class="link-type-item-list margin-vertical"
            v-for="n in 6"
            :key="n"
            subtitle="Subtitle"
          >
            <template #title>
              <f7-skeleton-block>Title Title Title Title</f7-skeleton-block>
            </template>
            <template #media>
              <f7-skeleton-block
                style="width: 40px; height: 40px !important; border-radius: 50%"
              ></f7-skeleton-block>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-block>

      <f7-block v-if="!loading">
        <f7-list
          sortable
          no-hairlines
          no-hairlines-between
          class="no-margin-vertical"
        >
          <f7-list-item> </f7-list-item>
          <f7-list-item
            media-item
            class="link-type-item-list margin-vertical"
            v-for="linkType in linksType"
            :key="linkType.name"
            :title="
              linkType.nombre_tipo_de_bloque[0].toUpperCase() +
              linkType.nombre_tipo_de_bloque.substring(1)
            "
            :subtitle="linkType.descripcion"
            @click="saveSelectedBlockType(linkType)"
          >
            <template #media>
              <i :class="linkType.icono"></i>
            </template>
          </f7-list-item>

          <!-- Plan Pro -->
          <f7-list-item
            v-if="suscripcion.nombre_suscripcion_actual === 'Gratuito'"
            media-item
            class="link-type-item-list margin-vertical link-type-premium-ad"
            style="background-color: #ffc314"
            title="¿Necesitas más funciones?"
            subtitle="Pasate al Plan Pro para ampliar la cantidad de bloques y usar los bloques avanzados."
            @click="openTryProSheet"
          >
            <template #media>
              <i class="fas fa-crown" style="color: #212121"></i>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
import store from "../../js/store";
import utils from "../../js/utils";
import crudBloques from "../../js/crud-bloques";

export default {
  props: {
    f7router: Object,
  },
  data() {
    return {
      loading: true,
    };
  },
  setup() {
    const linksType = useStore("linksType");
    const suscripcion = useStore("suscripcion");

    return {
      linksType,
      suscripcion,
    };
  },
  beforeMount() {
    const self = this;
    crudBloques.getTiposDeBloques(self);
  },
  methods: {
    async saveSelectedBlockType(linkType) {
      const self = this;

      let isInstagramConnected = await new Promise((resolve) => {
        if (linkType.id_nombre === "producto_instagram") {
          utils.axiosCall(
            "post",
            "linqr",
            "integracion_instagram",
            "check_integracion_instagram",
            {},
            {
              "Linqr-Token": localStorage.getItem("token"),
            },
            function (r) {
              if (r.data.message.success === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            },
            function (e) {}
          );
        } else {
          resolve(false);
        }
      });

      if (
        linkType.id_nombre === "producto_instagram" &&
        !isInstagramConnected
      ) {
        utils.toastConfig.text =
          "Primero debes conectarte con Instagram. Podrás hacerlo desde <strong>Editar Perfil</strong>";
        utils.toastConfig.cssClass = "error-toast";
        utils.toastConfig.closeTimeout = 5000;
        f7.toast.create(utils.toastConfig).open();

        f7.sheet.close();
      } else {
        localStorage.setItem("block_type", JSON.stringify(linkType));
        f7.sheet.close();
        f7.views.main.router.navigate(`/admin/new-link/${linkType.name}/`);
      }
    },
    removeLocalStorage() {
      localStorage.removeItem("block_type");
      // store.dispatch("setLinkType", []);
    },
    openTryProSheet() {
      const self = this;

      if (self.suscripcion.prueba_gratuita == false) {
        store.dispatch("setOpenTryProSheet", true);
        f7.sheet.close();
      }
      if (self.suscripcion.prueba_gratuita == true) {
        store.dispatch("setOpenBuyPro", true);
        f7.sheet.close();
      }
    },
  },
};
</script>
