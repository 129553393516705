<template id="fullscreen-loader">
  <!-- LOADER A PANTALLA COMPLETA -->
  <span class="fullscreen-loader-container">
    <div class="col fullscreen-loader">
      <div class="fullscreen-loader-image-container">
        <img
          src="/static/images/miqrositio_loader_2.gif"
          class="fullscreen-loader-image"
          height="200px"
        />
        <h2>Aguarde</h2>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  mounted: function () {
    const self = this;
  },
  methods: {},
};
</script>
