import { f7, f7ready, useStore } from "framework7-vue";
import crudBloques from "./crud-bloques";
import crudSettings from "./crud-settings";
// let frappeHost = "https://dev.miqro.app/api/method/"; // Development
let frappeHost = "https://admin.miqro.app/api/method/"; // Production

// Uppy
const Uppy = require("@uppy/core");
const Spain = require("@uppy/locales/lib/es_ES");
const Dashboard = require("@uppy/dashboard");
const Tus = require("@uppy/tus");
const XHRUpload = require("@uppy/xhr-upload");
// const Webcam = require("@uppy/webcam");
// const GoogleDrive = require("@uppy/google-drive");
// const Instagram = require("@uppy/instagram");
// const Facebook = require("@uppy/facebook");
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

let uppyConfig = function (self) {
  if (self.props.linkTypeId == "archivo") {
    var allowedFileTypes = [
      "image/*",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats officedocument.wordprocessingml.document",
      "audio/mpeg",
      "audio/wav",
      "video/webm",
      "image/webm",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/x-rar-compressed",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/zip",
    ];
  } else if (self.props.linkTypeId == "imagen") {
    var allowedFileTypes = ["image/*"];
  } else {
    var allowedFileTypes = "";
  }

  // if (f7.$(".DashboardContainer").length > 0) {
  setTimeout(function () {
    const uppyThumb = new Uppy({
      debug: true,
      locale: Spain,
      limit: 1,
      autoProceed: true,
      restrictions: {
        maxFileSize: 5242880,
        maxNumberOfFiles: 5,
        minNumberOfFiles: 1,
        allowedFileTypes: allowedFileTypes,
      },
    })
      .use(Dashboard, {
        inline: true,
        target: `.DashboardContainer`,
        replaceTargetContent: false,
        showProgressDetails: true,
        hideProgressAfterFinish: true,
        showRemoveButtonAfterComplete: true,
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: false,
        hideUploadButton: false,
        // note: "Images and video only, 2–3 files, up to 1 MB",
        height: "auto",
        width: "100%",
        locale: {
          strings: {
            browseFiles: "Subí tus Archivos",
            dropPasteImportFiles: "%{browseFiles}",
            dropPasteFiles: "%{browseFiles}",
          },
        },
        browserBackButtonClose: true,
      })
      .use(XHRUpload, {
        endpoint: `${frappeHost}linqr.linqr.doctype.utils.save_to_s3_protected`,
        headers: {
          "Linqr-Token": localStorage.getItem("token"),
        },
        formData: true,
        fieldName: "file",
      });

    // uppyThumb.on("upload-success", (file, response) => {
    //   response.status; // HTTP status code
    //   response.body; // extracted response data

    //   // do something with file and response
    // });

    // fetch(self.props.dataBloque.imagen_url)
    //   .then((response) => response.blob()) // returns a Blob
    //   .then((blob) => {
    //     uppyThumb.addFile({
    //       name: "image.jpg",
    //       type: blob.type,
    //       data: blob,
    //     });
    //   });

    // uppy.getFiles().forEach((file) => {
    //   uppy.setFileState(file.id, {
    //     progress: { uploadComplete: true, uploadStarted: false },
    //   });
    // });

    // uppy.getFiles().forEach((file) => {
    //   uppy.setFileMeta(file.id, {
    //     preview: self.props.dataBloque.imagen_url,
    //   });
    // });

    uppyThumb.on("upload", (file, progress) => {
      self.props.disableSave = true;
    });

    uppyThumb.on("complete", (result) => {
      debugger;
      if (result.successful.length > 0) {
        self.props.fileUploaded = true;
        let presigned_url =
          result.successful[0].response.body.message.presigned_url.url;
        let presigned_key =
          result.successful[0].response.body.message.presigned_url.fields.key;
        // console.log(self.formprops.dataBloque["imagen_url"]);

        if (self.props.linkTypeId == "archivo") {
          self.props.formDataBloque["archivo_url"] =
            presigned_url + presigned_key;
          self.props.dataBloque["archivo_url"] = presigned_url + presigned_key;
        } else if (self.props.linkTypeId == "imagen") {
          self.props.formDataBloque["imagen_url"] =
            presigned_url + presigned_key;
          self.props.dataBloque["imagen_url"] = presigned_url + presigned_key;
          self.props.preloadedImage = true;
        } else if (
          self.props.linkTypeId == "enlace_simple" ||
          self.props.linkTypeId == "producto"
        ) {
          self.props.formDataBloque["vista_previa_url"] =
            presigned_url + presigned_key;
          self.props.dataBloque["vista_previa_url"] =
            presigned_url + presigned_key;
        }

        if (self.props.dataBloque["name"]) {
          crudBloques.updateBloque(self, self.props.dataBloque["name"]);
        } else {
          if (
            !self.props.formDataBloque["nombre_bloque"] ||
            self.props.formDataBloque["nombre_bloque"] === ""
          ) {
            self.props.formDataBloque["nombre_bloque"] = "Bloque Sin Nombre";
            self.props.dataBloque["nombre_bloque"] = "Bloque Sin Nombre";
          }
          crudBloques.createBloque(self).then((r) => {
            self.props.operation = "edit";
          });
        }
      } else {
        console.log("failed files:", result.failed);
      }

      self.props.disableSave = false;
    });

    uppyThumb.on("error", (file, progress) => {
      self.props.disableSave = false;
    });

    uppyThumb.on("file-removed", (file, reason) => {
      self.props.fileUploaded = false;
    });
  }, 0);
};

let uppyConfigHomePreview = function (self, local) {
  let bloquesLocal = useStore("bloquesLocal").value;

  if (bloquesLocal !== null && bloquesLocal !== undefined) {
    bloquesLocal.forEach(async function (bloque) {
      let uppyElements = document.querySelectorAll(
        `.DashboardContainerHome_${bloque.name}`
      );

      let uppyElementRoot = f7
        .$(`.DashboardContainerHome_${bloque.name}`)
        .find(".uppy-Root");

      if (
        bloque.form_archivos == 1 &&
        uppyElements.length > 0 &&
        uppyElementRoot.length === 0
      ) {
        if (uppyElements.length > 1) {
          uppyElements[0].parentNode.removeChild(uppyElements[0]);
        }

        const uppyHome = new Uppy({
          id: bloque.name,
          debug: true,
          locale: Spain,
          // limit: 1,
          autoProceed: true,
          restrictions: {
            maxFileSize: 5242880,
            maxNumberOfFiles: 5,
            minNumberOfFiles: 1,
            // allowedFileTypes: ["image/*", "video/*"],
          },
        })
          .use(Dashboard, {
            inline: true,
            target: `.DashboardContainerHome_${bloque.name}`,
            replaceTargetContent: false,
            showProgressDetails: true,
            hideProgressAfterFinish: true,
            showRemoveButtonAfterComplete: true,
            proudlyDisplayPoweredByUppy: false,
            showLinkToFileUploadResult: false,
            // note: "Images and video only, 2–3 files, up to 1 MB",
            height: "auto",
            width: "100%",
            locale: {
              strings: {
                browseFiles: "Subí tus Archivos",
                dropPasteImportFiles: "%{browseFiles} imágenes",
                dropPasteFiles: "%{browseFiles}",
              },
            },
            browserBackButtonClose: true,
          })
          .use(XHRUpload, {
            endpoint: `${frappeHost}linqr.linqr.doctype.utils.save_to_s3`,
            headers: {
              "Linqr-Token": localStorage.getItem("token"),
            },
            formData: true,
            fieldName: "file",
          });

        uppyHome.on("files-added", (data) => {
          let totalSizeFiles = 0;

          for (let i = 0; i < data.length; i++) {
            totalSizeFiles += data[i].size / (1024 * 1024);

            if (data.length === i + 1) {
              uppyHome.setMeta({
                bloqueId: bloque.name,
                local: local.name,
                totalSize: totalSizeFiles,
              });
              self.props.uploadingFiles = true;
            }
          }
        });

        uppyHome.on("upload", (data) => {
          self.props.uploadingFiles = true;
        });

        uppyHome.on("complete", (result) => {
          self.props.formArchivos[bloque.name] = {};
          self.props.uploadingFiles = false;

          if (result.successful.length > 0) {
            for (let i = 0; i < result.successful.length; i++) {
              // let bloqueId = result.successful[i].meta.bloqueId;
              let presigned_url =
                result.successful[i].response.body.message.presigned_url.url;
              let presigned_key =
                result.successful[i].response.body.message.presigned_url.fields
                  .key;
              self.props.formArchivos[bloque.name][`archivo_url_${i}`] =
                presigned_url + presigned_key;
            }

            // var el = f7.$(`#form_archivo_${CSS.escape(bloqueId)}`);
            // el.value(presigned_url + presigned_key);
            // console.log(self.formDataBloque["imagen_url"]);
            // self.preloadedImage = true;
            document
              .querySelector(`form.${bloque.name}`)
              .addEventListener("submit", (e) => {
                uppyHome.reset();
              });
          } else {
            console.log("failed files:", result.failed);
          }
        });
        // }, 0);
        // }
      }
    });
  }
  // }
  // }, 500);
};

let uppyConfigProfile = function (self) {
  setTimeout(() => {
    const uppyHome = new Uppy({
      debug: false,
      locale: Spain,
      limit: 1,
      autoProceed: true,
      restrictions: {
        maxFileSize: 5242880,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: [".jpg", ".jpeg", ".png", ".gif"],
      },
    })
      .use(Dashboard, {
        inline: true,
        target: ".DashboardContainerProfile",
        replaceTargetContent: false,
        showProgressDetails: true,
        hideProgressAfterFinish: true,
        showRemoveButtonAfterComplete: true,
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: false,
        // note: "Images and video only, 2–3 files, up to 1 MB",
        height: "auto",
        width: "100%",
        locale: {
          strings: {
            browseFiles: "Subí tu imagen de perfil",
            dropPasteImportFiles: "%{browseFiles} imágenes",
            dropPasteFiles: "%{browseFiles}",
          },
        },
        browserBackButtonClose: true,
      })
      .use(XHRUpload, {
        endpoint: `${frappeHost}linqr.linqr.doctype.utils.save_to_s3_profile`,
        headers: {
          "Linqr-Token": localStorage.getItem("token"),
        },
        formData: true,
        fieldName: "file",
      });

    // uppyHome.setMeta({ bloqueId: bloque.name });
    uppyHome.on("upload", (data) => {
      self.props.uploadingFiles = true;
    });

    uppyHome.on("upload-error", (file, error, response) => {});

    uppyHome.on("complete", (result) => {
      if (result.successful.length > 0) {
        // let bloqueId = result.successful[0].meta.bloqueId;
        let presigned_url =
          result.successful[0].response.body.message.presigned_url.url;
        let presigned_key =
          result.successful[0].response.body.message.presigned_url.fields.key;
        // self.props.formArchivos[bloqueId] = {};
        self.props.dataSettings["imagen_perfil"] =
          presigned_url + presigned_key;

        // var el = f7.$(`#form_archivo_${CSS.escape(bloqueId)}`);
        // el.value(presigned_url + presigned_key);
        // console.log(self.formDataBloque["imagen_url"]);
        // self.preloadedImage = true;
        crudSettings.updateSettings(self, false).then((r) => {
          f7.dialog.close();
        });
        uppyHome.reset();
        self.props.profileImageLoaded = true;
        self.props.uploadingFiles = false;
      } else {
        console.log("failed files:", result.failed);
        self.props.uploadingFiles = false;
      }
    });
  }, 1000);
};

let uppyConfigProfileCover = function (self) {
  setTimeout(() => {
    const uppyHome = new Uppy({
      debug: false,
      locale: Spain,
      limit: 1,
      autoProceed: true,
      restrictions: {
        maxFileSize: 5242880,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: [".jpg", ".jpeg", ".png", ".gif"],
      },
    })
      .use(Dashboard, {
        inline: true,
        target: ".DashboardContainerProfileCover",
        replaceTargetContent: false,
        showProgressDetails: true,
        hideProgressAfterFinish: true,
        showRemoveButtonAfterComplete: true,
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: false,
        // note: "Images and video only, 2–3 files, up to 1 MB",
        height: "auto",
        width: "100%",
        locale: {
          strings: {
            browseFiles: "Subí tu imagen de cover",
            dropPasteImportFiles: "%{browseFiles} imágenes",
            dropPasteFiles: "%{browseFiles}",
          },
        },
        browserBackButtonClose: true,
      })
      .use(XHRUpload, {
        endpoint: `${frappeHost}linqr.linqr.doctype.utils.save_to_s3_profile`,
        headers: {
          "Linqr-Token": localStorage.getItem("token"),
        },
        formData: true,
        fieldName: "file",
      });

    // uppyHome.setMeta({ bloqueId: bloque.name });
    uppyHome.on("upload", (data) => {
      self.props.uploadingFiles = true;
    });

    uppyHome.on("complete", (result) => {
      if (result.successful.length > 0) {
        // let bloqueId = result.successful[0].meta.bloqueId;
        let presigned_url =
          result.successful[0].response.body.message.presigned_url.url;
        let presigned_key =
          result.successful[0].response.body.message.presigned_url.fields.key;
        // self.props.formArchivos[bloqueId] = {};
        self.props.dataSettings["imagen_cover"] = presigned_url + presigned_key;

        // var el = f7.$(`#form_archivo_${CSS.escape(bloqueId)}`);
        // el.value(presigned_url + presigned_key);
        // console.log(self.formDataBloque["imagen_url"]);
        // self.preloadedImage = true;
        crudSettings.updateSettings(self, false).then((r) => {
          f7.dialog.close();
        });
        uppyHome.reset();
        self.props.coverImageLoaded = true;
        self.props.uploadingFiles = false;
      } else {
        console.log("failed files:", result.failed);
        self.props.uploadingFiles = false;
      }
    });
  }, 1000);
};

let uppyConfigGaleria = function (self) {
  setTimeout(() => {
    const uppyGallery = new Uppy({
      debug: false,
      locale: Spain,
      limit: 1,
      autoProceed: true,
      restrictions: {
        maxFileSize: 5242880,
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
        allowedFileTypes: [".jpg", ".jpeg", ".png", ".gif"],
      },
    })
      .use(Dashboard, {
        inline: true,
        target: ".DashboardContainerGaleria",
        replaceTargetContent: false,
        showProgressDetails: true,
        hideProgressAfterFinish: true,
        showRemoveButtonAfterComplete: true,
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: false,
        // note: "Images and video only, 2–3 files, up to 1 MB",
        height: "auto",
        width: "100%",
        locale: {
          strings: {
            browseFiles: "Subí Imágenes a Tu Galería",
            dropPasteImportFiles: "%{browseFiles} imágenes",
            dropPasteFiles: "%{browseFiles}",
          },
        },
        browserBackButtonClose: true,
      })
      .use(XHRUpload, {
        endpoint: `${frappeHost}linqr.linqr.doctype.utils.save_to_s3_resized`,
        headers: {
          "Linqr-Token": localStorage.getItem("token"),
        },
        formData: true,
        fieldName: "file",
      });

    // uppyGallery.setMeta({ bloqueId: bloque.name });
    uppyGallery.on("upload", (data) => {
      self.props.disableSave = true;
    });

    uppyGallery.on("complete", async (result) => {
      if (!self.props.formDataBloque["galeria_imagenes"]) {
        self.props.formDataBloque["galeria_imagenes"] = [];
      }

      if (!self.props.dataBloque["galeria_imagenes"]) {
        self.props.dataBloque["galeria_imagenes"] = [];
      }

      if (result.successful.length > 0) {
        await new Promise((resolve) => {
          result.successful.forEach(async (upload) => {
            let presigned_url = upload.response.body.message.presigned_url.url;
            let presigned_key =
              upload.response.body.message.presigned_url.fields.key;
            self.props.formDataBloque["galeria_imagenes"].push(
              presigned_url + presigned_key
            );
            self.props.dataBloque["galeria_imagenes"].push(
              presigned_url + presigned_key
            );
            resolve();
          });
        });
        self.props.disableSave = false;
        uppyGallery.reset();
        if (self.props.dataBloque["name"]) {
          crudBloques.updateBloque(self, self.props.dataBloque["name"]);
        } else {
          if (
            !self.props.formDataBloque["nombre_bloque"] ||
            self.props.formDataBloque["nombre_bloque"] === ""
          ) {
            self.props.formDataBloque["nombre_bloque"] = "Nueva Galería";
            self.props.dataBloque["nombre_bloque"] = "Nueva Galería";
          }
          crudBloques.createBloque(self).then((r) => {
            self.props.operation = "edit";
          });
        }
      } else {
        console.log("failed files:", result.failed);
        self.props.disableSave = false;
      }
    });
  }, 500);
};

export default {
  uppyConfig,
  uppyConfigProfile,
  uppyConfigHomePreview,
  uppyConfigProfileCover,
  uppyConfigGaleria,
};
