<template>
  <f7-sheet
    backdrop
    style="
      height: -webkit-fill-available;
      overflow: auto;
      margin: 2.5%;
      width: 95%;
      box-shadow: 0 0rem 2rem rgb(197 205 218);
      border-radius: 10px;
      position: fixed;
    "
    :opened="openMainVideoTutorial"
    :close-by-backdrop-click="false"
  >
    <f7-page-content>
      <f7-block class="margin-top no-margin-bottom" style="position: absolute; right: 0">
        <f7-row class="text-align-right padding-right-half">
          <f7-col>
            <f7-link @click="closeOnboardingSheet"
              ><i class="fas fa-times"></i
            ></f7-link>
          </f7-col>
        </f7-row>
      </f7-block>

      <f7-block
        class="no-margin no-padding"
        style="width: 100%; z-index: 0; height: 100%"
      >
        <f7-row>
          <f7-col
            class="display-flex justify-content-center"
            style="height: 100%"
          >
            <video
              :src="srcHelpVideo"
              style="
                width: 100%;
                display: block;
                position: absolute;
                object-fit: contain;
                border-radius: 10 px;
                height: 100%;
              "
              controls
            ></video>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { useStore } from "framework7-vue";
import store from "../../js/store";

export default {
  setup() {
    const openMainVideoTutorial = useStore("openMainVideoTutorial");
    const local = useStore("local");
    const srcHelpVideo = ""

    return {
      openMainVideoTutorial,
      local,
      srcHelpVideo
    };
  },
  methods: {
    sheetBeforeIn() {
      const self = this;

      if (local.onboarding_completo === 0) {
        openMainVideoTutorial = true;
      }
    },
    closeOnboardingSheet() {
      const self = this;
      store.dispatch("setOpenMainVideoTutorial", false);
    },
  },
};
</script>