<template>
  <!-- Titulo de Seccion -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'feed_instagram'"
    class="item-feed-instagram padding-vertical"
  >
    <f7-row v-if="instagramPosts">
      <f7-col width="33" v-for="post in instagramPosts" :key="post.id">
        <a
          @click="
            currentInstagramImage = post.media_url;
            currentInstagramCaption = post.caption;
            actionInstagramPost = true;
            registraEvento(bloque, 'click_bloque');
          "
        >
          <img
            class=""
            style="
              object-fit: cover;
              width: -webkit-fill-available;
              border-radius: 4px;
            "
            :src="post.media_url"
            alt=""
          />
        </a>
      </f7-col>
    </f7-row>

    <f7-actions
      :opened="actionInstagramPost"
      @actions:closed="actionInstagramPost = false"
    >
      <f7-actions-group
        class="padding text-align-center"
        style="background: #ffffff; text-align: center"
      >
        <f7-row>
          <f7-col>
            <img
              style="
                object-fit: cover;
                width: -webkit-fill-available;
                border-radius: 4px;
              "
              :src="currentInstagramImage"
              alt=""
            />
            <div class="padding">{{ currentInstagramCaption }}</div>
            <f7-button color="primary">VER EN INSTAGRAM</f7-button>
            <f7-button @click="actionInstagramPost = false" color="red">CERRAR</f7-button>
          </f7-col>
        </f7-row>
      </f7-actions-group>
    </f7-actions>
  </div>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";
import { useStore, f7 } from "framework7-vue";

export default {
  props: {
    bloque: Object,
    props: Object,
  },
  data() {
    return {
      currentInstagramImage: "",
      currentInstagramCaption: "",
      actionInstagramPost: false,
    };
  },
  setup() {
    const instagramPosts = useStore("instagramPosts");

    return {
      instagramPosts,
    };
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
  },
};
</script>
