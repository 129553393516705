<template>
  <!-- Titulo de Seccion -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'titulo_de_seccion'"
    class="item-titulo-de-seccion"
  >
    <f7-row class="text-align-center" style="width: 100%">
      <f7-col>
        <div class="item-title">{{ bloque.nombre_bloque }}</div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
export default {
  props: {
    bloque: Object,
  },
};
</script>
