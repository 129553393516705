<template>
  <span style="width: 95%" v-show="local && local.imagen_cover">
    <f7-block
      v-show="!props.coverImageLoaded"
      class="link-settings-preview-image-cover home-cover"
      style="background-color: #f1f4f7; height: 125px"
    >
      <span class="spinner-placeholder-right-top">
        <i class="fa fa-spinner fa-spin"></i
      ></span>
    </f7-block>

    <f7-block
      v-show="props.coverImageLoaded"
      class="no-margin no-padding home-cover"
    >
      <img
        id="cover-image-front"
        :src="local.imagen_cover"
        class="link-settings-preview-image-cover"
      />
    </f7-block>
  </span>
</template>

<script>
export default {
  props: {
    props: Object,
    local: Object,
  },
};
</script>