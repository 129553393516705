<template>
  <!-- SUBIDA DE ARCHIVO (UPPY) -->
  <li
    v-show="
      props.linkTypeId === 'enlace_simple' ||
      props.linkTypeId === 'imagen' ||
      props.linkTypeId === 'archivo' ||
      props.linkTypeId === 'micro_post' ||
      props.linkTypeId === 'producto' ||
      props.linkTypeId === 'producto_instagram'
    "
  >
    <span style="display: none">
      {{ props.formDataBloque }}
      {{ props.dataBloque }}
    </span>
    <div class="item-content item-input item-input-outline margin-bottom">
      <div class="item-inner">
        <!-- <div class="item-title item-label">{{ fieldLabel }}</div> -->
        <span style="margin-bottom: 10px"></span>
        <!-- IMAGEN -->
        <div class="item-input-wrap item-input-wrap-uppy">
          <!-- DASHBOARD DE UPPY -->
          <div v-show="!props.preloadedImage" class="DashboardContainer"></div>
          <!-- IMAGEN/ARCHIVO PRECARGADO -->
          <div v-show="props.preloadedImage" class="link-item-preview">
            <img :src="fileSource" class="link-item-preview-image" />
            <div class="link-item-remove-preview-image" @click="deleteImage">
              <i class="fa fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>

  <li v-show="props.linkTypeId === 'galeria_imagenes'">
    <div class="item-content item-input item-input-outline margin-bottom">
      <div class="item-inner">
        <div class="item-title item-label">{{ fieldLabel }}</div>
        <span style="margin-bottom: 10px"></span>
        <!-- IMAGEN -->
        <div class="item-input-wrap item-input-wrap-uppy">
          <!-- DASHBOARD DE UPPY -->
          <div class="DashboardContainerGaleria"></div>
          <!-- IMAGEN/ARCHIVO PRECARGADO -->
          <div class="link-item-preview" style="height: unset !important">
            <!-- <a :href="fileSource" class="external"
                ><i class="fa fa-download"></i> &nbsp;&nbsp;Descargar Archivo</a
              > -->
            <!-- Grilla de Imágenes -->
            <f7-row
              v-if="fileSourceGalery && fileSourceGalery.length > 0"
              class="
                margin-vertical
                justify-content-flex-start
                galery-container
              "
            >
              <f7-col
                v-for="(image, i) in fileSourceGalery"
                :key="image"
                :data-src="image"
                style="height: 100px; width: calc(33% - 8px); margin: 4px"
                class="galery-item"
              >
                <span
                  style="
                    width: 100%;
                    height: 100%;
                    padding: 0px;
                    display: block;
                    margin: 0 auto;
                    border-radius: 5px;
                    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
                  "
                >
                  <img
                    :src="image"
                    alt=""
                    style="
                      width: 100%;
                      height: 100%;
                      display: block;
                      object-fit: contain;
                    "
                  />
                  <div
                    class="link-item-remove-preview-image-galery"
                    @click="deleteImageFromGallery(i, image)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                </span>
              </f7-col>
            </f7-row>
          </div>
        </div>
      </div>
    </div>
  </li>

  <!-- PRODUCTO | ACCION DE BOTON -->
  <li v-show="props.linkTypeId === 'galeria_imagenes'">
    <div class="item-content item-input item-input-outline">
      <div class="item-inner">
        <div class="item-title item-label">Tipo de Galeria</div>
        <f7-list class="radio-list">
          <f7-list-item
            radio
            radio-icon="end"
            title="Ordenada"
            name="tipo_galeria_imagenes"
            :checked="
              props.dataBloque.tipo_galeria_imagenes === 'ordenada'
                ? true
                : false
            "
            @change="(e) => setValueForm(e, 'ordenada')"
          ></f7-list-item>

          <f7-list-item
            radio
            radio-icon="end"
            title="Masonry (Estilo Pinterest)"
            name="tipo_galeria_imagenes"
            :checked="
              props.dataBloque.tipo_galeria_imagenes === 'masonry'
                ? true
                : false
            "
            @change="(e) => setValueForm(e, 'masonry')"
          ></f7-list-item>
        </f7-list>
      </div>
    </div>
  </li>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
import utils from "../../../../js/utils";
import crudBloques from "../../../../js/crud-bloques";
import { Sortable } from "@shopify/draggable";

export default {
  props: {
    props: Object,
  },
  data() {
    return {
      fieldLabel: "",
      fileSource: "",
      fileSourceGalery: [],
    };
  },
  mounted() {
    const self = this;

    setTimeout(() => {
      const sortable = new Sortable(
        document.querySelectorAll(".galery-container"),
        {
          draggable: ".galery-item",
        }
      );
    }, 2000);
  },
  updated() {
    const self = this;

    if (self.props.linkTypeId === "enlace_simple") {
      self.fieldLabel = "Vista Previa";
      self.fileSource = self.props.dataBloque.vista_previa_url;
    } else if (self.props.linkTypeId === "imagen") {
      self.fieldLabel = "Imagen";
      self.fileSource = self.props.dataBloque.imagen_url;
    } else if (self.props.linkTypeId === "archivo") {
      self.fieldLabel = "Archivo";
      self.fileSource = self.props.dataBloque.archivo_url;
    } else if (
      self.props.linkTypeId === "micro_post" ||
      self.props.linkTypeId === "producto" ||
      self.props.linkTypeId === "producto_instagram"
    ) {
      self.fieldLabel = "Imagen Principal";
      self.fileSource = self.props.dataBloque.vista_previa_url;
    } else if (self.props.linkTypeId === "galeria_imagenes") {
      self.fieldLabel = "Galería";
      self.fileSourceGalery = self.props.dataBloque.galeria_imagenes;
    }
  },
  methods: {
    deleteImage() {
      const self = this;
      self.props.preloadedImage = false;

      if (self.props.linkTypeId == "archivo") {
        self.removeFileFromS3(self, self.props.linkTypeId, "archivo_url");
        self.props.formDataBloque["archivo_url"] = "";
        self.props.dataBloque["archivo_url"] = "";
      } else if (self.props.linkTypeId == "imagen") {
        self.removeFileFromS3(self, self.props.linkTypeId, "imagen_url");
        self.props.formDataBloque["imagen_url"] = "";
        self.props.dataBloque["imagen_url"] = "";
      } else if (
        self.props.linkTypeId == "enlace_simple" ||
        self.props.linkTypeId == "producto" ||
        self.props.linkTypeId == "producto_instagram"
      ) {
        self.removeFileFromS3(self, self.props.linkTypeId, "vista_previa_url");
        self.props.formDataBloque["vista_previa_url"] = "";
        self.props.dataBloque["vista_previa_url"] = "";
      }
    },
    removeFileFromS3(self, blockType, fileName) {
      debugger;
      utils.axiosCall(
        "post",
        "linqr",
        "bloque",
        "remove_file_from_bloque",
        {
          bloque_name: self.props.dataBloque["name"],
          bloque_type: blockType,
          file_name: fileName ? fileName : self.props.dataBloque[blockType],
        },
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          f7.dialog.close();
          crudBloques.updateBloque(self, self.props.dataBloque["name"]);
          crudBloques.getBloquesLocal(self);
          crudBloques.getBloquesLocalPublic(self);
        },
        function (e) {}
      );
    },
    deleteImageFromGallery(imageGaleryIx, fileName) {
      const self = this;
      f7.dialog.preloader("Eliminando Imagen");

      if (!self.props.formDataBloque["imagen_de_galeria_a_borrar"]) {
        self.props.formDataBloque["imagen_de_galeria_a_borrar"] = [];
      }
      self.props.formDataBloque["imagen_de_galeria_a_borrar"].push(
        self.props.dataBloque.galeria_imagenes.splice(imageGaleryIx, 1)
      );
      // self.imagenes_de_galeria_a_borrar++;

      self.props.formDataBloque["galeria_imagenes"] =
        self.props.dataBloque.galeria_imagenes;

      self.removeFileFromS3(self, "galeria_imagenes", fileName);
    },
    setValueForm(e, radioValue) {
      const self = this;
      crudBloques.setValueForm({ props: self.props }, e, radioValue);
    },
  },
};
</script>