<template>
  <f7-block v-if="local" class="block-social-media margin-bottom-half margin-top-half">
    <f7-row class="justify-content-center">
      <f7-col
        width="20"
        v-for="rrss in local.rrss"
        :key="rrss"
        class="text-align-center padding-vertical-half"
      >
        <a
          :href="rrss.url_red_social + rrss.usuario_red_social"
          target="_blank"
          class="external block-social-media-icon"
          :style="`color: ${mainColorHex} !important`"
        >
          <i :class="rrss.icono_red_social"></i>
        </a>
      </f7-col>
      <!-- WhatsApp -->
      <f7-col v-if="local.whatsapp" width="20" class="text-align-center padding-vertical-half">
        <a
          :href="'https://wa.me/' + local.whatsapp"
          target="_blank"
          class="external block-social-media-icon"
          :style="`color: ${mainColorHex} !important`"
        >
          <i class="fab fa-whatsapp"></i>
        </a>
      </f7-col>
      <!-- Compartir -->
      <f7-col width="20" class="text-align-center padding-vertical-half" v-if="isMobile()">
        <a
          class="external block-social-media-icon"
          :style="`color: ${mainColorHex} !important`"
          @click="nativeShare"
        >
          <i class="fas fa-share-alt"></i>
        </a>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { useStore, f7 } from "framework7-vue";

export default {
  props: {
    props: Object,
    f7router: Object,
  },
  setup() {
    const local = useStore("local");
    const backgroundColorHex = useStore("backgroundColorHex");
    const mainColorHex = useStore("mainColorHex");

    return {
      local,
      backgroundColorHex,
      mainColorHex,
    };
  },
  methods: {
    nativeShare() {
      const self = this;
      const localCap = "";

      if (self.local.nombre !== undefined) {
        localCap =
          self.local.nombre.charAt(0).toUpperCase() +
          self.local.nombre.slice(1);
      }

      if (navigator.share) {
        navigator
          .share({
            title: `${localCap}`,
            text: `${self.local.nombre_local} 👉`,
            url: self.local.url_local,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log(error));
      } else {
        self.f7router.navigate("/admin/share/");
      }
    },
    isMobile() {
      return (f7.device.desktop === false);
    },
  },
};
</script>

