import { f7, useStore } from "framework7-vue";
import QRCodeStyling from "qr-code-styling";
import store from "./store";
import utils from "./utils";
import { toPng, toJpeg, toBlob } from "html-to-image";

let getCustomQRCode = (self) => {
  utils.axiosCall(
    "post",
    "linqr",
    "codigo_qr_local",
    "get_codigo_qr_personalizado",
    {},
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      store.dispatch("customizeQRText", r.data.message.data.texto_superior);
      store.dispatch("customizeQRSubText", r.data.message.data.texto_inferior);
      store.dispatch("customizeQRImage", r.data.message.data.muestra_logo);
      store.dispatch("customizeQRStyle", r.data.message.data.estilo);
      store.dispatch("customizeQRDotColor", r.data.message.data.color_puntos);
      store.dispatch(
        "customizeQRCornerColor",
        r.data.message.data.color_esquineros
      );
      store.dispatch(
        "customizeQRDotCornerColor",
        r.data.message.data.color_interior_esquineros
      );

      self.buildQRCode();
      self.loading = false;
    },
    function (e) {}
  );
};

let buildQRCode = (self) => {
  var qrCode = new QRCodeStyling({
    width: 1200,
    height: 1200,
    data: self.local.url_local,
    // image: self.local.imagen_perfil ? self.local.imagen_perfil : "",
    dotsOptions: {
      color: self.customQRDotColor,
      type: self.customQRStyle === "square" ? "square" : "dots",
    },
    backgroundOptions: {
      color: "transparent",
    },
    cornersSquareOptions: {
      color: self.customQRCornerColor,
      type: self.customQRStyle === "square" ? "square" : "extra-rounded",
    },
    cornersDotOptions: {
      color: self.customQRDotCornerColor,
      type: self.customQRStyle,
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 20,
    },
  });

  let qrElement = document.getElementById("qrcode");

  // setTimeout(() => {
  qrCode.append(qrElement);
  store.dispatch("customizeQR", qrCode);
};

let generateQrFile = async (
  self,
  imgDisplay,
  varData,
  fileName,
  elementIdToDownload,
  exportQRType
) => {
  var downloadWidthSize = imgDisplay[0].offsetWidth * 1;
  var downloadHeightSize = imgDisplay[0].offsetHeight * 1;

  return new Promise((resolve_1) => {
    if (imgDisplay.length > 0) {
      toBlob(imgDisplay[0], {
        canvasWidth: downloadWidthSize,
        canvasHeight: downloadHeightSize,
      }).then(async (blob) => {
        await new Promise((resolve_2) => {
          const formData = new FormData();
          formData.append("file", blob, fileName);

          if (exportQRType === "print") {
            saveToS3(self, formData, varData, elementIdToDownload).then(
              (imageToPrint) => {
                resolve_2(true);
                resolve_1(imageToPrint);
              }
            );
          } else if (exportQRType === "download") {
            saveToS3(self, formData, varData, elementIdToDownload).then(() => {
              resolve_2(true);
              resolve_1(true);
            });
          } else if (exportQRType === "share") {
            self.props[varData] = blob;
            resolve_2(true);
            resolve_1(true);
          }
        });
      });
    } else {
      setTimeout(() => {
        self.generateQrFile(
          self,
          imgDisplay,
          varData,
          fileName,
          elementIdToDownload,
          exportQRType
        );
      }, 1000);
    }
  });
};

let saveToS3 = async (self, formData, varData, elementIdToDownload) => {
  return new Promise((resolve) => {
    utils.axiosCall(
      "post",
      "linqr",
      "codigo_qr_local",
      "save_qr_to_s3_protected",
      formData,
      {
        "Content-Type": "multipart/form-data",
        "Linqr-Token": localStorage.getItem("token"),
      },
      function (r) {
        let presigned_url = r.data.message.presigned_url.url;
        let presigned_key = r.data.message.presigned_url.fields.key;

        self.props[varData] = presigned_url + presigned_key;

        if (elementIdToDownload) {
          f7.$(elementIdToDownload).attr("href", self.props[varData]);
        }
        resolve(self.props[varData]);
      },
      function (e) {}
    );
  });
};

export default {
  getCustomQRCode,
  buildQRCode,
  generateQrFile,
};
