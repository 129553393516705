import utils from "./utils.js";
import store from "./store.js";
import account from "./account.js";
import theming from "./theming.js";
import billing from "./billing.js";
import crudBloques from "./crud-bloques.js";

let getLocal = async function (self, getPublicBloques = false) {
  return new Promise((resolve) => {
    utils.axiosCall(
      "post",
      "linqr",
      "local",
      "get_local",
      {
        // hostname: location.hostname,
      },
      {
        "Linqr-Token": localStorage.getItem("token"),
      },
      function (r) {
        if (r.data.message.error_code === 401) {
          throw 401;
        }

        store.dispatch("setLocal", r.data.message.data);
        store.dispatch(
          "setOpenOnboardingSheet",
          !r.data.message.data.onboarding_completo
        );
        getSuscripcion(r.data.message.data);
        theming.updateColors();
        crudBloques.getBloquesLocal();

        if (self && self.props) {
          self.props.loading = false;
        }
        resolve(r.data.message.data);
      },
      function (e) {
        if (e === 401) {
          account.signOut();
        }
      }
    );
  });
};

let getLocalIdByUrl = (self, nombreUnicoLocal, currentLocalURL) => {
  utils.axiosCall(
    "post",
    "linqr",
    "local",
    "get_local_by_url",
    {
      url_local: `https://${currentLocalURL}`,
      nombre_unico_local: nombreUnicoLocal,
    },
    {},
    function (r) {
      store.dispatch("setLocal", r.data.message.data);
      crudBloques.getBloquesLocalPublic(self);
      theming.updateColors();
      registraVisita(r.data.message.data.name);

      document.title = r.data.message.data.nombre_local;
      const favicon16 = document.querySelector("#favicon16");
      favicon16.href = r.data.message.data.imagen_perfil;
      const favicon32 = document.querySelector("#favicon32");
      favicon32.href = r.data.message.data.imagen_perfil;

      if (self.props) {
        self.props.loading = false;
      }
    },
    function (e) {
      console.error(e);
    }
  );
};

let registraVisita = (local) => {
  utils.axiosCall(
    "post",
    "linqr",
    "local",
    "registra_visita",
    {
      local: local,
    },
    {},
    function (r) {},
    function (e) {}
  );
};

let getSuscripcion = (localDoc) => {
  utils.axiosCall(
    "post",
    "linqr",
    "suscripcion",
    "get_suscripcion",
    {
      local: localDoc.name,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.data.vencimiento_suscripcion) {
        r.data.message.data.vencimiento_suscripcion =
          r.data.message.data.vencimiento_suscripcion
            .split(".")[0]
            .replace(" ", "T");
        billing.checkEndFreeTrial();
      }
      store.dispatch("setSuscripcion", r.data.message.data);
      // billing.checkSuscription();
    },
    function (e) {
      console.log(e);
    }
  );
};

let checkIfMiqrositioExist = async (nombreUnicoLocal, localURL) => {
  return new Promise((resolve) => {
    utils.axiosCall(
      "post",
      "linqr",
      "local",
      "check_if_miqrositio_exist",
      {
        nombre_unico_local: nombreUnicoLocal,
        url_local: `https://${localURL}`,
      },
      {},
      function (r) {
        resolve(r);
      },
      function (e) {
        console.log(e);
      }
    );
  });
};

export default {
  getLocal,
  getLocalIdByUrl,
  getSuscripcion,
  checkIfMiqrositioExist,
};
