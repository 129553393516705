<template>
  <!-- Texto -->
  <div
    v-show="bloque.id_tipo_de_bloque === 'producto'"
    class="item-texto link-item-list-preview"
  >
    <!-- IMAGEN -->
    <img
      :src="bloque.vista_previa_url"
      style="
        /*width: 100%;*/
        height: auto;
        border-radius: 10px;
        object-fit: contain;
        display: block;
        max-height: 250px;
        padding: 0;
      "
    />
    <!-- CODIGO -->
    <f7-block
      v-if="bloque.vista_previa_url && bloque.codigo_interno"
      class="no-margin padding-top"
      style="width: 100%"
      :style="
        bloque.vista_previa_url
          ? 'margin-top: -50px !important'
          : 'text-align: center;'
      "
    >
      <f7-row>
        <f7-col>
          <f7-badge class="item-product-sku-badge"
            >Código: {{ bloque.codigo_interno }}</f7-badge
          >
        </f7-col>
      </f7-row>
    </f7-block>
    <!-- CONTENIDO -->
    <f7-block class="no-margin padding" style="width: 100%">
      <!-- NOMBRE -->
      <f7-row class="justify-content-center text-align-center">
        <div class="item-title">{{ bloque.nombre_bloque }}</div>
      </f7-row>
      <!-- DESCRIPCION -->
      <f7-row class="justify-content-center">
        <f7-col>
          <div class="text-align-center item-description">{{ bloque.texto }}</div>
          <!-- <div>
            <f7-badge class="item-product-sku-badge"
              >Disponibilidad: {{ bloque.stock }}</f7-badge
            >
          </div> -->
        </f7-col>
      </f7-row>
      <!-- PRECIO -->
      <f7-row v-if="parseFloat(bloque.importe) > 0">
        <f7-col class="text-align-center padding-top">
          <span
            :class="
              parseFloat(bloque.importe_descuento) > 0
                ? 'item-product-price-discount'
                : 'item-product-price'
            "
          >
            {{ bloque.moneda_importe }}
            {{ parseFloat(bloque.importe).toFixed(2) }}
          </span>
          &nbsp;&nbsp;
          <span
            v-if="parseFloat(bloque.importe_descuento) > 0"
            class="item-product-price"
          >
            {{ bloque.moneda_importe }}
            {{ parseFloat(bloque.importe_descuento).toFixed(2) }}
          </span>
        </f7-col>
      </f7-row>
      <!-- CTA -->
      <f7-row class="padding-top" v-if="bloque.texto_boton_cta">
        <f7-col>
          <f7-button
            v-if="bloque.accion_boton_cta_producto === 'consulta-whatsapp'"
            fill
            class="preview-btn"
            @click="
              (e) => {
                registraEvento(
                  e,
                  bloque,
                  'click_bloque',
                  true,
                  buildWhatsAppLink(bloque)
                );
              }
            "
            ><span style="margin-top: 0px">{{
              bloque.texto_boton_cta
            }}</span></f7-button
          >
          <f7-button
            v-if="bloque.accion_boton_cta_producto === 'consulta-email'"
            fill
            class="preview-btn"
            @click="
              (e) => {
                consultaEmail(e, bloque);
              }
            "
            ><span style="margin-top: 0px">{{
              bloque.texto_boton_cta
            }}</span></f7-button
          >
          <f7-button
            v-if="bloque.accion_boton_cta_producto === 'redireccionar-url'"
            fill
            class="external preview-btn"
            :href="bloque.enlace_boton_cta"
            @click="
              (e) => {
                registraEvento(
                  e,
                  bloque,
                  'click_bloque',
                  true,
                  bloque.enlace_boton_cta
                );
              }
            "
            ><span style="margin-top: 0px">{{
              bloque.texto_boton_cta
            }}</span></f7-button
          >
          <f7-button
            v-if="
              bloque.accion_boton_cta_producto === 'mercadopago' &&
              bloque.importe > 0
            "
            fill
            class="external preview-btn"
            @click="
              (e) => {
                registraEvento(
                  e,
                  bloque,
                  'click_bloque',
                  true,
                  buildMercadoPagoLink(bloque)
                );
              }
            "
            ><span style="margin-top: 0px">{{
              bloque.texto_boton_cta
            }}</span></f7-button
          >
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
import crudBloques from "../../../js/crud-bloques";

export default {
  props: {
    bloque: Object,
    local: Object,
  },
  mounted() {
    const self = this;
    // self.convertStringIntoHtml();
  },
  methods: {
    async registraEvento(
      e,
      bloque,
      evento,
      redirect = false,
      redirectUrl = ""
    ) {
      e.preventDefault();
      const self = this;
      let redirectUrlResolved = await new Promise((resolve) =>
        resolve(redirectUrl)
      );

      crudBloques.registraEvento(
        self,
        bloque,
        evento,
        redirect,
        redirectUrlResolved
      );
    },
    async buildWhatsAppLink(bloque) {
      const self = this;

      let whatsappMessage = await new Promise((resolve) => {
        if (bloque.codigo_interno && bloque.codigo_interno !== "") {
          resolve(
            `Hola! Me interesa la publicación *${bloque.nombre_bloque} (${bloque.codigo_interno})* que vi en su sitio web.`
          );
        } else {
          resolve(
            `Hola! Me interesa la publicación *${bloque.nombre_bloque}* que vi en su sitio web.`
          );
        }
      });

      return `https://wa.me/${self.local.whatsapp}/?text=${encodeURI(
        whatsappMessage
      )}`;
    },
    async buildMercadoPagoLink(bloque) {
      const self = this;
      f7.dialog.preloader("Redirigiendo a Mercado Pago");

      let mercadoPagoLink = await crudBloques.buildMercadoPagoLink(
        self,
        bloque,
        self.local
      );

      return mercadoPagoLink;
    },
    consultaEmail(e, bloque) {
      const self = this;

      crudBloques.sendEmailForProduct(self, bloque, self.local);
      self.registraEvento(e, bloque, "click_bloque");
    },
    // convertStringIntoHtml() {
    //   const self = this;

    //   setTimeout(function () {
    //     var el = f7.$(`#${CSS.escape(self.bloque.name)}`);
    //     // if (!self.loadedOnce) {
    //     el[0].insertAdjacentHTML("afterbegin", self.bloque.texto);
    //     self.loadedOnce = true;
    //     // }
    //   }, 1000);
    // },
  },
};
</script>
