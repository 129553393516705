<template>
  <f7-panel class="help-panel" left cover>
    <f7-view>
      <f7-page>
        <f7-navbar class="no-shadow">
          <template #title>Ayuda</template>
          <template #right>
            <span class="padding-right-half">
              <f7-button class="btn-links btn-links-red" panel-close
              ><i class="fas fa-times"></i
            ></f7-button>
            </span>
          </template>
        </f7-navbar>
        <f7-block class="no-printme no-margin no-padding">
          <f7-row class="margin-vertical">
            <f7-col class="margin-horizontal">
              <div class="margin-vertical">
                Desde esta sección vas a poder configurar todos los bloques que
                serán visibles en tu MiQRositio
              </div>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>
</template>
