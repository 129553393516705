import { f7, useStore } from "framework7-vue";
import utils from "./utils";
import store from "./store";

let checkEndFreeTrial = () => {
  const suscripcion = useStore("suscripcion");
  let endFreeTrial = new Date(
    `${suscripcion.value.vencimiento_suscripcion}T00:00:00`
  );
  let alreadyDismissed = localStorage.getItem("dismiss_end_free_trial");
  let daysDiff = Math.floor((endFreeTrial - Date.now()) / (1000 * 60 * 60));

  if (!alreadyDismissed || alreadyDismissed === false) {
    if (daysDiff <= 24) {
      store.dispatch("setOpenEndFreeTrialSheet", true);
    }
  }
};

let checkSuscription = () => {
  // const suscripcion = useStore("suscripcion");
  // if (suscripcion.estado_suscripcion !== "Activo") {
  //   store.dispatch("setDisabledSuscriptionSheet", true);
  // }
};

export default {
  checkEndFreeTrial,
  checkSuscription,
};
