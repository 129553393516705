<template>
  <f7-actions
    :opened="props.actionsPrintQR"
    @actions:closed="props.actionsPrintQR = false"
  >
    <f7-actions-group>
      <f7-actions-label>Imprimir</f7-actions-label>
      <f7-actions-button
        @click="printPlantilla"
        ><i class="fas fa-print"></i>&nbsp;&nbsp;Imprimir
        Plantilla</f7-actions-button
      >
      <f7-actions-button
        @click="printQRCode"
        ><i class="fas fa-print"></i>&nbsp;&nbsp;Imprimir Solo Código
        QR</f7-actions-button
      >
    </f7-actions-group>
  </f7-actions>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  methods: {
    printPlantilla() {
      const self = this;
      self.props.actionSelectPrintSizeOpen = true;
      self.props.printTemplate = true;
    },
    printQRCode() {
      const self = this;
      self.props.actionSelectPrintSizeOpen = true
      self.props.printQRCode = true
    }
  }
};
</script>