import { useStore, f7 } from "framework7-vue";
import utils from "./utils";
import fileUploader from "./file-uploader";
import store from "./store";
import theming from "./theming";
import crudLocal from "./crud-local";
const axios = require("axios").default;

let getTiposDeBloques = function (self) {
  utils.axiosCall(
    "post",
    "linqr",
    "tipo_de_bloque",
    "get_tipos_bloques",
    {},
    {},
    function (r) {
      let linksType = r.data.message.data;
      let availableLinksType = [];

      for (let i = 0; i < linksType.length; i++) {
        if (
          self.suscripcion.nombre_suscripcion_actual === "Gratuito" &&
          linksType[i].nombre_suscripcion_asociado === "Pro"
        ) {
        } else {
          availableLinksType.push(linksType[i]);
        }

        if (linksType.length === i + 1) {
          store.dispatch("setLinksType", availableLinksType);
          self.loading = false;
        }
      }
    },
    function (e) {}
  );
};

let getTipoDeBloque = function (self, tipoDeBloqueName, callback) {
  utils.axiosCall(
    "post",
    "linqr",
    "tipo_de_bloque",
    "get_tipo_bloque",
    {
      bloque_id: tipoDeBloqueName,
    },
    {},
    function (r) {
      callback(r.data.message.data);
    },
    function (e) {}
  );
};

let getTipoDeBloqueHijo = function (self, callback) {
  let linkTypeLS = JSON.parse(localStorage.getItem("block_type"));

  utils.axiosCall(
    "post",
    "linqr",
    "tipo_de_bloque",
    "get_tipo_bloque_hijo",
    {
      bloque_id: linkTypeLS.name,
    },
    {},
    function (r) {
      callback(r.data.message.data);
    },
    function (e) {
      self.props.loading = false;
    }
  );
};

let getBloquesLocal = async (self) => {
  const local = useStore("local").value;

  return new Promise((resolve, reject) => {
    utils.axiosCall(
      "post",
      "linqr",
      "bloque",
      "get_bloques_local",
      {},
      {
        "Linqr-Token": localStorage.getItem("token"),
      },
      function (r) {
        store.dispatch("setBloquesLocal", r.data.message.data);
        personalizeHomePreview(self, local);
        resolve();
        // self.props.loading = false;
      },
      function (e) {
        console.error(e);
        reject();
      }
    );
  });
};

let getBloquesLocalPublic = (self) => {
  const local = useStore("local").value;
  const suscripcion = useStore("suscripcion");

  utils.axiosCall(
    "post",
    "linqr",
    "bloque",
    "get_bloques_local_public",
    {
      local: local.name,
    },
    {},
    function (r) {
      let bloquesLocal = r.data.message.data;
      let availableBloquesLocal = [];

      for (let i = 0; i < bloquesLocal.length; i++) {
        if (
          suscripcion.nombre_suscripcion_actual === "Gratuito" &&
          bloquesLocal[i].suscripcion_de_bloque === "Pro"
        ) {
        } else {
          availableBloquesLocal.push(bloquesLocal[i]);
        }

        if (bloquesLocal.length === i + 1) {
          store.dispatch("setBloquesLocal", availableBloquesLocal);
        }
      }
      personalizeHomePreview(self, local);
    },
    function (e) {}
  );
};

let personalizeHomePreview = (self, local) => {
  setTimeout(() => {
    fitVideos();

    // getInstaFeed(self);
    // if (local && local.ig_user) {
    //   getInstagramPosts(local.ig_user, local.ig_access_token);
    // }
    theming.updateColors();
    initializeUppy(self, local);

    // self.props.loading = false;
  }, 100);
};

let initializeUppy = async (self, local) => {
  let bloquesLocal = useStore("bloquesLocal").value;
  let uppyElements = document.querySelectorAll(
    "div[class^='DashboardContainerHome']"
  );
  let formExists = bloquesLocal.some((bloque) => bloque.form_archivos === 1);

  if (formExists) {
    if (uppyElements.length > 0) {
      fileUploader.uppyConfigHomePreview(self, local);
    } else {
      setTimeout(() => {
        initializeUppy(self, local);
      }, 500);
    }
  }
};

let fitVideos = () => {
  // List of Video Vendors embeds you want to support
  var players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];

  // Select videos
  var fitVids = document.querySelectorAll(players.join(","));

  // If there are videos on the page...
  if (fitVids.length) {
    // Loop through videos
    for (var i = 0; i < fitVids.length; i++) {
      // Get Video Information
      var fitVid = fitVids[i];
      var width = fitVid.getAttribute("width");
      var height = fitVid.getAttribute("height");
      var aspectRatio = height / width;
      var parentDiv = fitVid.parentNode;

      // Wrap it in a DIV
      var div = document.createElement("div");
      div.className = "fitVids-wrapper";
      div.style.paddingBottom = aspectRatio * 100 + "%";
      parentDiv.insertBefore(div, fitVid);
      fitVid.remove();
      div.appendChild(fitVid);

      // Clear height/width from fitVid
      fitVid.removeAttribute("height");
      fitVid.removeAttribute("width");
    }
  }
};

let getBloque = (self, linkId, callback = null) => {
  utils.axiosCall(
    "post",
    "linqr",
    "bloque",
    "get_bloque",
    {
      bloque_id: linkId,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      let responseData = r.data.message.data;
      localStorage.setItem(
        "block_data",
        JSON.stringify(responseData.bloque_doc)
      );
      localStorage.setItem(
        "block_type",
        JSON.stringify(responseData.tipo_bloque_doc)
      );

      self.props.linkTypeName = responseData.bloque_doc.nombre_tipo_de_bloque;
      self.props.linkTypeId = responseData.bloque_doc.id_tipo_de_bloque;
      self.props.dataBloque = responseData.bloque_doc;
      self.props.loading = false;

      callback();

      if (
        responseData.bloque_doc.imagen_url ||
        responseData.bloque_doc.archivo_url ||
        responseData.bloque_doc.vista_previa_url
      ) {
        self.props.preloadedImage = true;
        self.props.fileUploaded = true;
      }
    },
    function (e) {}
  );
};

let createBloque = async (self) => {
  // self.props.loading = true;
  self.props.formDataBloque["tipo_de_bloque"] = self.props.linkTypeLS.name;

  // f7.sheet.close();
  f7.dialog.preloader("Creando el Bloque");

  // if (creatingParent) {
  //   self.props.formDataBloque["es_padre"] = true;
  // } else if (creatingChild) {
  //   let parentBlockName = JSON.parse(localStorage.getItem("parent_block")).name;
  //   self.props.formDataBloque["es_hijo"] = true;
  //   self.props.formDataBloque["padre"] = parentBlockName;
  // }

  // self.props.formDataBloque["nombre_bloque"] =
  //   self.props.formDataBloque["nombre_bloque"] || self.props.linkTypeName;

  return new Promise((resolve) => {
    utils.axiosCall(
      "post",
      "linqr",
      "bloque",
      "create_bloque",
      {
        data_bloque: self.props.formDataBloque,
      },
      {
        "Linqr-Token": localStorage.getItem("token"),
      },
      function (r) {
        if (r.data.message.success === true) {
          // if (creatingParent) {
          //   localStorage.setItem(
          //     "parent_block",
          //     JSON.stringify(r.data.message.data)
          //   );
          // } else if (creatingChild) {
          //   f7.sheet.close();
          //   let parentBlockName = JSON.parse(localStorage.getItem("parent_block"))
          //     .name;
          //   f7.views.main.router.navigate(`/admin/link/${parentBlockName}/`, {
          //     ignoreCache: true,
          //     options: {
          //       props: {
          //         operation: "edit",
          //       },
          //     },
          //   });
          // }
          // f7.views.main.router.navigate("/admin/links");
          saveSortedLinks(self, "", r.data.message.data.name, "create-bloque");

          utils.toastConfig.text = r.data.message.message;
          utils.toastConfig.cssClass = "success-toast";
          f7.toast.create(utils.toastConfig).open();

          // f7.sheet.close();

          setTimeout(() => {
            getBloquesLocal(self);
            // getBloquesLocalPublic(self);
            self.props.loading = false;
            f7.dialog.close();

            self.props.linkId = r.data.message.data.name;
            resolve(r.data.message.data);
          }, 500);
        } else {
          utils.toastConfig.text = r.data.message.message;
          utils.toastConfig.cssClass = "error-toast";
          f7.toast.create(utils.toastConfig).open();
          self.props.loading = false;
        }
      },
      function (e) {}
    );
  });
};

let updateBloque = async (self, linkId) => {
  f7.dialog.preloader("Actualizando el Bloque");

  await new Promise((resolve) => {
    self.props.formDataBloque["galeria_imagenes"] = [];
    let DOMGalleryItems = document.querySelectorAll(".galery-item");

    if (DOMGalleryItems.length === 0) {
      resolve();
    }
    DOMGalleryItems.forEach((item, i) => {
      self.props.formDataBloque["galeria_imagenes"].push(item.dataset.src);

      if (DOMGalleryItems.length === i + 1) {
        resolve();
      }
    });
  });

  utils.axiosCall(
    "post",
    "linqr",
    "bloque",
    "update_bloque",
    {
      bloque_id: linkId,
      data_bloque: self.props.formDataBloque,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();

        // Trasladar a utils.js
        // if (
        //   self.props.linkTypeId === "imagen_de_galeria" ||
        //   self.props.linkTypeId === "item_de_catalogo"
        // ) {
        //   f7.views.main.router.navigate(
        //     `/admin/link/${self.props.dataBloque.padre}/`,
        //     {
        //       ignoreCache: true,
        //     }
        //   );
        //   f7.sheet.close();
        // } else {
        //   f7.sheet.close();
        // }

        setTimeout(() => {
          getBloquesLocal(self);
          // getBloquesLocalPublic(self);
          f7.dialog.close();
        }, 500);
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
      }
    },
    function (e) {}
  );
};

let saveSortedLinks = async (self, oldBlockId = "", newBloqueId = "", type) => {
  var newOrderBloques = [];
  var bloques = document.querySelectorAll("li.link-item-list");
  var bloquesHomePreview = document.querySelectorAll(
    "li.link-item-list-home-preview"
  );

  await (async () => {
    new Promise((resolve) => {
      if (type === "order-bloques") {
        for (var i = 0, max = bloques.length; i < max; i++) {
          newOrderBloques.push(bloques[i].dataset.bloqueId);
          if (bloques.length === i + 1) {
            resolve;
          }
        }
      } else if (type === "create-bloque") {
        newOrderBloques.push(newBloqueId);

        for (var i = 0, max = bloquesHomePreview.length; i < max; i++) {
          newOrderBloques.push(bloquesHomePreview[i].dataset.bloqueId);
          if (bloquesHomePreview.length === i + 1) {
            resolve;
          }
        }
      } else if (type === "clone-bloque") {
        for (var i = 0, max = bloquesHomePreview.length; i < max; i++) {
          if (oldBlockId === bloquesHomePreview[i].dataset.bloqueId) {
            newOrderBloques.push(newBloqueId);
            newOrderBloques.push(bloquesHomePreview[i].dataset.bloqueId);
          } else {
            newOrderBloques.push(bloquesHomePreview[i].dataset.bloqueId);
          }
          if (bloquesHomePreview.length === i + 1) {
            resolve;
          }
        }
      }
    });
  })();

  // console.log(newOrderBloques);

  utils.axiosCall(
    "post",
    "linqr",
    "bloque",
    "sort_links",
    {
      new_order_bloques: JSON.stringify(newOrderBloques),
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      // self.sortLinksEnabled = false;
    },
    function (e) {}
  );
};

let cloneBloque = (self, linkId) => {
  f7.dialog.preloader("Clonando el Bloque");

  utils.axiosCall(
    "post",
    "linqr",
    "bloque",
    "clone_bloque",
    {
      bloque_id: linkId,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        f7.toast.create(utils.toastConfig).open();

        // Trasladar a utils.js
        // if (
        //   self.props.linkTypeId === "imagen_de_galeria" ||
        //   self.props.linkTypeId === "item_de_catalogo"
        // ) {
        //   f7.views.main.router.navigate(
        //     `/admin/link/${self.props.dataBloque.padre}/`,
        //     {
        //       ignoreCache: true,
        //     }
        //   );
        //   f7.sheet.close();
        // } else {
        //   // f7.views.main.router.navigate("/admin/links");
        //   f7.sheet.close();
        // }
        saveSortedLinks(self, linkId, r.data.message.data.name, "clone-bloque");

        setTimeout(() => {
          getBloquesLocal(self);
          // getBloquesLocalPublic(self);
          f7.sheet.close();

          setTimeout(() => {
            self.props.loading = false;
            f7.dialog.close();
            f7.views.main.router.navigate(
              `/admin/link/${r.data.message.data.name}/`
            );
          }, 500);
        }, 500);
      } else {
        // utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.text = "No se ha podido clonar el Bloque";
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();

        self.props.loading = false;
        f7.dialog.close();
      }
    },
    function (e) {}
  );
};

let deleteBloque = (self, linkId) => {
  // self.props.loading = true;
  f7.dialog.preloader("Eliminando el Bloque");

  utils.axiosCall(
    "post",
    "linqr",
    "bloque",
    "delete_bloque",
    {
      bloque_id: linkId,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      self.props.loading = false;

      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();

        // Trasladar a utils.js
        // if (
        //   self.props.linkTypeId === "imagen_de_galeria" ||
        //   self.props.linkTypeId === "item_de_catalogo"
        // ) {
        //   f7.views.main.router.navigate(
        //     `/admin/link/${self.props.dataBloque.padre}/`,
        //     {
        //       ignoreCache: true,
        //     }
        //   );
        //   f7.sheet.close();
        // } else {
        //   // f7.views.main.router.navigate("/admin/links");
        //   f7.sheet.close();
        // }

        setTimeout(() => {
          crudLocal.getLocal(self);
          // getBloquesLocal(self);
          // getBloquesLocalPublic(self);
          setTimeout(() => {
            self.props.loading = false;
            f7.dialog.close();
          }, 500);
        }, 100);
      } else {
        // utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.text = "No se ha podido eliminar el Bloque";
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();

        f7.dialog.close();

        self.props.loading = false;
      }
    },
    function (e) {}
  );
};

let envioFormulario = (self, formDataJson, linkId) => {
  const local = useStore("local");

  // f7.dialog.preloader("Enviando");

  utils.axiosCall(
    "post",
    "linqr",
    "registro_de_formulario",
    "envio_formulario",
    {
      bloque_id: linkId,
      local: local.value.name,
      form_data_json: formDataJson,
    },
    {
      "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      if (r.data.message.success === true) {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "success-toast";
        // utils.toastConfig.position = "bottom";
        f7.toast.create(utils.toastConfig).open();

        // f7.views.main.router.navigate(self.f7router.currentRoute, {
        //   ignoreCache: true,
        // });
        // f7.views.main.router.refreshPage();
        // fileUploader.uppyConfigHomePreview(self);

        f7.dialog.close();
        self.props.loading = false;

        // f7.views.main.router.refreshPage();
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        // utils.toastConfig.position = "bottom";
        f7.toast.create(utils.toastConfig).open();

        f7.dialog.close();
        self.props.loading = false;
      }
    },
    function (e) {}
  );
};

let registraEvento = function (self, bloque, evento, redirect, redirectUrl) {
  const loggedUser = localStorage.getItem("token");

  // if (!loggedUser) {
  utils.axiosCall(
    "post",
    "linqr",
    "bloque",
    "registra_evento",
    {
      bloque_id: bloque.name,
      evento: evento,
    },
    {},
    function (r) {
      if (r.data.message.success === true) {
        if (redirect) {
          window.location.assign(redirectUrl);
          f7.dialog.close();
        }
      } else {
        utils.toastConfig.text = r.data.message.message;
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();

        f7.dialog.close();
        self.props.loading = false;
      }
    },
    function (e) {}
  );
  // }
};

let getInstaFeed = function (self) {
  const local = useStore("local");

  utils.axiosCall(
    "post",
    "linqr",
    "integracion_instagram",
    "get_instagram_token",
    {
      local: local.value.name,
    },
    {
      //   "Linqr-Token": localStorage.getItem("token"),
    },
    function (r) {
      var response_data = r.data.message.data;
      self.instagramLoader = false;
      var feed = new Instafeed({
        get: "user",
        userId: response_data.usuario_instagram,
        accessToken: response_data.token_larga_duracion,
        limit: response_data.total_posts_instagram || 6,
        template:
          '<div class="col-33 padding-vertical-half"><a href="{{link}}" class="external instafeed-post"><img title="{{caption}}" src="{{image}}" /></a></div>',
      });
      feed.run();
    },
    function (e) {}
  );
};

let setValueForm = function (self, e, radioValue = "") {
  // let textEditorInstance = f7.textEditor.get("#" + e.currentTarget.id);
  // console.log(textEditorInstance[0].getValue());

  var eventType = e.type;
  var eventFieldname = e.target.name;

  if (eventType === "texteditor:input") {
    var textEditorValue = e.target.f7TextEditor.value;
    var inputName = "editor_texto";
  } else {
    var inputType = e.currentTarget.type;
    var inputName = e.currentTarget.name;
    var inputValue = e.currentTarget.value;
    var inputChecked = e.currentTarget.checked === true ? 1 : 0;
  }

  if (eventType === "input") {
    if (eventFieldname === "video_url") {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = inputValue.match(regExp);
      if (match && match[7].length == 11) {
        self.props.formDataBloque[inputName] =
          "https://www.youtube.com/embed/" + match[7];
        self.props.dataBloque[inputName] =
          "https://www.youtube.com/embed/" + match[7];
      } else {
        self.props.formDataBloque[inputName] = inputValue;
        self.props.dataBloque[inputName] = inputValue;
      }
    } else {
      self.props.formDataBloque[inputName] = inputValue;
      self.props.dataBloque[inputName] = inputValue;
    }
  } else if (eventType === "change") {
    if (inputType === "radio") {
      self.props.formDataBloque[inputName] = radioValue;
      self.props.dataBloque[inputName] = radioValue;
    } else {
      if (self.props.dataBloque[inputName] !== inputChecked) {
        self.props.formDataBloque[inputName] = inputChecked;
        self.props.dataBloque[inputName] = inputChecked;
      }
    }
  } else if (eventType === "texteditor:input") {
    self.props.formDataBloque[inputName] = textEditorValue;
    self.props.dataBloque[inputName] = textEditorValue;
  } else if (eventType === "textarea") {
    self.props.formDataBloque[inputName] = inputValue;
    self.props.dataBloque[inputName] = inputValue;
  }
};

let getInstagramPosts = (ig_user, ig_access_token) => {
  axios({
    method: "get",
    url: `https://graph.instagram.com/v12.0/${ig_user}/media?fields=caption,media_url,media_type,children&access_token=${ig_access_token}`,
  })
    .then(function (r_user) {
      store.dispatch("setInstagramPosts", r_user.data.data);
    })
    .catch(function (error) {
      console.error(error);
    })
};

let buildMercadoPagoLink = (self, bloque) => {
  const local = useStore("local").value;

  return new Promise((resolve) => {
    utils.axiosCall(
      "post",
      "linqr",
      "integracion_mercado_pago",
      "crear_preferencia_mercadopago",
      {
        bloque: bloque,
        local: local,
        importe:
          bloque.importe_descuento > 0
            ? bloque.importe_descuento
            : bloque.importe,
      },
      {
        "Linqr-Token": localStorage.getItem("token"),
      },
      function (r) {
        resolve(r.data.message.data.preference_info.init_point);
      },
      function (e) {}
    );
  });
};

let sendEmailForProduct = (self, bloque, local) => {
  utils.axiosCall(
    "post",
    "linqr",
    "bloque",
    "enviar_email_consulta_producto",
    {
      bloque: bloque,
      local: local,
    },
    {},
    function (r) {
      utils.toastConfig.text = "Su consulta se ha enviaddo con éxito.";
      utils.toastConfig.cssClass = "success-toast";
      f7.toast.create(utils.toastConfig).open();
    },
    function (e) {}
  );
};

// let onBlockCrudSuccess = function (self, r) {
//   if (
//     self.props.linkTypeId === "imagen_de_galeria" ||
//     self.props.linkTypeId === "item_de_catalogo"
//   ) {
//     f7.sheet.close();
//     f7.views.main.router.navigate(
//       `/admin/link/${localStorage.getItem("parent_block")}/`,
//       {
//         ignoreCache: true,
//       }
//     );
//   } else {
//     f7.sheet.close();
//     f7.views.main.router.navigate("/admin");

//     utils.toastConfig.text = r.data.message.message;
//     utils.toastConfig.cssClass = "success-toast";
//     f7.toast.create(utils.toastConfig).open();
//   }

// };

export default {
  getTiposDeBloques,
  getTipoDeBloque,
  getTipoDeBloqueHijo,
  getBloquesLocal,
  getBloquesLocalPublic,
  fitVideos,
  getBloque,
  createBloque,
  updateBloque,
  saveSortedLinks,
  cloneBloque,
  deleteBloque,
  envioFormulario,
  registraEvento,
  getInstaFeed,
  setValueForm,
  buildMercadoPagoLink,
  sendEmailForProduct,
  getInstagramPosts,
  // onBlockCrudSuccess,
};
