<template>
  <!-- SKELETON -->
  <f7-block v-show="props.loading" class="no-padding-horizontal margin text-align-center loader-home-block">
    <f7-row class="loader-home-container">
      <div class="loader-home-box1"></div>
      <div class="loader-home-box2"></div>
      <div class="loader-home-box3"></div>
    </f7-row>
  </f7-block>
  <!-- <f7-block
      v-show="props.loading && skeletonRows > 0"
      class="no-padding-horizontal margin text-align-center"
    >
      <f7-row>
        <f7-col class="display-flex justify-content-center">
          <div
            class="skeleton-effect-fade home-avatar-placeholder"
            style="background: #cececc"
          ></div>
        </f7-col>
      </f7-row>
      <f7-row class="margin-top">
        <f7-col class="display-flex justify-content-center">
          <div class="skeleton-text skeleton-effect-fade">
            TestTestTestTestTest
          </div>
        </f7-col>
      </f7-row>
      <f7-list
        no-hairlines
        no-hairlines-between
        class="skeleton-list margin-vertical skeleton-text skeleton-effect-fade"
        v-for="n in skeletonRows"
        :key="n"
        media-list
      >
        <f7-list-item
          class="skeleton-text skeleton-effect-fade link-item-list-preview"
        >
          TestTestTestTestTestTestTestTestTestTest
          TestTestTestTestTestTestTestTestTestTest
        </f7-list-item>
      </f7-list>
    </f7-block> -->
</template>

<script>
export default {
  props: {
    props: Object,
  },
  data() {
    return {
      skeletonRows: 0,
    };
  },
  mounted() {
    const self = this;
    self.skeletonRows = Math.floor((window.visualViewport.height * 0.7) / 76);
  },
};
</script>