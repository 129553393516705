<template>
  <f7-page class="dash-page" name="dash" @page:beforein="pageBeforeIn">
    <!-- <fullscreenLoader v-if="loading" /> -->
    <div>
      <!-- <f7-block
      v-if="!loading"
      class="bio-block padding-top margin-bottom margin-horizontal"
    >
      <f7-row>
        <f7-col class="display-flex justify-content-center">
          <img class="home-avatar" src="/static/images/linqr_avatar.jpg" />
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col
          v-if="local && local.hasOwnProperty('nombre_local')"
          class="display-flex justify-content-center"
        >
          <h3 class="margin-vertical-half">{{ local.nombre_local }}</h3>
        </f7-col>
      </f7-row>
      <f7-row class="no-padding-top padding-bottom">
        <f7-col
          v-if="local && local.hasOwnProperty('url_local')"
          class="display-flex justify-content-center"
        >
          <f7-link href="#">{{ local.url_local }}</f7-link>
        </f7-col>
      </f7-row>
    </f7-block> -->

      <f7-block class="no-margin-bottom margin-top-half">
        <f7-row class="align-items-center">
          <f7-col width="70" class="display-flex justify-content-flex-start">
            <h2>
              <i class="fas fa-chart-bar"></i>
              &nbsp;&nbsp;Estadísticas
            </h2>
          </f7-col>
          <!-- <f7-col
            width="15"
            class="display-flex flex-direction-column align-items-flex-end"
          >
            <f7-button
              morph-to=".dash-page"
              style="font-size: 18px; display: block"
              ><i class="fas fa-question"></i
            ></f7-button>
          </f7-col> -->
          <!-- <f7-col
            width="15"
            class="display-flex flex-direction-column align-items-flex-end"
          >
            <f7-button
              class=""
              href="/main-menu"
              style="font-size: 20px; display: block"
              ><i class="fas fa-bars"></i
            ></f7-button>
          </f7-col> -->
        </f7-row>
        <f7-row>
          <f7-col>
            <p class="no-margin" style="color: #b1b1b1">Últimos 30 Días</p>
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- Bloque General | Hits a la Página y a los Links -->
      <!-- <f7-block class="no-margin-vertical display-block text-align-center">
      <f7-row>
        <f7-col>
          <span style="color: #b1b1b1">Últimos 30 Días</span>
        </f7-col>
      </f7-row>
    </f7-block> -->

      <!-- Bloque General | Hits a la Página y a los Links -->
      <f7-block class="no-margin-vertical">
        <f7-row>
          <!-- <f7-col>
          <f7-card class="metricas-cards">
            <template #content>
              <h1 class="no-margin text-align-center">
                {{ totalVisitasQR }}
              </h1>
              <div class="text-align-center">Códigos QR Escaneados</div>
              <i
                class="fas fa-qrcode"
                style="
                  position: absolute;
                  background-color: #d7f9e1;
                  color: #0f8834;
                  border-radius: 50px;
                  padding: 5px;
                  top: 10px;
                  right: 10px;
                "
              ></i>
            </template>
          </f7-card>
        </f7-col> -->
          <f7-col>
            <f7-card class="metricas-cards">
              <template #content>
                <h1 class="no-margin">{{ totalVisitas || 0 }}</h1>
                <div>Visitas</div>
                <i class="fas fa-eye metricas-icon-box"></i>
              </template>
            </f7-card>
          </f7-col>
          <f7-col>
            <f7-card class="metricas-cards">
              <template #content>
                <h1 class="no-margin">{{ totalClicks || 0 }}</h1>
                <div>Bloques Clickeados</div>
                <i class="fas fa-hand-point-up metricas-icon-box"></i>
              </template>
            </f7-card>
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- Bloque de Chart | Hits a la Página y a los Links -->
      <f7-block class="no-margin-vertical">
        <f7-row>
          <f7-col>
            <f7-card class="metricas-cards">
              <template #content>
                <canvas id="clicksChart" width="400" height="300"></canvas>
              </template>
            </f7-card>
          </f7-col>
        </f7-row>
      </f7-block>

      <!-- Bloque de Tabla | Links mas clickeados -->
      <f7-block class="no-margin-vertical">
        <f7-row>
          <f7-col>
            <div class="card data-table table-cards">
              <h3 class="text-align-center">Ranking de Bloques</h3>
              <!-- <h3 class="text-align-center">Ranking de QRs</h3> -->
              <i class="fas fa-hand-point-up metricas-icon-box"></i>
              <table v-if="dataTableRanking.length > 0">
                <tbody>
                  <tr v-for="(value, name) in dataTableRanking" :key="name">
                    <td>{{ Object.keys(value)[0] }}</td>
                    <td class="numeric-cell">
                      {{ value[Object.keys(value)[0]] }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-else
                class="padding margin text-align-center"
                style="color: #777777"
              >
                Aun no hay suficientes datos<br />para calcular el ranking
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>

      <f7-fab position="right-top" class="custom-fab" @click="navigateToHome">
        <i class="fas fa-rocket"></i>
      </f7-fab>

      <!-- Compensa la Tabbar -->
      <f7-block class="no-margin no-padding">
        <f7-row style="height: 60px"></f7-row>
      </f7-block>

      <!-- <f7-button class="btn-links btn-links-disabled" href="/qr-customize-panel/"
        ><i class="fas fa-question-circle"></i
      ></f7-button> -->

      <!-- <tabbar activePage="dash" /> -->
    </div>
  </f7-page>
</template>

<script>
import { useStore, f7 } from "framework7-vue";
import store from "../js/store";
import utils from "../js/utils";
// import tabbar from "../components/tabbar.vue";
import fullscreenLoader from "../components/fullscreen-loader-container";

export default {
  props: {
    f7router: Object,
  },
  components: {
    // tabbar,
    fullscreenLoader,
  },
  data() {
    return {
      totalVisitas: 0,
      totalClicks: 0,
      // totalVisitasQR: 0,
      dataTableRanking: [],
      loading: true,
      myLineChart: undefined,
    };
  },
  setup() {
    const local = useStore("local");

    return {
      local,
    };
  },
  methods: {
    async pageBeforeIn() {
      const self = this;

      let ctx = await new Promise((resolve) => {
        resolve(document.getElementById("clicksChart"));
      });

      self.myLineChart = new Chart(ctx, {
        type: "line",
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  precision: 0,
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
      self.getMetricas();
    },
    navigateToPreview() {
      const self = this;

      f7.views.main.router.navigate(`/admin/local/${self.local.name}/`, {
        ignoreCache: true,
        realoadAll: true,
      });
    },
    getMetricas() {
      const self = this;
      utils.axiosCall(
        "post",
        "linqr",
        // "codigo_qr",
        "bloque",
        "get_metricas_30_dias",
        {},
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          // self.totalVisitasQR = r.data.message.data.total_visitas_qr;
          self.totalVisitas = r.data.message.data.total_visitas;
          self.totalClicks = r.data.message.data.total_clicks;
          self.dataTableRanking = r.data.message.data.data_table_ranking;

          self.myLineChart.data = {
            labels: r.data.message.data.labels_grafico,
            datasets: [
              {
                label: "Vistas",
                data: r.data.message.data.data_grafico,
                borderColor: "#c41515",
                fill: false,
              },
            ],
          };
          self.loading = false;
          self.myLineChart.update();
        },
        function (e) {}
      );
    },
    navigateToHome() {
      const self = this;
      self.f7router.navigate("/admin", {
        reloadCurrent: true,
      });
    },
  },
};
</script>
