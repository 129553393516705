<template>
  <f7-navbar style="position: sticky;">
    <f7-nav-left class="padding-left">
      <span class="text-align-center" style="width: 100%">
        <h4 class="no-margin"><i class="fas fa-eye"></i>&nbsp;&nbsp;Vista Previa</h4></span
      >
    </f7-nav-left>
    <f7-nav-right class="padding-right">
      <f7-button
        v-if="props.showPreview"
        class="btn-links margin-right-half"
        @click="props.showPreview = false"
        ><i class="fas fa-pen"></i
      ></f7-button>
      <f7-button
        v-if="!props.showPreview"
        class="btn-links margin-right-half"
        @click="props.showPreview = true"
        ><i class="fas fa-eye"></i
      ></f7-button>
      <f7-button
        class="btn-links margin-right-half"
        href="/admin/customize-page-sheet/"
        ><i class="fas fa-palette"></i
      ></f7-button>
      <f7-button class="btn-links btn-links-red" href="/admin/links/"
        ><i class="fas fa-times"></i
      ></f7-button>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
export default {
  props: {
    props: Object,
  },
};
</script>