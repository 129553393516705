<template>
  <!-- Archivo -->
  <div
    v-if="bloque.id_tipo_de_bloque === 'archivo'"
    class="item-archivo link-item-list-preview"
    @click="
      !bloque.archivo_solicitar_email
        ? clickBloque(bloque)
        : archivoSolicitarEmail(bloque)
    "
  >
    <!-- <div> -->
    <!-- <a style="width: 100%"> -->
    <f7-row
      class="
        display-flex
        text-align-center
        align-items-center
        justify-content-center
      "
    >
      <!-- <f7-col width="25" class="text-align-left">
                  <i class="fas fa-download icon-blue-home-preview"></i>
                </f7-col> -->
      <f7-col width="100">
        <div class="item-title">{{ bloque.nombre_bloque }}</div>
      </f7-col>
    </f7-row>
    <!-- </a> -->
    <!-- </div> -->
  </div>
</template>
<script>
import { f7, Dialog } from "framework7-vue";
import crudBloques from "../../../js/crud-bloques";
import utils from "../../../js/utils";

export default {
  props: {
    bloque: Object,
  },
  methods: {
    registraEvento(bloque, evento, redirect = false, redirectUrl = "") {
      const self = this;
      crudBloques.registraEvento(self, bloque, evento, redirect, redirectUrl);
    },
    buildWhatsAppLink(bloque) {
      const self = this;
      return `https://wa.me/${bloque.whatsapp_numero}/?text=${encodeURI(
        bloque.whatsapp_mensaje
      )}`;
    },
    archivoSolicitarEmail(bloque) {
      const self = this;

      let downloadFileDialog = f7.dialog
        .create({
          title: "",
          text: "Ingresa tu correo electrónico para descargar el archivo",
          content: `
          <div class="list no-hairlines no-margin-bottom">
            <ul>
              <li class="item-content item-input item-input-outline no-padding-left">
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <input class="dialog-input no-margin-top" type="email" placeholder="Ingresa tu Email">
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>`,
          buttons: [
            { text: "Cancelar" },
            { text: "Descargar", bold: true, close: false },
          ],
          onClick: function (dialog, index) {
            let e = dialog.$el.find(".dialog-input").val();

            if (index === 1) {
              if (
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                  e
                )
              ) {
                utils.toastConfig.text =
                  "Muchas Gracias! Tu archivo esta siendo descargado";
                utils.toastConfig.cssClass = "success-toast";
                f7.toast.create(utils.toastConfig).open();

                downloadFileDialog.close();

                self.clickBloque(bloque);
              } else {
                utils.toastConfig.text = "Debes ingresar un email válido";
                utils.toastConfig.cssClass = "warning-toast";
                f7.toast.create(utils.toastConfig).open();
              }
            }
          },
        })
        .open();
    },
    clickBloque(bloque) {
      const self = this;

      utils.toastConfig.text = "En breve se descargará tu archivo";
      utils.toastConfig.cssClass = "success-toast";
      f7.toast.create(utils.toastConfig).open();

      self.registraEvento(bloque, "click_bloque", false);

      setTimeout(function () {
        window.open(bloque.archivo_url, "_blank");
      }, 1500);
    },
  },
};
</script>
