<template>
  <f7-row class="margin-vertical">
    <f7-col class="auth-logo">
      <span>
        <img
          src="/static/images/miqroapp_logo_v3.png"
          alt=""
          height="30"
        />
      </span>
    </f7-col>
  </f7-row>
</template>

<script>
export default {};
</script>