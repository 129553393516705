<template>
  <f7-block
        v-if="
          !props.showPreview &&
          !props.loading &&
          bloquesLocal &&
          bloquesLocal.length === 0
        "
      >
        <!-- <f7-row
          class="empty-state-home-preview"
        >
        </f7-row> -->
        <!-- <f7-row
          class="justify-content-center padding-vertical-half"
          style="opacity: 0.5"
        >
          <span class="empty-state-block-text"
            >Aún no has creado contenido</span
          >
        </f7-row> -->
      </f7-block>
</template>

<script>
export default {
  props: {
    props: Object,
    bloquesLocal: Array
  }
}
</script>