<template>
  <f7-page>
    <!-- <f7-navbar title="Not found" back-link="Back"></f7-navbar> -->
    <f7-block class="no-margin padding">
      <f7-row>
        <f7-col class="text-align-center miqrositio-not-found-container">
          <h2>Este Sitio no existe</h2>
          <img style="margin-top: -20px;" src="/static/images/miqrositio-not-found.png" alt="" />
          <p>Comprueba que la URL sea la correcta e intenta nuevamente</p>
          <span class="miqrositio-not-found-logo">
            <a href="https://miqro.app" class="external" target="_blank">
              <img
              src="/static/images/miqroapp_logo_v3.png"
              alt=""
              height="20"
            />
            </a>
          </span>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>
export default {
  props: {
    currentLocal: String,
  },
};
</script>
