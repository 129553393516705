<template>
  <f7-actions
      :opened="actionsConectionMPOpened"
      @actions:closed="actionsConectionMPOpened = false"
    >
      <f7-actions-label>Conectar con Mercado Pago</f7-actions-label>
      <f7-actions-group
        class="padding text-align-center"
        style="background: #ffffff; text-align: center"
      >
      <p>Aun no has conectado tu Sitio con una cuenta de Mercado Pago.</p>
      <f7-button fill color="green" @click="conectarMercadoPago()">Conectar con Mercado Pago</f7-button>
      <p>Recuerda guardar tu producto antes de continuar, ya que serás redirigido a una página externa.</p>
      </f7-actions-group>
    </f7-actions>
</template>

<script>
export default {
  actionsConectionMPOpened: Boolean
}
</script>