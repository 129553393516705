<template>
  <f7-actions
    :opened="props.actionSelectPrintSizeOpen"
    @actions:closed="props.actionSelectPrintSizeOpen = false"
  >
    <f7-actions-group>
      <f7-actions-label>Elige un Tamaño de Impresión</f7-actions-label>
      <f7-actions-button @click="printQrDisplay('qr-display-etiqueta')">
        <i class="fas fa-print"></i>&nbsp;&nbsp;Etiqueta
      </f7-actions-button>
      <f7-actions-button @click="printQrDisplay('qr-display-extra-chico')">
        <i class="fas fa-print"></i>&nbsp;&nbsp;Extra Chico
      </f7-actions-button>
      <f7-actions-button @click="printQrDisplay('qr-display-chico')">
        <i class="fas fa-print"></i>&nbsp;&nbsp;Chico (1/4 de Hoja)
      </f7-actions-button>
      <f7-actions-button @click="printQrDisplay('qr-display-mediano')">
        <i class="fas fa-print"></i>&nbsp;&nbsp;Mediano (1/2 Hoja)
      </f7-actions-button>
      <f7-actions-button @click="printQrDisplay('qr-display-grande')">
        <i class="fas fa-print"></i>&nbsp;&nbsp;Grande (Hoja Completa)
      </f7-actions-button>
    </f7-actions-group>
  </f7-actions>
</template>

<script>
import { f7 } from "framework7-vue";
import store from "../../js/store";
import qr from "../../js/qr";

export default {
  props: {
    props: Object,
    local: Object,
  },
  data() {
    return {
      imagenQRPlantilla: "",
      imageCodeQR: "",
    };
  },
  methods: {
    async printQrDisplay(qrSize) {
      const self = this;
      f7.dialog.preloader("Generando Código QR");

      let printSizes = {
        "qr-display-etiqueta": {
          height: "50mm",
        },
        "qr-display-extra-chico": {
          height: "100mm",
        },
        "qr-display-chico": {
          height: "150mm",
        },
        "qr-display-mediano": {
          height: "200mm",
        },
        "qr-display-grande": {
          height: "290mm",
        },
      };

      await new Promise((resolve) => {
        if (self.props.printTemplate) {
          qr.generateQrFile(
            self,
            f7.$(".qr-display"),
            "templateQRFileURL",
            `${self.local.nombre_unico_local} Plantilla QR.png`,
            "#download-qrcode-template",
            "print"
          ).then((imagePlantillaToPrint) => {
            self.imagenQRPlantilla = imagePlantillaToPrint;
            resolve();
          });
        } else if (self.props.printQRCode) {
          qr.generateQrFile(
            self,
            f7.$("#qrcode").find("canvas"),
            "qRFileURL",
            `${self.local.nombre_unico_local} Código QR.png`,
            "#download-qrcode",
            "print"
          ).then((imageCodeToPrint) => {
            self.imageCodeQR = imageCodeToPrint;
            resolve();
          });
        }
      });

      f7.dialog.close();
      f7.dialog.preloader("Preparando la Impresión");

      // Create a fake iframe
      const iframe = document.createElement("iframe");

      // Make it hidden
      iframe.style.height = 0;
      iframe.style.visibility = "hidden";
      iframe.style.width = 0;

      let imageElement = document.createElement("img");
      imageElement.setAttribute(
        "src",
        self.props.printTemplate ? self.imagenQRPlantilla : self.imageCodeQR
      );
      imageElement.setAttribute("id", "image-qr-to-print");
      imageElement.style.maxWidth = "100%";
      imageElement.style.height = printSizes[qrSize].height;
      document.body.appendChild(imageElement);

      // Set the iframe's source
      iframe.setAttribute("srcdoc", "<html><body></body></html>");

      document.body.appendChild(iframe);

      iframe.addEventListener("load", function () {
        // Append the image to the iframe's body
        const body = iframe.contentDocument.body;
        body.style.textAlign = "center";
        body.appendChild(imageElement);

        imageElement.addEventListener("load", function () {
          // Invoke the print when the image is ready
          f7.dialog.close();
          iframe.contentWindow.print();
        });
      });

      iframe.contentWindow.addEventListener("afterprint", function () {
        iframe.parentNode.removeChild(iframe);
      });
      //   });
      // });

      store.dispatch("customizeQRSize", qrSize);

      // setTimeout(() => {
      //   window.print();
      // }, 500);
    },
  },
};
</script>