<template>
  <f7-sheet
    :swipe-to-close="false"
    :close-by-backdrop-click="false"
    :close-by-outside-click="false"
    :close-on-escape="false"
    backdrop
    style="height: -webkit-fill-available; max-height: 60%; overflow: auto"
    class="customize-page-sheet qr-customize-sheet"
  >
    <f7-page-content>
      <f7-page>
        <navbarQrCustomizeSheet :props="props" :f7router="f7router"/>

        <f7-block class="no-printme no-margin no-padding">
          <f7-row>
            <f7-col>
              <f7-list
                no-hairlines
                no-hairlines-between
                class="no-margin-top margin-bottom-half"
              >
                <!-- Margin List -->
                <f7-list-item>
                  <span style="height: 30px"></span>
                </f7-list-item>
                <!-- Tipo de QR -->
                <f7-list-input
                  label="Estilo de QR"
                  class="no-padding"
                  type="select"
                  outline
                  :value="customQRStyle"
                  @change="
                    (e) => {
                      changeQRStyle(e);
                    }
                  "
                >
                  <option :selected="customQRStyle === 'square'" value="square">
                    Cuadrado
                  </option>
                  <option :selected="customQRStyle === 'dot'" value="dot">
                    Redondo
                  </option>
                </f7-list-input>

                <f7-list-input
                  type="text"
                  outline
                  id="input_texto_superior"
                  name="texto_superior"
                  label="Texto Superior"
                  class="qr-template-custom-text"
                  placeholder="Texto para mostrar arriba del código QR"
                  :value="customQRText"
                  @input="
                    (e) => {
                      changeQRText(e);
                    }
                  "
                  @click="
                    (e) => {
                      emojiElementSelect(e, 'input');
                    }
                  "
                >
                </f7-list-input>

                <f7-list-input
                  type="text"
                  outline
                  id="input_texto_inferior"
                  label="Texto Inferior"
                  name="texto_inferior"
                  class="qr-template-custom-text"
                  placeholder="Texto para mostrar debajo del código QR"
                  :value="customQRSubText"
                  @input="
                    (e) => {
                      changeQRSubText(e);
                    }
                  "
                  @click="
                    (e) => {
                      emojiElementSelect(e, 'input');
                    }
                  "
                >
                </f7-list-input>

                <!-- <f7-list-item>
                  <span>Mostrar Imagen de Perfil</span>
                  <f7-toggle
                    color="green"
                    :checked="customQRImage ? true : false"
                    @change="(e) => changeQRProfileImage(e)"
                  ></f7-toggle>
                </f7-list-item> -->

                <li class="list-item-divider-custom">
                  <div class="item-content">
                    <div class="item-inner">
                      <span>
                        <i class="fas fa-eye-dropper"></i>&nbsp;&nbsp;
                        <span>Color Principal</span>
                      </span>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="item-content">
                    <div class="item-inner">
                      <f7-block class="no-margin no-padding">
                        <!-- Predefined Colors -->
                        <f7-row class="margin-half">
                          <f7-col>
                            <div
                              class="padding-left-half item-title item-label"
                            >
                              Colores Predefinidos
                            </div>
                          </f7-col>
                        </f7-row>
                        <f7-row
                          class="text-align-center justify-content-center"
                        >
                          <f7-col
                            v-for="color in predefinedColors"
                            :key="color.name"
                            width="20"
                            class="
                              display-flex
                              justify-content-center
                              margin-vertical-half
                            "
                            @click="
                              changeOverallQRColors(color.color, color.name)
                            "
                          >
                            <div
                              class="
                                color-selection-circle-principal
                                display-flex
                                justify-content-center
                                flex-direction-column
                                align-items-center
                              "
                              v-bind:style="{
                                background: color.color,
                              }"
                              :data-background-color="color.color"
                            >
                              <!-- <span
                                v-show="customQRMainColor == color.name"
                                style="
                                  color: #ffffff;
                                  font-size: 30px;
                                  margin-top: 5px;
                                "
                                >✓</span
                              >
                              <span
                                v-show="customQRMainColor != color.name"
                                :style="
                                  'color: ' +
                                  color.color +
                                  '; ' +
                                  ' font-size: 30px; margin-top: 5px;'
                                "
                                >✓</span
                              > -->
                            </div>
                          </f7-col>
                        </f7-row>
                        <!-- Color Personalizado -->
                        <f7-row class="margin-half">
                          <f7-col>
                            <div
                              class="padding-left-half item-title item-label"
                            >
                              Color Personalizado
                            </div>
                            <f7-input
                              type="colorpicker"
                              outline
                              placeholder="Color"
                              readonly
                              :value="{ hex: customQRDotColor }"
                              :color-picker-params="{
                                modules: ['wheel', 'hex'],
                                hexLabel: true,
                                hexValueEditable: true,
                                groupedModules: true,
                              }"
                              @change="
                                (e) =>
                                  changeOverallQRColors(
                                    e.currentTarget.value,
                                    ''
                                  )
                              "
                            ></f7-input>
                          </f7-col>
                        </f7-row>
                      </f7-block>
                    </div>
                  </div>
                </li>

                <!-- <f7-list-input
                  type="colorpicker"
                  outline
                  label="Puntos"
                  placeholder="Color"
                  readonly
                  :value="{ hex: customQRDotColor }"
                  :color-picker-params="{
                    modules: [
                      'initial-current-colors',
                      'sb-spectrum',
                      'hue-slider',
                      'hex',
                    ],
                    hexLabel: true,
                    hexValueEditable: true,
                    groupedModules: true,
                  }"
                  @change="changeQRDotsColors"
                ></f7-list-input>

                <f7-list-input
                  type="colorpicker"
                  outline
                  label="Esquineros"
                  placeholder="Color"
                  readonly
                  :value="{ hex: customQRCornerColor }"
                  :color-picker-params="{
                    modules: [
                      'initial-current-colors',
                      'sb-spectrum',
                      'hue-slider',
                      'hex',
                    ],
                    hexLabel: true,
                    hexValueEditable: true,
                    groupedModules: true,
                  }"
                  @change="changeQRSquareColors"
                ></f7-list-input>

                <f7-list-input
                  type="colorpicker"
                  outline
                  label="Interior de Esquineros"
                  placeholder="Color"
                  readonly
                  :value="{ hex: customQRDotCornerColor }"
                  :color-picker-params="{
                    modules: [
                      'initial-current-colors',
                      'sb-spectrum',
                      'hue-slider',
                      'hex',
                    ],
                    hexLabel: true,
                    hexValueEditable: true,
                    groupedModules: true,
                  }"
                  @change="changeQRCornerDotsColors"
                ></f7-list-input> -->
              </f7-list>
            </f7-col>
          </f7-row>
        </f7-block>
        
        <fabEmojiPicker />
      </f7-page>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import store from "../../js/store";
import utils from "../../js/utils";
import navbarQrCustomizeSheet from "./navbar-qr-customize-sheet.vue";
import fabEmojiPicker from "../fab-emoji-picker.vue";

export default {
  props: {
    f7router: Object,
    f7route: Object,
  },
  setup() {
    const customQR = useStore("customQR");
    const customQRStyle = useStore("customQRStyle");
    const customQRMainColor = useStore("customQRMainColor");
    const customQRText = useStore("customQRText");
    const customQRSubText = useStore("customQRSubText");
    const customQRImage = useStore("customQRImage");
    const customQRSize = useStore("customQRSize");
    const customQRDotColor = useStore("customQRDotColor");
    const customQRCornerColor = useStore("customQRCornerColor");
    const customQRDotCornerColor = useStore("customQRDotCornerColor");
    const local = useStore("local");
    const emojiPicker = useStore("emojiPicker");

    return {
      customQR,
      customQRStyle,
      customQRMainColor,
      customQRText,
      customQRSubText,
      customQRImage,
      customQRSize,
      customQRDotColor,
      customQRCornerColor,
      customQRDotCornerColor,
      local,
      emojiPicker,
    };
  },
  components: {
    navbarQrCustomizeSheet,
      fabEmojiPicker
  },
  data() {
    return {
      predefinedColors: [],
      props: {
        currentInputSel: {
          val: "",
        },
      },
    };
  },
  mounted() {
    const self = this;

    utils.loadPickerWidget(self);
    self.getColorPalettes();
  },
  methods: {
    changeQRSize(e) {
      const self = this;
      store.dispatch("customizeQRSize", e.target.value);
    },
    changeQRText(e) {
      const self = this;
      store.dispatch("customizeQRText", e.target.value);
    },
    changeQRSubText(e) {
      const self = this;
      store.dispatch("customizeQRSubText", e.target.value);
    },
    changeQRStyle(e) {
      const self = this;
      store.dispatch("customizeQRStyle", e.target.value);

      if (e.target.value === "square") {
        self.customQR.update({
          dotsOptions: {
            type: "square",
          },
          cornersSquareOptions: {
            type: "square",
          },
          cornersDotOptions: {
            type: "square",
          },
        });
      } else if (e.target.value === "dot") {
        self.customQR.update({
          dotsOptions: {
            type: "dots",
          },
          cornersSquareOptions: {
            type: "extra-rounded",
          },
          cornersDotOptions: {
            type: "dot",
          },
        });
      }
    },
    // changeQRProfileImage(e) {
    //   const self = this;

    //   if (e.currentTarget.checked) {
    //     store.dispatch("customizeQRImage", true);

    //     self.customQR.update({
    //       image: self.local.imagen_perfil,
    //       imageOptions: {
    //         crossOrigin: "anonymous",
    //         margin: 20,
    //       },
    //     });
    //   } else {
    //     store.dispatch("customizeQRImage", false);

    //     self.customQR.update({
    //       image: "",
    //       imageOptions: {
    //         crossOrigin: "anonymous",
    //         margin: 20,
    //       },
    //     });
    //   }
    // },
    changeOverallQRColors(color, colorName) {
      const self = this;

      store.dispatch("customizeQRDotColor", color);
      store.dispatch("customizeQRCornerColor", color);
      store.dispatch("customizeQRDotCornerColor", color);
      store.dispatch("customizeQRMainColor", colorName);

      self.customQR.update({
        dotsOptions: {
          color: color,
        },
        cornersSquareOptions: {
          color: color,
        },
        cornersDotOptions: {
          color: color,
        },
      });
    },
    changeQRDotsColors(e) {
      const self = this;
      store.dispatch("customizeQRDotColor", e.target.value);

      self.customQR.update({
        dotsOptions: {
          color: e.target.value,
        },
      });
    },
    changeQRSquareColors(e) {
      const self = this;
      store.dispatch("customizeQRCornerColor", e.target.value);

      self.customQR.update({
        cornersSquareOptions: {
          color: e.target.value,
        },
      });
    },
    changeQRCornerDotsColors(e) {
      const self = this;
      store.dispatch("customizeQRDotCornerColor", e.target.value);

      self.customQR.update({
        cornersDotOptions: {
          color: e.target.value,
        },
      });
    },
    getColorPalettes() {
      const self = this;

      utils.axiosCall(
        "post",
        "linqr",
        "paleta_de_color",
        "get_paleta_de_colores",
        {},
        {
          "Linqr-Token": localStorage.getItem("token"),
        },
        function (r) {
          let coloresPrincipales = r.data.message.data.colores_principales;
          let coloresDeFondo = r.data.message.data.colores_de_fondo;

          for (let index = 0; index < coloresPrincipales.length; index++) {
            self.predefinedColors.push(coloresPrincipales[index]);
          }

          for (let index = 0; index < coloresDeFondo.length; index++) {
            self.predefinedBackgroundColors.push(coloresDeFondo[index]);
          }

          self.loading = false;
        },
        function (e) {}
      );
    },
    emojiElementSelect(e, dataType) {
      const self = this;
      e.target.setAttribute("data-type", dataType);
      e.target.setAttribute("data-page-type", "qr");
      store.dispatch("setEmojiElementSelected", e.target);
      store.dispatch("setEmojiPageData", self.customQRText);

      // if (self.emojiPicker.isPickerVisible()) {
      //   self.emojiPicker.hidePicker();
      // }
    },
    emojiElementDeselect() {
      const self = this;
      store.dispatch("setEmojiElementSelected", "");
      self.emojiPicker.hidePicker();
    },
  },
};
</script>
