<template>
  <f7-block
    class="no-margin-vertical margin-horizontal"
    style="margin-top: -50px !important z-index: 0;"
  >
    <f7-row v-if="local && local.imagen_perfil">
      <f7-col
        class="display-flex justify-content-center"
        :style="
          local.imagen_cover
            ? 'margin-top: -55px'
            : 'margin-top: var(--f7-typography-margin)'
        "
      >
        <div
          v-show="!props.profileImageLoaded"
          class="link-settings-preview-image"
          style="background-color: #e5eaef"
        >
          <span class="spinner-placeholder-center">
            <i class="fa fa-spinner fa-spin"></i
          ></span>
        </div>
        <img
          v-show="props.profileImageLoaded"
          id="profile-image-front"
          class="home-avatar"
          :src="local.imagen_perfil"
        />
      </f7-col>
    </f7-row>
    <!-- <f7-row v-if="local && !local.imagen_perfil">
      <f7-col class="display-flex justify-content-center">
        <div class="home-avatar-placeholder">
          MI<br/>LOGO
        </div>
      </f7-col>
    </f7-row> -->
    <f7-row>
      <f7-col class="display-flex justify-content-center">
        <h2
          v-if="local && local.hasOwnProperty('nombre_local')"
          class="
            margin-vertical-half
            home-preview-nombre-local
            text-align-center
          "
        >
          {{ local.nombre_local }}
        </h2>
      </f7-col>
    </f7-row>
    <f7-row class="no-padding-top padding-bottom">
      <f7-col class="display-flex justify-content-center">
        <f7-link
          v-if="local && local.hasOwnProperty('informacion_local')"
          class="home-preview-url-local"
          :style="`color: ${mainColorHex} !important`"
          href="#"
          >{{ local.informacion_local }}</f7-link
        >
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { useStore, f7 } from "framework7-vue";

export default {
  props: {
    props: Object,
    local: Object,
  },
  setup() {
    const mainColorHex = useStore("mainColorHex");

    return {
      mainColorHex,
    };
  },
};
</script>