<template>
  <f7-app v-bind="f7params">
    <f7-views tabs class="safe-areas">
      <f7-view
        id="view-tabs"
        class="view-main"
        name="main"
        url="/"
        main
        browser-history
        browser-history-separator=""
        :ios-swipe-back="false"
        :md-swipe-back="false"
      ></f7-view>
      <onboarding />
      <sheetTryPro />
      <sheetBuyPro />
      <sheetEndFreeTrial />
      <helpActionsMainVideo />
    </f7-views>
  </f7-app>
</template>
<script>
import { ref, onMounted } from "vue";
import { f7, f7ready, useStore } from "framework7-vue";

import routes from "../js/routes.js";
import store from "../js/store";
import utils from "../js/utils";
import onboarding from "../components/onboarding";
import sheetTryPro from "../components/billing/sheet-try-pro";
import sheetBuyPro from "../components/billing/sheet-buy-pro";
import sheetEndFreeTrial from "../components/billing/sheet-end-free-trial";
import helpActionsMainVideo from "../components/helps/help-actions-main-video";

export default {
  setup() {
    // Framework7 Parameters
    const f7params = {
      name: "miqroapp",
      theme: "auto",
      store: store,
      routes: routes,
      keepAlive: true,
      dialog: {
        title: "",
        buttonOk: "Continuar",
        buttonCancel: "Volver",
      },
      touch: {
        mdTouchRipple: false,
        auroraTouchRipple: false,
        fastClicks: true,
      },
      iosTranslucentModals: false,
    };

    onMounted(() => {
      f7ready((self) => {
        localStorage.removeItem("block_type");
        localStorage.removeItem("block_data");
        localStorage.removeItem("parent_block");

        // store.dispatch("setEmojiPicker", utils.newEmojiPicker());
        // utils.loadPickerWidget()
      });
    });

    return {
      f7params,
    };
  },
  components: {
    onboarding,
    sheetTryPro,
    sheetBuyPro,
    sheetEndFreeTrial,
    helpActionsMainVideo,
  },
};
</script>
