<template>
  <f7-page>
    <!-- <f7-navbar title="Not found" back-link="Back"></f7-navbar> -->
    <f7-block class="no-margin padding">
      <f7-row>
        <f7-col class="text-align-center miqrositio-not-found-container">
          <h2 class="margin-bottom">Parece que esta página no existe</h2>
          <img src="/static/images/miqrositio-not-found.png" alt="" />
          <f7-button @click="navigateToAdmin" class="margin-top" fill>Vuelve al Administrador</f7-button>
          <span class="miqrositio-not-found-logo">
            <a href="https://miqro.app" class="external" target="_blank">
              <img
              src="/static/images/miqroapp_logo_v3.png"
              alt=""
              height="20"
            />
            </a>
          </span>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>
import { f7 } from "framework7-vue"
export default {
  props: {
    currentLocal: String,
  },
  methods: {
    navigateToAdmin() {
      const self = this;
      f7.views.main.router.navigate("/admin", {
        reloadAll: true,
        ignoreCache: true
      })
    }
  }
};
</script>
