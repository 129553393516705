<template>
  <span>
    <f7-list-item v-if="props.linkTypeId === 'formulario'">
      <span>Nombre y Apellido</span>
      <f7-toggle
        color="green"
        name="form_nombre_apellido"
        :checked="
          props.dataBloque.form_nombre_apellido &&
          props.dataBloque.form_nombre_apellido === 1
            ? true
            : false
        "
        @change="(e) => setValueForm(e)"
      ></f7-toggle>
    </f7-list-item>
    <f7-list-item v-if="props.linkTypeId === 'formulario'">
      <span>Correo Electrónico</span>
      <f7-toggle
        color="green"
        name="form_email"
        :checked="
          props.dataBloque.form_email && props.dataBloque.form_email === 1
            ? true
            : false
        "
        @change="(e) => setValueForm(e)"
      ></f7-toggle>
    </f7-list-item>
    <f7-list-item v-if="props.linkTypeId === 'formulario'">
      <span>Teléfono / WhatsApp</span>
      <f7-toggle
        color="green"
        name="form_telefono"
        :checked="
          props.dataBloque.form_telefono && props.dataBloque.form_telefono === 1
            ? true
            : false
        "
        @change="(e) => setValueForm(e)"
      ></f7-toggle>
    </f7-list-item>
    <f7-list-item v-if="props.linkTypeId === 'formulario'">
      <span>Consulta</span>
      <f7-toggle
        color="green"
        name="form_consulta"
        :checked="
          props.dataBloque.form_consulta && props.dataBloque.form_consulta === 1
            ? true
            : false
        "
        @change="(e) => setValueForm(e)"
      ></f7-toggle>
    </f7-list-item>
    <f7-list-item v-if="props.linkTypeId === 'formulario'">
      <span>Subir Archivos</span>
      <f7-toggle
        color="green"
        name="form_archivos"
        :checked="
          props.dataBloque.form_archivos && props.dataBloque.form_archivos === 1
            ? true
            : false
        "
        @change="(e) => setValueForm(e)"
      ></f7-toggle>
    </f7-list-item>
    <f7-list-input
      v-if="props.linkTypeId === 'formulario'"
      outline
      id="input_form_texto_boton"
      label="Texto del Boton"
      type="text"
      name="form_texto_boton"
      @input="setValueForm"
      :value="props.dataBloque.form_texto_boton"
      placeholder="Ej: Suscribite, Consultar, Presupuestar, etc."
      required
      validate
      @click="
        (e) => {
          emojiElementSelect(e, 'input');
        }
      "
    ></f7-list-input>
  </span>
</template>

<script>
import crudBloques from "../../../js/crud-bloques";
import utils from "../../../js/utils";
import store from "../../../js/store";
import { f7, useStore } from "framework7-vue";

export default {
  props: {
    props: Object,
  },
  setup() {
    const emojiPicker = useStore("emojiPicker");
    const tipoEmoji = "bloques";

    return {
      emojiPicker,
      tipoEmoji
    };
  },
  mounted() {
    const self = this;
    utils.loadPickerWidget(self);
  },
  methods: {
    triggerEmojiPicker(e) {
      const self = this;
      utils.triggerEmojiPicker(self, e);
    },
    setValueForm(e) {
      const self = this;
      crudBloques.setValueForm(self, e);
    },
    emojiElementSelect(e, dataType) {
      const self = this;
      e.target.setAttribute("data-type", dataType);
      e.target.setAttribute("data-page-type", "bloques");
      store.dispatch("setEmojiElementSelected", e.target);
      store.dispatch("setEmojiPageData", self.props.dataBloque);

      // if (self.emojiPicker.isPickerVisible()) {
      //   self.emojiPicker.hidePicker();
      // }
    },
    emojiElementDeselect() {
      const self = this;
      store.dispatch("setEmojiElementSelected", "");
      self.emojiPicker.hidePicker();
    },
  },
};
</script>
