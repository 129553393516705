<template>
  <f7-fab-backdrop></f7-fab-backdrop>
  <f7-fab
    v-if="!props.loading && props.loggedUser && !props.showPreview"
    position="right-top"
    class="custom-fab"
  >
    <i class="fas fa-bars"></i>
    <i class="fas fa-times"></i>
    <f7-fab-buttons
      position="bottom"
      style="font-family: 'Outfit', sans-serif !important"
    >
      <f7-fab-button
        fab-close
        v-if="props.showPreview && props.loggedUser"
        @click="props.showPreview = false"
        label="Editar Contenido"
        ><i class="fas fa-pen"></i
      ></f7-fab-button>
      <f7-fab-button
        fab-close
        v-if="!props.showPreview && props.loggedUser"
        @click="props.showPreview = true"
        label="Vista Previa"
        ><i class="fas fa-eye"></i
      ></f7-fab-button>
      <f7-fab-button
        fab-close
        v-if="props.loggedUser"
        href="/admin/links/"
        label="Ordenar Bloques"
        ><i class="fas fa-layer-group"></i
      ></f7-fab-button>
      <f7-fab-button
        fab-close
        v-if="props.loggedUser"
        href="/admin/qr/"
        label="Mi Código QR"
        ><i class="fas fa-qrcode"></i
      ></f7-fab-button>
      <f7-fab-button
        fab-close
        v-if="props.loggedUser && suscripcion.nombre_suscripcion_actual === 'Pro'"
        href="/admin/dash/"
        label="Estadísticas"
        ><i class="fas fa-chart-bar"></i
      ></f7-fab-button>
      <f7-fab-button
        fab-close
        v-if="props.loggedUser"
        href="/admin/account/"
        label="Mi Cuenta"
        ><i class="fas fa-user"></i
      ></f7-fab-button>
      <f7-fab-button
        fab-close
        v-if="props.loggedUser"
        label="Ayuda"
        style="background-color: #ffffff; color: #0f8834;"
        @click="openMainVideoTutorial"
        ><i class="fas fa-question-circle"></i
      ></f7-fab-button>
      <f7-fab-button
        style="background: #ffc314; color: #222"
        fab-close
        v-show="
          props.loggedUser && suscripcion.nombre_suscripcion_actual === 'Gratuito'
        "
        @click="TryOrBuyPro"
        label="Pasate a Pro"
        ><i class="fas fa-crown"></i
      ></f7-fab-button>
      <f7-fab-button
        style="background: #da5151"
        fab-close
        v-if="props.loggedUser"
        @click="signOut"
        label="Cerrar Sesión"
        ><i class="fas fa-sign-out-alt"></i
      ></f7-fab-button>
    </f7-fab-buttons>
  </f7-fab>

  <f7-fab
    v-if="!props.loading && props.loggedUser && props.showPreview"
    position="right-top"
    class="custom-fab"
    @click="props.showPreview = false"
  >
    <i class="fas fa-pen"></i>
  </f7-fab>

  <f7-fab
    v-if="props.loggedUser && !props.showPreview"
    @click="addLink"
    position="right-bottom"
    class="custom-fab blob green"
  >
    <i class="fas fa-plus"></i>
  </f7-fab>

  <f7-fab
    v-if="props.loggedUser && !props.showPreview"
    @click="navigateToCustomizePageSheet"
    position="left-bottom"
    class="custom-fab"
  >
    <i class="fas fa-palette"></i>
  </f7-fab>
</template>

<script>
import { f7, useStore } from "framework7-vue";
import account from "../../js/account";
import store from "../../js/store";

export default {
  setup() {
    const loggedUser = localStorage.getItem("token");
    const suscripcion = useStore("suscripcion");
    const bloquesLocal = useStore("bloquesLocal");

    return {
      loggedUser,
      suscripcion,
      bloquesLocal,
    };
  },
  props: {
    props: Object,
  },
  methods: {
    navigateBack() {
      const self = this;
      self.f7router.navigate("/admin/dash/");
    },
    addLink() {
      const self = this;

      if (self.bloquesLocal.length >= 50) {
        utils.toastConfig.text =
          "Con el Plan Gratuito solo puedes crear hasta 50 Bloques. Pasate a Pro!";
        utils.toastConfig.cssClass = "error-toast";
        f7.toast.create(utils.toastConfig).open();
      } else {
        f7.views.main.router.navigate("/admin/new-link-type/");
      }
    },
    navigateToCustomizePageSheet() {
      const self = this;
      f7.views.main.router.navigate("/admin/customize-page-sheet/");
    },
    openMainVideoTutorial() {
      const self = this;

      f7.panel.close();
      store.dispatch("setOpenOnboardingSheet", true);
    },
    signOut() {
      account.signOut();
    },
    TryOrBuyPro() {
      const self = this;

      if (self.suscripcion.prueba_gratuita === 0) {
        store.dispatch("setOpenTryProSheet", true);
      } else if (self.suscripcion.prueba_gratuita === 1) {
        store.dispatch("setOpenBuyProSheet", true);
      }
    },
  },
};
</script>